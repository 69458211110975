<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Developer API</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Developer API</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Developer API List</h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="flex justify-between items-center mb-3">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;{{ total }}&nbsp;Records
                                </h3>
                            </div>
                            <div class="flex-column text-right">
                                <button
                            type="button"
                            id="addGroupBtn"
                            (click)="openAddAPIForm(content)"
                            class="btn btn-primary mb-2"
                        >
                            Create API
                        </button>
                        <div class="search-container flex items-center ml-2">
                            <label for="searchDevicesInput" class="form-label mt-2 mr-2">Search:</label>
                            <input type="text" id="searchDevicesInput" placeholder="Search Devices..." (input)="filterFromTable($event.target.value)" class="form-control">
                        </div>
                            </div>
                        </div>

                        <table
                            id="example2"
                            class="table table-bordered table-hover"
                        >
                            <thead>
                                <tr>
                                    <th
                                        *ngFor="
                                            let field of prop
                                        "
                                    >
                                        {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="
                                    let datas of displayedDeviceData;
                                    let i = index
                                "
                            >
                                <tr>
                                    <ng-container
                                        *ngFor="let val of prop"
                                    >
                                        <td>
                              {{ datas[val] }}
                            </td>

                                    </ng-container>

                                    <!-- <td class="px-4 py-2 border text-center">1</td>
                  <td class="px-4 py-2 border text-center">John Doe</td> -->
                                    <td>
                                        <button type="button"
                                                value="{{ datas['id'] }}"
                                                id="editUpdateAPI_{{ datas['id'] }}"
                                                class="btn btn-primary ml-1"
                                                (click)="
                                                    openEditUpdateAPIForm(
                                                        editAPIFormdATA,
                                                        datas['id'],
                                                        datas['ip_address']
                                                    )
                                                "
                                                ng-click="getValue($event)"
                                                matTooltip="Edit API"
                                            >
                                                <i class="fas fa-edit"></i></button
                                            >

                                            <button type="button"
                                            value="{{ datas['id'] }}"
                                            class="btn btn-danger ml-1"
                                            (click)="
                                                deleteAPIKey(datas['id'])
                                            "
                                            id="deleteAPI_{{ datas['id'] }}"
                                            ng-click="getValue($event)"
                                            matTooltip="Delete API"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </button>

                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->

<ng-template #content let-modal>
    <form
        [formGroup]="addAPIForm"
        (ngSubmit)="addAPISubmit()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add API</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div>
                <label
                    for="IPName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >IP Address</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="IPName"
                        id="IPName"
                        formControlName="IPName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter IP Address"
                    />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                ADD API
            </button>
        </div>
    </form>
</ng-template>

<ng-template #editAPIFormdATA let-modal>
    <form
        [formGroup]="editAPIForm"
        (ngSubmit)="editAPINameSubmit()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit IP Address Name</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div>
                <label
                    for="editAPIName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >IP Address</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="editAPIName"
                        id="editAPIName"
                        formControlName="editAPIName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter IP Address"
                    />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                UPDATE
            </button>
        </div>
    </form>

    <!-- In your HTML -->
    <!-- <div *ngIf="isLoadingEdit" class="custom-loader"></div> -->

    <div class="loader-container" *ngIf="isLoadingEdit">
        <div class="lds-hourglass"></div>
     </div>
    <!-- Your other HTML goes here -->
</ng-template>

<!-- In your HTML -->
<!-- <div *ngIf="isLoading" class="custom-loader"></div> -->
<!-- Your other HTML goes here -->

<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
 </div>