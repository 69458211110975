import { IsAuthService } from '@/service/is-auth.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-ttsaudiocollection',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    MatSelectModule,
    MatTooltipModule
  ],
  templateUrl: './ttsaudiocollection.component.html',
  styleUrl: './ttsaudiocollection.component.scss'
})
export class TtsaudiocollectionComponent implements OnInit{

  closeResult: any;
  modalReference: any;

  selectedAudioTTSName: any;
  dataTTSAudioType = [{id:1,audioType:"mp3"},{id:2,audioType:"wav"},{id:3,audioType:"amr"}];

  selectedAudioTTSType:any;
  dataTTSAudioFormatType = [{id:1,audioFormatType:"16-bit stereo"},{id:2,audioFormatType:"8-bit stereo"},{id:3,audioFormatType:"16-bit mono"},{id:4,audioFormatType:"8-bit mono"}]
  
  selectedTTSAudioLanguageType:any;
  dataTTSAudioLanguageType = [{id:1,audioLanguage:"en"},{id:2,audioLanguage:"fr"},{id:3,audioLanguage:"es"},{id:4,audioLanguage:"al"}]

  selectedTTSAudioVoiceType:any;
  dataTTSAudioVoiceType = [{id:1,audioVoice:"male"},{id:1,audioVoice:"female"}];

  prop: any;
  data: any;
  total:any;

  dataDeviceIds:any;
  selectedDeviceId:any;

  dataGroupName:any;
  selectedGroupName:any;
  groupNameProp:any;
  totalGroupName:any;

  showDeviceProp:any;
  showDeviceData:any;
  showDeviceTotal:any;

  isLoading = true; // Add this line to show the loader at the beginning
  isLoadingEdit= false;

  constructor(
    private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private isAuth: IsAuthService
  ){}

  ngOnInit(): void {
    this.apiToCall();
  }

  displayedDeviceData:any

  filterFromTable(input: any) {
    //console.log(input);
  
    if (!input) {
      // If there's no input, reset to the original dataset
      this.displayedDeviceData = [...this.data];
    } else {
      const res = this.data.filter(device => 
        Object.values(device).some(prop => 
          prop !== null && prop !== undefined && prop.toString().toLowerCase().includes(input.toLowerCase())
        )
      );
      this.displayedDeviceData = res;
    }
  }
  
  sortData(sortField: string, sortDirection: string) {
    this.displayedDeviceData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  apiToCall(){
    try {
      this.service.showAudioTTSFilesCollection().subscribe((data:any)=>{
        //console.log(data);
        this.prop = [];
        try {
          if (data['status'] == true) {
            //console.log("here");
    
            
              for (var property in data['data'][0]) {
                if (data['data'][0].hasOwnProperty(property)) {
                  //console.log("PROPERTY",property);
                  if (property.toLowerCase() != 'total') {
                    this.prop.push(property);
                  }
                  //console.log(this.prop);
                  
    
                }
              } 
            this.rearrangeArray();
            this.data = data['data'];
            this.displayedDeviceData = [...this.data];
            this.total = data['total_audiofiles'];

            this.isLoading = false
            
          } else {
            this.data = null;
            this.displayedDeviceData = [...this.data];
            this.total = data['total_audiofiles'];
            this.toast.info("No Groups Found");
            this.isLoading = false
          }
        } catch (error) {
          console.log(error);
          
        }
      })
    } catch (error) {
      console.log(error);
      
    }


    try {
      this.dataDeviceIds = null;
      this.service.getShowDevicesAPI().subscribe((data2:any)=>{
        //console.log("show devices",data2);
  
        if (data2['status'] == true) {
          //console.log("here");
  
          this.showDeviceProp = [];
          for (var propertyAddedDevice in data2['data'][0]) {
            if (data2['data'][0].hasOwnProperty(propertyAddedDevice)) {
              //console.log("PROPERTY",property);
              if (propertyAddedDevice.toLowerCase() != 'total') {
                this.showDeviceProp.push(propertyAddedDevice);
              }
              //console.log("prop",this.deviceProp);
            }
          }

          //this.rearrangeArray();

          this.showDeviceData = data2['data'];
          this.dataDeviceIds = data2['data'];
          this.showDeviceTotal = data2['total_devices'];
          //console.log("data",this.data);
        } else {
          this.data = null;
          this.toast.error("No Device Found");
        }
        
      })
    } catch (error) {
      console.log(error);
      
    }


    try {
      this.dataGroupName = null;

      this.service.getShowGroupAPI().subscribe((data:any)=>{
        //console.log("data",data);
  
        if (data['status'] == true) {
          //console.log("here");
  
          this.groupNameProp = [];
            for (var property in data['data'][0]) {
              if (data['data'][0].hasOwnProperty(property)) {
                //console.log("PROPERTY",property);
                if (property.toLowerCase() != 'total') {
                  this.groupNameProp.push(property);
                }
                //console.log(this.prop);
                
  
              }
            } 
          
          this.dataGroupName = data['data'];
          this.totalGroupName = data['total_groups'];
        } else {
          this.data = null;
          this.toast.error("No Groups Found");
        }
  
        
      });



    } catch (error) {
      console.log(error);
      
    }
  }

  capitalizeAndReplace(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}


  rearrangeArray() {

    // Use the JavaScript splice and unshift methods to move 'Date' to the beginning of the array

    const dateIdx6 = this.prop.indexOf('audio_voice');
    if (dateIdx6 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx6, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx5 = this.prop.indexOf('file_format');
    if (dateIdx5 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx5, 1)[0];
      this.prop.unshift(deviceNameElement);
    }


    const dateIdx3 = this.prop.indexOf('file_type');
    if (dateIdx3 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx3, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx2 = this.prop.indexOf('file_name');
    if (dateIdx2 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx2, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx4 = this.prop.indexOf('audio_name');
    if (dateIdx4 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx4, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx1 = this.prop.indexOf('text');
    if (dateIdx1 > -1) {
      const groupNameElement = this.prop.splice(dateIdx1, 1)[0];
      this.prop.unshift(groupNameElement);
    }

    const dateIdx = this.prop.indexOf('id');
    if (dateIdx > -1) {
      const userNameElement = this.prop.splice(dateIdx, 1)[0];
      this.prop.unshift(userNameElement);
    }
}

  userName = localStorage.getItem('UserName');

  addTTSAudioForm = new FormGroup({
    ttsAudioName: new FormControl('', [Validators.required]),
    ttsAudioConvertName : new FormControl('',[Validators.required]),
  });

  get ttsAudioName(): FormControl {
    return this.addTTSAudioForm.get('ttsAudioName') as FormControl;
  }

  get ttsAudioConvertName(): FormControl {
    return this.addTTSAudioForm.get('ttsAudioConvertName') as FormControl;
  }

  changeTTSAudioTypeName(value: any) {
    //console.log("audio TTS type",value);
    this.selectedAudioTTSName = value;
    
  }

  changeTTSAudioFormatTypeName(value:any){
    //console.log("audio TTS type",value);
    this.selectedAudioTTSType = value;
    
  }

  changeTTSAudioLanguageName(value:any){
    //console.log("audio TTS language",value);
    this.selectedTTSAudioLanguageType = value;
  }

  changeTTSAudioVoiceName(value:any){
    //console.log("audio voice",value);
    this.selectedTTSAudioVoiceType = value;
    
  }

  openAddAudioTTSForm(content:any){


    this.modalService.open(content, { ariaLabelledBy: 'addAudioTTSFile' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);


    // this.modalReference = this.modalService.open(open, { ariaLabelledBy: 'addAudioTTSFile' });
    // this.modalReference.result.then((result:any) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason:any) => {
    //   this.closeResult = `Dismissed `;
    // });
  }

  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}

  modal:any

  submitAndClose(modal:any) {
    // perform your actions here, like sending the form data

    // then close the modal
    this.modalReference.close('Save Clicked')
  }

  

  addTTSAudio(){
    //console.log("add tts audio");
    //console.log(this.addTTSAudioForm.value,this.selectedAudioTTSName,this.selectedAudioTTSType,this.selectedTTSAudioLanguageType,this.selectedTTSAudioVoiceType);
    try {
      let sendTTSAddData = {
        "audio_name": this.addTTSAudioForm.value.ttsAudioName,
        "text": this.addTTSAudioForm.value.ttsAudioConvertName,
        "file_type": this.selectedAudioTTSName,
        "file_format": this.selectedAudioTTSType,
        "audio_lang": this.selectedTTSAudioLanguageType,
        "audio_voice": this.selectedTTSAudioVoiceType
      }

      //console.log(sendTTSAddData);
      
      this.service.uploadAudioTTSFileCollection(sendTTSAddData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          if (data['status'] == true) {
            this.toast.success(data['message']);
            this.addTTSAudioForm.reset();
            this.selectedAudioTTSName = null
            this.selectedAudioTTSType = null
            this.selectedTTSAudioLanguageType = null
            this.selectedTTSAudioVoiceType = null
            this.ngOnInit();
          }else{
            this.toast.error(data['message']);
            this.ngOnInit()
          }
        }
      })

    } catch (error) {
      console.log(error);
      
    }
    
  }

  ttsCollectionAudioId:any;

  sendAudioFileOpenForm(sendAudioForm:any,audioId:any){
    //console.log(audioId);
    this.ttsCollectionAudioId = audioId;


    this.modalReference = this.modalService.open(sendAudioForm, { ariaLabelledBy: `sendAudio_${audioId}`});
      this.modalReference.result.then((result:any) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason:any) => {
        this.closeResult = `Dismissed `;
      });
  
  
  }

  ttsAudioToGroupForm = new FormGroup({
    selectedDeviceId2 : new FormControl('',[Validators.required])
  });

  get selectedDeviceId2(): FormControl {
    return this.ttsAudioToGroupForm.get('selectedDeviceId2') as FormControl;
  }

  sendToGroupAudioId:any;

  sendAudioFileToGroupOpenForm(sendTTSAudioToGroupForm:any,audioId:any){
    //console.log(audioId);
    //console.log("audio id",audioId);
    this.sendToGroupAudioId = audioId;

    this.modalReference = this.modalService.open(sendTTSAudioToGroupForm, { ariaLabelledBy: `sendAudioToGroup_${audioId}`});
      this.modalReference.result.then((result:any) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason:any) => {
        this.closeResult = `Dismissed `;
      });
  }

  sendAudioToGroup(){
    //console.log(this.sendToGroupAudioId,this.selectedGroupName);
    try {

      let sendTTSAudioToDevice = {
        "group" : this.selectedGroupName,
        "audiofile_id" : this.sendToGroupAudioId
      }
      //console.log(sendTTSAudioToDevice);
      this.service.audioCollectionGroupTTS(sendTTSAudioToDevice).subscribe({
        next: (data: any) => {
            if (data.status === true) {
                this.toast.success(data.message);
            } else {
                this.toast.error(data.message);
            }
            this.ngOnInit(); // Consider the necessity of reinitializing component
        },
        error: (error: any) => {
          //console.log(error);
          
            // Handle HTTP error status
            if (error && error.status) {
                // You can use a switch case to handle various error statuses
                switch (error.status) {
                    case 400:
                        this.toast.error("Bad Request: " + error.error.message);
                        break;
                    case 401:
                        this.toast.error("Unauthorized: " + error.error.message);
                        break;
                    case 403:
                        this.toast.error("Forbidden: " + error.error.message);
                        break;
                    case 404:
                        this.toast.error("Not Found: " + error.error.message);
                        break;
                    case 500:
                        this.toast.error("Internal Server Error: Please try again later.");
                        break;
                    default:
                        this.toast.error(error.error.message);
                }
            } else {
                // Handle case where error status is not available
                this.toast.error("An unexpected error occurred. Please try again.");
            }
        }
    });
      

    } catch (error) {
      console.log(error);
      
    }
    
  }

  sendAudioToDeviceForm = new FormGroup({
    selectedDeviceId1 : new FormControl('',[Validators.required])
  })

  get selectedDeviceId1(): FormControl {
    return this.sendAudioToDeviceForm.get('selectedDeviceId1') as FormControl;
  }


  changeGroupName(value:any){
    //console.log("group name",value);
    this.selectedGroupName = value;
  }

  changeDeviceId(value:any){
    //console.log(value);
    this.selectedDeviceId = value;
  }

  submitAndCloseSendModal(modal: any) {
    if (this.selectedDeviceId != '') {
      this.sendAudioToDevice();
      modal.modalReference.close();
    } else {
      this.toast.error('Please select a device ID before sending.');
    }
  }

  deleteTTSAudioFile(deleteId:any){
    //console.log(deleteId);
    try {
      let sendDeleteId = {
        "id": deleteId
      }

      this.service.deleteTTSAudioFilesCollection(sendDeleteId).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("heer");
          
          this.toast.success(data['message']);
          this.ngOnInit();
        }else{
          this.toast.error(data['message']);
          this.ngOnInit();
        }
      })

    } catch (error) {
      console.log(error);
      
    }
  }


  sendAudioToDevice(){
    //console.log(this.ttsCollectionAudioId);
    //console.log(this.selectedDeviceId);
    try {

      let sendTTSAudioToDevice = {
        "device_id" : this.selectedDeviceId,
        "audiofile_id" : this.ttsCollectionAudioId
      }
      //console.log(sendTTSAudioToDevice);
      this.service.audioCollectionTTS(sendTTSAudioToDevice).subscribe({
        next: (data: any) => {
            if (data.status === true) {
                this.toast.success(data.message);
            } else {
                this.toast.error(data.message);
            }
            this.ngOnInit(); // Consider the necessity of reinitializing component
        },
        error: (error: any) => {
          //console.log(error);
          
            // Handle HTTP error status
            if (error && error.status) {
                // You can use a switch case to handle various error statuses
                switch (error.status) {
                    case 400:
                        this.toast.error("Bad Request: " + error.error.message);
                        break;
                    case 401:
                        this.toast.error("Unauthorized: " + error.error.message);
                        break;
                    case 403:
                        this.toast.error("Forbidden: " + error.error.message);
                        break;
                    case 404:
                        this.toast.error("Not Found: " + error.error.message);
                        break;
                    case 500:
                        this.toast.error("Internal Server Error: Please try again later.");
                        break;
                    default:
                        this.toast.error(error.error.message);
                }
            } else {
                // Handle case where error status is not available
                this.toast.error("An unexpected error occurred. Please try again.");
            }
        }
    });
      

    } catch (error) {
      console.log(error);
      
    }
    
  }

  
  
  logout() {
    this.isAuth.logout();
  }

}
