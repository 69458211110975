<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Language Packs</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Language Pack Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">ZIP File Pack List of {{viewZipFileFolderPath}}</h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="flex justify-between items-center mb-3">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;<b>{{ viewZipFileTotal }}</b>&nbsp;Records
                                </h3>
                                <h3 class="text-2xl">
                                    Total File Size:&nbsp;<b>{{ roundFigOfFileSize }}</b>&nbsp;KB
                                </h3>
                            </div>
                        </div>

                        <div class="flex-column text-right" style="margin-bottom: 20px;">
                            <button type="button" class="btn btn-primary ml-1" (click)="triggerFileInput()" matTooltip="Upload Audio File">
                                <!-- <i class="fas fa-upload"></i> -->
                                 Upload Single Audio File
                            </button>
                            <input type="file" id="fileInput" style="display: none;" (change)="onFileSelected($event)"
                                accept=".mp3, .wav, .ogg, .m4a, .aac, .flac" />
                        </div>
                        

                        <table id="example2" class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th *ngFor="let field of viewZipFileProps">
                                        {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </th>
                                    <th>Play Audio File</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let datas of viewZipFileDisplayedData; let i = index">
                                    <ng-container *ngFor="let val of viewZipFileProps">
                                        <td>
                                            {{ datas[val] }}
                                        </td>
                                    </ng-container>

                                    <td>
                                        <button type="button" value="{{ datas['id'] }}"
                                            id="playAudioFile_{{ datas['id'] }}" class="btn btn-primary ml-1"
                                            (click)="playAudioFile(datas['file_name'], i)" matTooltip="Play Audio File">
                                            <i class="fa fa-play"></i>
                                        </button>
                                        <!-- Audio container for each row -->
                                        <div id="audioContainer_{{i}}" class="audio-container"></div>
                                    </td>

                                    <td>
                                        <button type="button" value="{{ datas['id'] }}"
                                            id="downloadSingleFiles_{{ datas['id'] }}" class="btn btn-primary ml-1"
                                            (click)="downloadSingleFiles(datas['file_name'])"
                                            matTooltip="Download Single Files">
                                            <i class="fa fa-download"></i>
                                        </button>

                                        <button type="button" value="{{ datas['id'] }}"
                                            id="deleteLangPack_{{ datas['id'] }}" class="btn btn-danger ml-1"
                                            (click)="deleteSingleFiles(datas['file_name'])"
                                            matTooltip="Delete Single File">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>

<!-- /.content -->



<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>