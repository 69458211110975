import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-testaudiogroup',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './testaudiogroup.component.html',
    styleUrl: './testaudiogroup.component.scss'
})
export class TestaudiogroupComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {}

    userName = localStorage.getItem('UserName');

    sendAudioToGroupForm = new FormGroup({
        apiKey: new FormControl('', [Validators.required]),
        audioFileCode: new FormControl('', [Validators.required]),
        groupName: new FormControl('', [Validators.required])
    });

    get apiKey(): FormControl {
        return this.sendAudioToGroupForm.get('apiKey') as FormControl;
    }

    get audioFileCode(): FormControl {
        return this.sendAudioToGroupForm.get('audioFileCode') as FormControl;
    }

    get groupName(): FormControl {
        return this.sendAudioToGroupForm.get('groupName') as FormControl;
    }

    sendAudioToGroup() {
        let sendToGroup = {
            ip_key: this.sendAudioToGroupForm.value.apiKey,
            group: this.sendAudioToGroupForm.value.groupName,
            audiofile_code: this.sendAudioToGroupForm.value.audioFileCode
        };

        //console.log(sendToGroup);

        this.service
            .postSendToGroupAudio(sendToGroup)
            .subscribe((data: any) => {
                //console.log(data);
                let responses = data;

                responses.forEach((response: any) => {
                    // Display toast with message based on status
                    this.showToast(response.message, response.status);
                });
            });
    }

    showToast(message: any, status: boolean) {
        if (status == true) {
            // Call success toast function with message
            this.toast.success(message);
        } else {
            // Call error toast function with message
            this.toast.error(message);
        }
    }

    logout() {
        this.isAuth.logout();
    }

    getcURL1 = `curl --location "http://91.107.200.91:6500/test_audio_device_group" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"ip_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"group\\\":\\\"Bonrix\\\",\\\"audiofile_code\\\":\\\"TiBzqctD\\\"}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }
}
