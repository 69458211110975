import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {
    Component,
    ElementRef,
    NgZone,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faIndianRupee} from '@fortawesome/free-solid-svg-icons';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {catchError, throwError} from 'rxjs';
import {FormsModule} from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {ChangeDetectorRef} from '@angular/core';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {HttpErrorResponse} from '@angular/common/http';
@Component({
    selector: 'app-staff',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './staff.component.html',
    styleUrl: './staff.component.scss'
})
export class StaffComponent implements OnInit {
    prop: any;
    data: any;
    total: any;
    displayedStaffData: any;

    staffRoles = [
        {id: 1, staffName: `Manager`},
        {id: 2, staffName: `Customer Care`},
        {id: 3, staffName: `Supervisior`}
    ];

    isLoading = true; // Add this line to show the loader at the beginning
    isLoadingEdit = false;

    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getStaff();
    }

    getStaff() {
        this.service.getShowStaff().subscribe({
            next: (data) => {
                //console.log(data);
                if (data['status'] === true) {
                    this.isLoading = false;

                    this.prop = [];
                    for (var property in data['data'][0]) {
                        if (data['data'][0].hasOwnProperty(property)) {
                            //console.log("PROPERTY",property);
                            if (property.toLowerCase() != 'total') {
                                this.prop.push(property);
                            }
                            //console.log(this.prop);
                        }
                    }
                    this.rearrangeArray();
                    this.data = data['data'];
                    this.displayedStaffData = [...this.data];
                    //this.total = data['total_apis'];
                } else {
                    this.isLoading = false;
                    this.toast.info(data['data']);
                }
            },
            error: (err) => {
                console.log(err);
            }
        });
    }

    rearrangeArray() {
        // Use the JavaScript splice and unshift methods to move 'Date' to the beginning of the array

        const dateIdx3 = this.prop.indexOf('email_id');
        if (dateIdx3 > -1) {
            const active = this.prop.splice(dateIdx3, 1)[0];
            this.prop.unshift(active);
        }

        const dateIdx2 = this.prop.indexOf('full_name');
        if (dateIdx2 > -1) {
            const active = this.prop.splice(dateIdx2, 1)[0];
            this.prop.unshift(active);
        }

        const dateIdx4 = this.prop.indexOf('staff_user_name');
        if (dateIdx4 > -1) {
            const active = this.prop.splice(dateIdx4, 1)[0];
            this.prop.unshift(active);
        }

        const dateIdx1 = this.prop.indexOf('id');
        if (dateIdx1 > -1) {
            const active = this.prop.splice(dateIdx1, 1)[0];
            this.prop.unshift(active);
        }
    }

    filterFromTable(input: any) {
        //console.log(input);

        if (!input) {
            // If there's no input, reset to the original dataset
            this.displayedStaffData = [...this.data];
        } else {
            const res = this.data.filter((device) =>
                Object.values(device).some(
                    (prop) =>
                        prop !== null &&
                        prop !== undefined &&
                        prop
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                )
            );
            this.displayedStaffData = res;
        }
    }

    sortData(sortField: string, sortDirection: string) {
        this.displayedStaffData.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortField] > b[sortField]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    capitalizeAndReplace(str: string): string {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    }

    closeResult: any;

    modal: any;
    submitAndClose(modal: any) {
        // perform your actions here, like sending the form data

        // then close the modal
        //this.modalReference.close('Save Clicked');
        this.modalService.dismissAll();
    }

    private getDismissReason(reason: any): string {
        switch (reason) {
            case ModalDismissReasons.ESC:
                return 'by pressing ESC';
            case ModalDismissReasons.BACKDROP_CLICK:
                return 'by clicking on a backdrop';
            default:
                return `with: ${reason}`;
        }
    }

    editStaffForm = new FormGroup({
        editStaffName: new FormControl('', [Validators.required]),
        editStaffFullName: new FormControl('', [Validators.required]),
        editStaffContactNo: new FormControl('', [Validators.required]),
        editStaffEmail: new FormControl('', [Validators.required]),
        editStaffRole: new FormControl('mn', [Validators.required])
    });

    get editStaffName(): FormControl {
        return this.editStaffForm.get('editStaffName') as FormControl;
    }

    get editStaffFullName(): FormControl {
        return this.editStaffForm.get('editStaffFullName') as FormControl;
    }

    get editStaffContactNo(): FormControl {
        return this.editStaffForm.get('editStaffContactNo') as FormControl;
    }

    get editStaffEmail(): FormControl {
        return this.editStaffForm.get('editStaffEmail') as FormControl;
    }

    get editStaffRole(): FormControl {
        return this.editStaffForm.get('editStaffRole') as FormControl;
    }

    id: any;
    // sName: any;
    // sFullName: any;
    // sContactNo: any;
    // sEmail: any;
    // sRole: any;

    openEditUpdateAPIForm(
        editStaffFormdATA: TemplateRef<any>,
        editId: any,
        editStaffName: any,
        editFullName: any,
        editContactNo: any,
        editEmail: any,
        editRole: any
    ) {
        //console.log('edit id', editId);

        this.id = editId;
        // this.sName = editStaffName;
        // this.sFullName = editFullName;
        // this.sContactNo = editContactNo;
        // this.sEmail = editEmail;
        // this.sRole = editRole;

        this.editStaffForm.controls.editStaffName.setValue(editStaffName);
        this.editStaffForm.controls.editStaffFullName.setValue(editFullName);
        this.editStaffForm.controls.editStaffContactNo.setValue(editContactNo);
        this.editStaffForm.controls.editStaffEmail.setValue(editEmail);
        this.editStaffForm.controls.editStaffRole.setValue(editRole);

        this.modalService
            .open(editStaffFormdATA, {
                ariaLabelledBy: `editUpdateStaff_${editId}`
            })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    editStaffSubmit() {
        try {
            const sendData = {
                id: this.id,
                staff_name: this.editStaffForm.value.editStaffName,
                full_name: this.editStaffForm.value.editStaffFullName,
                contact_no: this.editStaffForm.value.editStaffContactNo,
                email_id: this.editStaffForm.value.editStaffEmail,
                role: this.editStaffForm.value.editStaffRole
            };

            this.service.postEditStaff(sendData).subscribe({
                next: (data) => {
                    if (data['status'] === true) {
                        this.toast.success(data['message']);
                        this.ngOnInit();
                    } else {
                        this.toast.error(data['message']);
                    }
                },
                error: (err) => {
                    console.log(err);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    deleteStaff(deleteId: any) {
        //console.log(deleteId);
        try {
            let sendDelId = {
                id: deleteId
            };

            this.service.postDeleteStaff(sendDelId).subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    //console.log("heer");

                    this.toast.success(data['message']);
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    userName = localStorage.getItem('UserName');

    openAddStaffForm(content: TemplateRef<any>) {
        this.modalService
            .open(content, {ariaLabelledBy: 'addStaffBtn'})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    addStaffForm = new FormGroup({
        staffName: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        staffFullName: new FormControl('', [Validators.required]),
        staffContactNo: new FormControl('', [Validators.required]),
        staffEmail: new FormControl('', [Validators.required]),
        staffRole: new FormControl('Manager', [Validators.required])
    });

    get staffRole(): FormControl {
        return this.addStaffForm.get('staffRole') as FormControl;
    }

    get staffEmail(): FormControl {
        return this.addStaffForm.get('staffEmail') as FormControl;
    }

    get staffContactNo(): FormControl {
        return this.addStaffForm.get('staffContactNo') as FormControl;
    }

    get staffFullName(): FormControl {
        return this.addStaffForm.get('staffFullName') as FormControl;
    }

    get password(): FormControl {
        return this.addStaffForm.get('password') as FormControl;
    }

    get staffName(): FormControl {
        return this.addStaffForm.get('staffName') as FormControl;
    }

    addStaffFormSubmit() {
        try {
            let sendData = {
                staff_name: this.addStaffForm.value.staffName,
                password: this.addStaffForm.value.password,
                full_name: this.addStaffForm.value.staffFullName,
                contact_no: this.addStaffForm.value.staffContactNo,
                email_id: this.addStaffForm.value.staffEmail,
                role: this.addStaffForm.value.staffRole
            };

            //console.log(sendData);

            this.service.postAddStaff(sendData).subscribe({
                next: (data) => {
                    //console.log(data);
                    if (data['status'] === true) {
                        this.addStaffForm.reset();
                        this.toast.success(data['message']);
                        this.ngOnInit();
                    } else {
                        this.toast.error(data['message']);
                        //this.ngOnInit();
                    }
                },
                error: (err) => {
                    console.log(err);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    staffPasswordChangeId: any;

    changePassword(
        changeStaffPassword: TemplateRef<any>,
        changePasswordId: any
    ) {
        //console.log('edit id', changePasswordId);
        this.staffPasswordChangeId = changePasswordId;

        this.modalService
            .open(changeStaffPassword, {
                ariaLabelledBy: `changeStaffPassword_${changePasswordId}`
            })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    changePasswordForm = new FormGroup({
        changeStaffPassword: new FormControl('', [Validators.required])
    });

    get changeStaffPassword(): FormControl {
        return this.changePasswordForm.get(
            'changeStaffPassword'
        ) as FormControl;
    }

    updateStaffPassword() {
        try {
            const sendData = {
                id: this.staffPasswordChangeId,
                new_password: this.changePasswordForm.value.changeStaffPassword
            };

            this.service.postChangeStaffPassword(sendData).subscribe({
                next: (data) => {
                    //console.log(data);
                    if (data['status'] === true) {
                        this.changePasswordForm.reset();
                        this.ngOnInit();
                        this.toast.success(data['message']);
                    } else {
                        this.toast.error(data['message']);
                    }
                },
                error: (err) => {
                    console.log(err);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    activeStaffStatus: any;
    activeStaffId: any;

    updateStaffStatusActive(e: any) {
        //console.log(e);
        this.activeStaffStatus = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).checked;
        this.activeStaffId = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).value;
        //console.log(this.activeStaffId, this.activeStaffStatus);

        let sendStaffStatus = {
            id: this.activeStaffId
            //"status":this.activeDeviceStatus
        };
        //console.log(sendStaffStatus);

        this.service
            .postUpdateStaffStatus(sendStaffStatus)
            .subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
    }
}
