<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Language Packs</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Language Pack Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Language Pack List</h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="flex justify-between items-center mb-3">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;{{ total }}&nbsp;Records
                                </h3>
                            </div>
                            <div class="flex-column text-right">
                                <button
                                    type="button"
                                    id="addLanguagePackBtn"
                                    (click)="open(content)"
                                    class="btn btn-primary"
                                >
                                    Create Language Pack
                                </button>
                                <div class="search-container flex items-center mt-2 ml-2">
                                    <label for="searchDevicesInput" class="form-label mt-2 mr-2">Search:</label>
                                    <input type="text" id="searchDevicesInput" placeholder="Search Devices..." (input)="filterFromTable($event.target.value)" class="form-control">
                                </div>
                            </div>
                        </div>

                        <table
                            id="example2"
                            class="table table-bordered table-hover"
                        >
                            <thead>
                                <tr>
                                    <th *ngFor="let field of prop">
                                        {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </th>
                                    <th>ZIP</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let datas of displayedDeviceData; let i = index">
                                <tr>
                                    <ng-container *ngFor="let val of prop">
                                        <td *ngIf="val.toLowerCase() !== 'lang_status' && val.toLowerCase() !== 'default_lang'  " id="id_{{ i }}" attr.data-value="{{ datas[val] }}">
                                            {{ datas[val] }}
                                        </td>

                                        <td *ngIf="
                                                datas[val] === 1 &&
                                                val.toLowerCase() ===
                                                    'lang_status'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateLanguageStatusActive(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" checked />
                                            </label>
                                        </td>
                                        <td *ngIf="
                                                datas[val] !== 1 &&
                                                val.toLowerCase() ===
                                                    'lang_status'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateLanguageStatusActive(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" unchecked />
                                            </label>
                                        </td>

                                        <td *ngIf="
                                                datas[val] === 1 &&
                                                val.toLowerCase() ===
                                                    'default_lang'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateDefaultLanguageStatus(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" checked />
                                            </label>
                                        </td>
                                        <td *ngIf="
                                                datas[val] !== 1 &&
                                                val.toLowerCase() ===
                                                    'default_lang'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateDefaultLanguageStatus(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" unchecked />
                                            </label>
                                        </td>

                                    </ng-container>

                                    <!-- <td class="px-4 py-2 border text-center">1</td>
                  <td class="px-4 py-2 border text-center">John Doe</td> -->
                                    <td>
                                        <button
                                            type="button"
                                            class="btn btn-primary ml-1"
                                            (click)="triggerFileInput(datas['id'])"
                                            matTooltip="Upload Zip File"
                                        >
                                            <i class="fas fa-upload"></i>
                                        </button>
                                        <input
                                            type="file"
                                            id="fileInput_{{ datas['id'] }}"
                                            style="display: none;"
                                            (change)="onFileSelected($event, datas['id'])"
                                            accept=".zip"
                                        />

                                        <button
                                            type="button"
                                            class="btn btn-primary ml-1"
                                            (click)="goToZipFiles(datas['id'])"
                                            matTooltip="View Uploaded Zip Files"
                                        >
                                            <i class="fa fa-eye"></i>
                                        </button>

                                        <button
                                            type="button"
                                            value="{{ datas['id'] }}"
                                            id="downloadZipFiles_{{
                                                datas['id']
                                            }}"
                                            class="btn btn-primary ml-1"
                                            (click)="
                                                downloadZipFiles(datas['id'])
                                            "
                                            ng-click="getValue($event)"
                                            matTooltip="Download Zip File"
                                        >
                                            <i class="fa fa-download"></i>
                                        </button>

                                    </td>
                                    <td>
                                        
                                        <button
                                            type="button"
                                            value="{{ datas['id'] }}"
                                            id="deleteLangPack_{{
                                                datas['id']
                                            }}"
                                            class="btn btn-danger ml-1"
                                            (click)="
                                                deleteLangPack(datas['id'])
                                            "
                                            ng-click="getValue($event)"
                                            matTooltip="Delete Language Pack"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </button>

                                        <button
                                            type="button"
                                            value="{{ datas['id'] }}"
                                            id="deleteZip_{{
                                                datas['id']
                                            }}"
                                            class="btn btn-warning ml-1"
                                            (click)="
                                                deleteZipFiles(datas['id'])
                                            "
                                            ng-click="getValue($event)"
                                            matTooltip="Delete All Files"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </button>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->


<ng-template #content let-modal>
    <form [formGroup]="addLanguagePackForm" (ngSubmit)="addLanguagePackSubmit()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Language Pack</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div>
                <label
                    for="groupName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Language Name</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="languageName"
                        id="languageName"
                        formControlName="languageName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Language Name"
                    />
                </div>
            </div>&nbsp;

            <div>
                <label for="language" class="block text-2xl font-medium leading-6 text-gray-900">Change
                    Language</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select a language</mat-label>
                        <mat-select formControlName="language">
                            <mat-option value="English">English</mat-option>
                            <mat-option value="Hindi">Hindi</mat-option>
                            <mat-option value="Gujarati">Gujarati</mat-option>
                            <mat-option value="Marathi">Marathi</mat-option>
                            <mat-option value="Kannada">Kannada</mat-option>
                            <mat-option value="Malayalam">Malayalam</mat-option>
                            <mat-option value="Punjabi">Punjabi</mat-option>
                            <mat-option value="Tamil">Tamil</mat-option>
                            <mat-option value="Telugu">Telugu</mat-option>
                            <mat-option value="Bengali">Bengali</mat-option>
                            <mat-option value="Urdu">Urdu</mat-option>
                            <mat-option value="Spanish">Spanish</mat-option>
                            <mat-option value="French">French</mat-option>
                            <mat-option value="German">German</mat-option>
                            <mat-option value="Chinese (Mandarin)">Chinese (Mandarin)</mat-option>
                            <mat-option value="Japanese">Japanese</mat-option>
                            <mat-option value="Korean">Korean</mat-option>
                            <mat-option value="Russian">Russian</mat-option>
                            <mat-option value="Portuguese">Portuguese</mat-option>
                            <mat-option value="Italian">Italian</mat-option>
                            <mat-option value="Arabic">Arabic</mat-option>
                            <mat-option value="Dutch">Dutch</mat-option>
                            <mat-option value="Swedish">Swedish</mat-option>
                            <mat-option value="Norwegian">Norwegian</mat-option>
                            <mat-option value="Danish">Danish</mat-option>
                            <mat-option value="Finnish">Finnish</mat-option>
                            <mat-option value="Turkish">Turkish</mat-option>
                            <mat-option value="Greek">Greek</mat-option>
                            <mat-option value="Polish">Polish</mat-option>
                            <mat-option value="Ukrainian">Ukrainian</mat-option>
                            <mat-option value="Czech">Czech</mat-option>
                            <mat-option value="Hungarian">Hungarian</mat-option>
                            <mat-option value="Romanian">Romanian</mat-option>
                            <mat-option value="Bulgarian">Bulgarian</mat-option>
                            <mat-option value="Hebrew">Hebrew</mat-option>
                            <mat-option value="Thai">Thai</mat-option>
                            <mat-option value="Vietnamese">Vietnamese</mat-option>
                            <mat-option value="Indonesian">Indonesian</mat-option>
                            <mat-option value="Malay">Malay</mat-option>
                            <mat-option value="Filipino">Filipino</mat-option>
                            <mat-option value="Swahili">Swahili</mat-option>
                            <mat-option value="Zulu">Zulu</mat-option>
                            <mat-option value="Afrikaans">Afrikaans</mat-option>
                            <mat-option value="Persian (Farsi)">Persian (Farsi)</mat-option>
                            <mat-option value="Pashto">Pashto</mat-option>
                            <mat-option value="Kazakh">Kazakh</mat-option>
                            <mat-option value="Uzbek">Uzbek</mat-option>
                            <mat-option value="Azerbaijani">Azerbaijani</mat-option>
                            <mat-option value="Armenian">Armenian</mat-option>
                            <mat-option value="Georgian">Georgian</mat-option>
                            <mat-option value="Mongolian">Mongolian</mat-option>

                            <!-- Add more language options as needed -->
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>




<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>