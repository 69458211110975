import { IsAuthService } from '@/service/is-auth.service';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-onlinedashboard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatPaginatorModule
  ],
  templateUrl: './onlinedashboard.component.html',
  styleUrl: './onlinedashboard.component.scss'
})
export class OnlinedashboardComponent implements OnInit {

  totalDashboard:number = 8; 

  totalDevice5Min: any;
  active5MinCount: any;
  deActive5MinCount: any;

  totalDevice10Min:any;
  active10MinCount:any;
  deActive10MinCount:any;

  totalDevice15Min:any;
  active15MinCount:any;
  deActive15MinCount:any;

  totalDevice30Min:any;
  active30MinCount:any;
  deActive30MinCount:any;

  totalDevice1Hour:any;
  active1HourCount:any;
  deActive1HourCount:any;

  totalDevice6Hour:any;
  active6HourCount:any;
  deActive6HourCount:any;

  totalDevice12Hour:any;
  active12HourCount:any;
  deActive12HourCount:any;

  deActive24HourCount: any;
  active24HourCount: any;
  totalDevice24Hour: any;

  showActiveLogProp: any;
  showActiveLogTotal: any;
  closeResult: any;
  showActiveLogData: any;

  showDeActiveLogProp:any;
  showDeActiveLogTotal:any;
  showDeActiveLogData:any;

  isLoading = true;

  constructor(
    private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private router: Router,
    private isAuth: IsAuthService,
    private http:HttpClient,
    private renderer: Renderer2
  ){}

  @ViewChild('openActiveModalShowdata') openActiveModalShowdata: any;
  @ViewChild('openDeActiveModalShowdata') openDeActiveModalShowdata: any;

  ngOnInit(): void {
    this.min5();
    this.min10();
    this.min15();
    this.min30();
    this.hour1();
    this.hour6();
    this.hour12();
    this.hour24();
  }

  userName = localStorage.getItem('UserName');

  isActiveClicked5Min = false;
  isActiveClicked10Min = false;
  isActiveClicked15Min = false;
  isActiveClicked30Min = false;
  isActiveClicked1Hour = false;
  isActiveClicked6Hour = false;
  isActiveClicked12Hour = false;
  isActiveClicked24Hour = false;

  activeClicked(openActiveModalShowdata:any,intervalValue:number){
    //console.log("active",intervalValue);

    this.isActiveClicked5Min = false;
    this.isActiveClicked10Min = false;
    this.isActiveClicked15Min = false;
    this.isActiveClicked30Min = false;
    this.isActiveClicked1Hour = false;
    this.isActiveClicked6Hour = false;
    this.isActiveClicked12Hour = false;
    this.isActiveClicked24Hour = false;

    if (intervalValue === 5) {
      this.isActiveClicked5Min = true;
    } else if (intervalValue === 10) {
      this.isActiveClicked10Min = true;
    } else if (intervalValue === 15) {
      this.isActiveClicked15Min = true;
    } else if (intervalValue === 30) {
      this.isActiveClicked30Min = true;
    } else if (intervalValue === 60) {
      this.isActiveClicked1Hour = true;
    } else if (intervalValue === 360) {
      this.isActiveClicked6Hour = true;
    } else if (intervalValue === 720) {
      this.isActiveClicked12Hour = true;
    } else if (intervalValue === 1440) {
      this.isActiveClicked24Hour = true;
    }


    try {
      let sendData = {
        "interval" : [intervalValue]
      }
  
      this.service.postGetActiveDeviceList(sendData).subscribe((data:any)=>{
        //console.log(data);
  
        if (data['status'] == true && data['data'] && typeof data['data'][intervalValue] === 'object') {
          this.showActiveLogProp = [];
          for (var property in data['data'][intervalValue]['active_device'][0]) {
            if (data['data'][intervalValue]['active_device'][0].hasOwnProperty(property)) {
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.showActiveLogProp.push(property);
              }
            }
          }

          if (data['data'][intervalValue]['total_active_devices'] !== undefined) {
            this.showActiveLogTotal = data['data'][intervalValue]['total_active_devices'];
            if(this.showActiveLogTotal > 0){
              this.modalReference = this.modalService.open(this.openActiveModalShowdata, { ariaLabelledBy: 'activeClicked5MinCount' });
              this.modalReference.result.then((result:any) => {
                this.closeResult = `Closed with: ${result}`;
              }, (reason:any) => {
                this.closeResult = `Dismissed `;
                this.isActiveClicked5Min = false;
                this.isActiveClicked10Min = false;
                this.isActiveClicked15Min = false;
                this.isActiveClicked30Min = false;
                this.isActiveClicked1Hour = false;
                this.isActiveClicked6Hour = false;
                this.isActiveClicked12Hour = false;
                this.isActiveClicked24Hour = false;
              });
            } else {
              this.toast.info("NO ACTIVE DEVICES");
              this.isActiveClicked5Min = false;
              this.isActiveClicked10Min = false;
              this.isActiveClicked15Min = false;
              this.isActiveClicked30Min = false;
              this.isActiveClicked1Hour = false;
              this.isActiveClicked6Hour = false;
              this.isActiveClicked12Hour = false;
              this.isActiveClicked24Hour = false;
            }
            this.showActiveLogData = data['data'][intervalValue]['active_device'];
            this.showActiveLogTotal = data['data'][intervalValue]['total_active_devices'];
          }
        } else {
          this.showActiveLogData = null;
          this.toast.info("NO ACTIVE DEVICES");
          this.isActiveClicked5Min = false;
          this.isActiveClicked10Min = false;
          this.isActiveClicked15Min = false;
          this.isActiveClicked30Min = false;
          this.isActiveClicked1Hour = false;
          this.isActiveClicked6Hour = false;
          this.isActiveClicked12Hour = false;
          this.isActiveClicked24Hour = false;
        }
      })
  
    } catch (error) {
      console.log(error);
    }

    
  }

  


  isDeActiveClicked5Min = false;
  isDeActiveClicked10Min = false;
  isDeActiveClicked15Min = false;
  isDeActiveClicked30Min = false;
  isDeActiveClicked1Hour = false;
  isDeActiveClicked6Hour = false;
  isDeActiveClicked12Hour = false;
  isDeActiveClicked24Hour = false;

  deActiveClicked(openDeActiveModalShowdata:any,intervalValue:any){
    
    //console.log("Deactive Clicked");
    this.isDeActiveClicked5Min = false;
    this.isDeActiveClicked10Min = false;
    this.isDeActiveClicked15Min = false;
    this.isDeActiveClicked30Min = false;
    this.isDeActiveClicked1Hour = false;
    this.isDeActiveClicked6Hour = false;
    this.isDeActiveClicked12Hour = false;
    this.isDeActiveClicked24Hour = false;

    if (intervalValue === 5) {
      this.isDeActiveClicked5Min = true;
    } else if (intervalValue === 10) {
      this.isDeActiveClicked10Min = true;
    } else if (intervalValue === 15) {
      this.isDeActiveClicked15Min = true;
    } else if (intervalValue === 30) {
      this.isDeActiveClicked30Min = true;
    } else if (intervalValue === 60) {
      this.isDeActiveClicked1Hour = true;
    } else if (intervalValue === 360) {
      this.isDeActiveClicked6Hour = true;
    } else if (intervalValue === 720) {
      this.isDeActiveClicked12Hour = true;
    } else if (intervalValue === 1440) {
      this.isDeActiveClicked24Hour = true;
    }


    try {
      let sendData = {
        "interval" : [intervalValue]
      }
  
      this.service.postGetDeActiveDeviceList(sendData).subscribe((data:any)=>{
        //console.log(data);
  
        if (data['status'] == true && data['data'] && typeof data['data'][intervalValue] === 'object') {
          this.showDeActiveLogProp = [];
          for (var property in data['data'][intervalValue]['inactive_device'][0]) {
            if (data['data'][intervalValue]['inactive_device'][0].hasOwnProperty(property)) {
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.showDeActiveLogProp.push(property);
              }
            }
          }

          if (data['data'][intervalValue]['total_inactive_devices'] !== undefined) {
            this.showDeActiveLogTotal = data['data'][intervalValue]['total_inactive_devices'];
            if(this.showDeActiveLogTotal > 0){
              this.modalReference = this.modalService.open(this.openDeActiveModalShowdata, { ariaLabelledBy: 'deActiveClicked5MinCount' });
              this.modalReference.result.then((result:any) => {
                this.closeResult = `Closed with: ${result}`;
              }, (reason:any) => {
                this.closeResult = `Dismissed `;
                this.isDeActiveClicked5Min = false;
                this.isDeActiveClicked10Min = false;
                this.isDeActiveClicked15Min = false;
                this.isDeActiveClicked30Min = false;
                this.isDeActiveClicked1Hour = false;
                this.isDeActiveClicked6Hour = false;
                this.isDeActiveClicked12Hour = false;
                this.isDeActiveClicked24Hour = false;
              });
            } else {
              this.toast.info("NO DEACTIVE DEVICES");
                this.isDeActiveClicked5Min = false;
                this.isDeActiveClicked10Min = false;
                this.isDeActiveClicked15Min = false;
                this.isDeActiveClicked30Min = false;
                this.isDeActiveClicked1Hour = false;
                this.isDeActiveClicked6Hour = false;
                this.isDeActiveClicked12Hour = false;
                this.isDeActiveClicked24Hour = false;
            }
            this.showDeActiveLogData = data['data'][intervalValue]['inactive_device'];
            this.showDeActiveLogTotal = data['data'][intervalValue]['total_inactive_devices'];
          }
        } else {
          this.showDeActiveLogData = null;
          this.toast.info("NO DEACTIVE DEVICES");
          this.isDeActiveClicked5Min = false;
          this.isDeActiveClicked10Min = false;
          this.isDeActiveClicked15Min = false;
          this.isDeActiveClicked30Min = false;
          this.isDeActiveClicked1Hour = false;
          this.isDeActiveClicked6Hour = false;
          this.isDeActiveClicked12Hour = false;
          this.isDeActiveClicked24Hour = false;
        }
      })
  
    } catch (error) {
      console.log(error);
    }


    
  }





  modalReference: any;
  modal:any

  // submitAndClose(modal:any) {
  //   // perform your actions here, like sending the form data

  //   // then close the modal
  //   this.modalReference.close('Save Clicked')
  // }

  submitAndClose(modal: any) {
    // perform your actions here, like sending the form data

    // then close the modal
    //this.modalReference.close('Save Clicked');
    this.modalService.dismissAll();



    // this.modalService.open(content, { ariaLabelledBy: 'addGroupBtn' }).result.then(
		// 	(result) => {
		// 		this.closeResult = `Closed with: ${result}`;
		// 	},
		// 	(reason) => {
		// 		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		// 	},
		// );



  }

  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}



  min5(){
    //console.log("5 min clicked");

    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [5]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice5Min = data['device_status_counts'][5]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active5MinCount = data['device_status_counts'][5]['active_count'];
          this.deActive5MinCount = data['device_status_counts'][5]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  min10(){
    //console.log("10 min clicked");

    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [10]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice10Min = data['device_status_counts'][10]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active10MinCount = data['device_status_counts'][10]['active_count'];
          this.deActive10MinCount = data['device_status_counts'][10]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  min15(){
    //console.log("15 min clicked");
    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [15]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice15Min = data['device_status_counts'][15]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active15MinCount = data['device_status_counts'][15]['active_count'];
          this.deActive15MinCount = data['device_status_counts'][15]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  min30(){
    //console.log("30 min clicked");

    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [30]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice30Min = data['device_status_counts'][30]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active30MinCount = data['device_status_counts'][30]['active_count'];
          this.deActive30MinCount = data['device_status_counts'][30]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  hour1(){
    //console.log("1 hour clicked");
    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [60]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice1Hour = data['device_status_counts'][60]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active1HourCount = data['device_status_counts'][60]['active_count'];
          this.deActive1HourCount = data['device_status_counts'][60]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  hour6(){
    //console.log("6 hour clicked");
    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [360]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice6Hour = data['device_status_counts'][360]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active6HourCount = data['device_status_counts'][360]['active_count'];
          this.deActive6HourCount = data['device_status_counts'][360]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  hour12(){
    //console.log("12 hour clicked");
    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [720]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice12Hour = data['device_status_counts'][720]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active12HourCount = data['device_status_counts'][720]['active_count'];
          this.deActive12HourCount = data['device_status_counts'][720]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  hour24(){
    //console.log("24 hour clicked");
    try {

      //this.isLoading = true; // Initialize the loader state to false
      let sendData = {
        "interval" : [1440]
      }

      //console.log(sendData);

      this.service.postDeviceStatusCount(sendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("in if");
          this.totalDevice24Hour = data['device_status_counts'][1440]['total_devices'];
          //console.log(this.totalDevice5Min);
          this.active24HourCount = data['device_status_counts'][1440]['active_count'];
          this.deActive24HourCount = data['device_status_counts'][1440]['inactive_count'];
          this.isLoading = false; // Initialize the loader state to false
        }else{
          this.toast.error("No Data Found");
          this.isLoading = false; // Initialize the loader state to false
        }
      })
      
      
    } catch (error) {
      console.log(error);
      
    }
  }

  logout() {
    this.isAuth.logout();
  }
  

}
