import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-tts',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './tts.component.html',
    styleUrl: './tts.component.scss'
})
export class TtsComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {}

    userName = localStorage.getItem('UserName');

    ttsFormData = new FormGroup({
        ip_key: new FormControl('', [Validators.required]),
        device_id: new FormControl('', [Validators.required]),
        tts: new FormControl('', [Validators.required])
    });

    get ip_key(): FormControl {
        return this.ttsFormData.get('ip_key') as FormControl;
    }

    get device_id(): FormControl {
        return this.ttsFormData.get('device_id') as FormControl;
    }

    get tts(): FormControl {
        return this.ttsFormData.get('tts') as FormControl;
    }

    getcURL1 = `curl --location "http://91.107.200.91:6500/create_wav_file" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"device_id\\\":\\\"ctGVOPt26yxU263FFA1BiDZ9Ed1yMzCT\\\",\\\"ip_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"text\\\":\\\"hello. i am from bonrix\\\"}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    getcURLMerchant = `curl --location "http://91.107.200.91:6500/send_tts_merchant" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"ip_key\\\":\\\"0rtuFwE9ExWRtrLcJZFH4ICGIEExYPaB\\\",\\\"merchant_id\\\":\\\"159951\\\",\\\"pos_id\\\":\\\"\\\",\\\"text\\\":\\\"hello. i am from bonrix\\\"}"
  `;

    copyTextMerchantCurl(): void {
        const textToCopy: any =
            document.getElementById('textToCopyMerchant')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    createTTS() {
        //console.log(this.ttsFormData.value);
        try {
            let sendTTSData = {
                ip_key: this.ttsFormData.value.ip_key,
                device_id: this.ttsFormData.value.device_id,
                text: this.ttsFormData.value.tts
            };
            //console.log(sendTTSData);

            this.service.postTTSAPI(sendTTSData).subscribe((data: any) => {
                //console.log(data);

                if (data['status'] == true) {
                    //console.log("heer");
                    this.ngOnInit();
                    this.toast.success(data['message']);
                    this.ttsFormData.reset();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    sendViaPOSorMerchantFormData = new FormGroup({
        api_key: new FormControl('', [Validators.required]),
        merchant_id: new FormControl('', [Validators.required]),
        pos_id: new FormControl('', [Validators.required]),
        ttsMerchant: new FormControl('', [Validators.required])
    });

    get api_key(): FormControl {
        return this.sendViaPOSorMerchantFormData.get('api_key') as FormControl;
    }

    get merchant_id(): FormControl {
        return this.sendViaPOSorMerchantFormData.get(
            'merchant_id'
        ) as FormControl;
    }

    get pos_id(): FormControl {
        return this.sendViaPOSorMerchantFormData.get('pos_id') as FormControl;
    }

    get ttsMerchant(): FormControl {
        return this.sendViaPOSorMerchantFormData.get(
            'ttsMerchant'
        ) as FormControl;
    }

    sendViaPOSorMerchant() {
        try {
            let sendTTSData = {
                ip_key: this.sendViaPOSorMerchantFormData.value.api_key,
                merchant_id:
                    this.sendViaPOSorMerchantFormData.value.merchant_id,
                pos_id: this.sendViaPOSorMerchantFormData.value.pos_id,
                text: this.sendViaPOSorMerchantFormData.value.ttsMerchant
            };
            //console.log(sendTTSData);

            this.service.sendMerchantOrPosIdViaMQTT(sendTTSData).subscribe({
                next: (data: any) => {
                    //console.log(data);

                    //const dataArray = data as any[];

                    if (data.status === true) {
                        this.toast.success(data.message);
                    } else {
                        this.toast.error(data.message);
                    }

                    // dataArray.forEach((item) => {
                    //     //console.log(item);
                    //     if (item.status === true) {
                    //         this.toast.success(item.message);
                    //     } else {
                    //         this.toast.error(item.message);
                    //     }
                    // });
                    this.ngOnInit(); // Consider the necessity of reinitializing component
                },
                error: (error: any) => {
                    //console.log(error);

                    // Handle HTTP error status
                    if (error && error.status) {
                        // You can use a switch case to handle various error statuses
                        switch (error.status) {
                            case 400:
                                this.toast.error(
                                    'Bad Request: ' + error.error.message
                                );
                                break;
                            case 401:
                                this.toast.error(
                                    'Unauthorized: ' + error.error.message
                                );
                                break;
                            case 403:
                                this.toast.error(
                                    'Forbidden: ' + error.error.message
                                );
                                break;
                            case 404:
                                this.toast.error(
                                    'Not Found: ' + error.error.message
                                );
                                break;
                            case 500:
                                this.toast.error(
                                    'Internal Server Error: Please try again later.'
                                );
                                break;
                            default:
                                this.toast.error(error.error.message);
                        }
                    } else {
                        // Handle case where error status is not available
                        this.toast.error(
                            'An unexpected error occurred. Please try again.'
                        );
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    ttsDeviceNameFormData = new FormGroup({
        apiKeyDeviceName: new FormControl('', [Validators.required]),
        device_name: new FormControl('', [Validators.required]),
        ttsDeviceName: new FormControl('', [Validators.required])
    });

    get apiKeyDeviceName(): FormControl {
        return this.ttsDeviceNameFormData.get(
            'apiKeyDeviceName'
        ) as FormControl;
    }

    get device_name(): FormControl {
        return this.ttsDeviceNameFormData.get('device_name') as FormControl;
    }

    get ttsDeviceName(): FormControl {
        return this.ttsDeviceNameFormData.get('ttsDeviceName') as FormControl;
    }

    createTTSDeviceName() {
        //console.log(this.ttsFormData.value);
        try {
            let sendTTSData = {
                api_key: this.ttsDeviceNameFormData.value.apiKeyDeviceName,
                device_name: this.ttsDeviceNameFormData.value.device_name,
                text: this.ttsDeviceNameFormData.value.ttsDeviceName
            };
            //console.log(sendTTSData);

            this.service
                .postcreateWavFileDeviceName(sendTTSData)
                .subscribe((data: any) => {
                    //console.log(data);

                    if (data['status'] == true) {
                        //console.log("heer");
                        this.ngOnInit();
                        this.toast.success(data['message']);
                        this.ttsDeviceNameFormData.reset();
                    } else {
                        this.toast.error(data['message']);
                        this.ngOnInit();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    getcURL2 = `curl --location "http://91.107.200.91:6500/create_wavfile_name" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"device_name\\\":\\\"a1wZjRkad2j\\\",\\\"api_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"text\\\":\\\"hello. i am from bonrix\\\"}"
  `;

    copyText2(): void {
        const textToCopy: any =
            document.getElementById('textToCopy2')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    logout() {
        this.isAuth.logout();
    }
}
