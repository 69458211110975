<!-- <div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://www.bonrix.co.in/"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
       bonrix.co.in</a
    >
    <span>.</span>
</strong>
<span> All rights reserved.</span> -->


<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a href="https://www.bwtech.in/" target="_blank" rel="noopener noreferrer" style="margin: 0">
        bwtech.in</a>
    <span>.</span>
</strong>
<span> All rights reserved.</span>