<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>API Log</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">API Log Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Api Log History</h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="flex justify-between items-center">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;{{ total }}&nbsp;Records
                                </h3>
                            </div>

                            <div class="flex-column text-right">
                                <button type="button" id="searchBtn" (click)="toggleSearchForm()"
                                    class="btn btn-primary mb-2">
                                    Search
                                </button>
                                <div class="search-container flex items-center ml-2">
                                    <label for="searchDevicesInput" class="form-label mt-2 mr-2">Search:</label>
                                    <input type="text" id="searchDevicesInput" placeholder="Search Devices..." (input)="
                                            filterFromTable($event.target.value)
                                        " class="form-control" />
                                </div>
                            </div>
                        </div>

                        <div>
                            <section class="content" *ngIf="displaySearchForm">
                                <!-- Default box -->
                                <form [formGroup]="searchFormData" (ngSubmit)="onSearch()" class="flex">
                                    <div class="container-fluid">
                                        <div class="flex">
                                            <div class="form-group">
                                                <label for="fromDate" style="margin-top: 20px">From Date:</label>
                                                <input type="date" id="fromDate" formControlName="fromDate"
                                                    name="fromDate" class="form-control" style="
                                                        width: 150px;
                                                        font-size: 0.8rem;
                                                        height: 56px;
                                                        margin-top: 8px;
                                                    " />
                                            </div>

                                            <div class="form-group" style="margin-left: 20px">
                                                <label for="toDate" style="margin-top: 20px">To Date:</label>
                                                <input type="date" id="toDate" formControlName="toDate" name="toDate"
                                                    class="form-control" style="
                                                        width: 150px;
                                                        font-size: 0.8rem;
                                                        height: 56px;
                                                        margin-top: 8px;
                                                    " />
                                            </div>
                                            &nbsp; &nbsp;

                                            <div class="form-group" style="flex: 0 0 auto">
                                                <label class="switch" for="applyDate" style="margin-top: 60px">
                                                    <input type="checkbox" id="applyDate" value="" #myCheckbox
                                                        unchecked />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            &nbsp; &nbsp;

                                            <div class="form-group" style="
                                                    flex: 0 0 auto;
                                                    margin-top: 20px;
                                                ">
                                                <label for="ipAddress">IP Address</label>
                                                <input type="text" id="ipAddress" formControlName="ipAddress"
                                                    class="form-control" style="
                                                        width: 150px;
                                                        font-size: 0.8rem;
                                                        height: 56px;
                                                        margin-top: 7px;
                                                    " />
                                            </div>
                                            &nbsp; &nbsp;

                                            <div class="form-group" style="
                                                    flex: 0 0 auto;
                                                    margin-top: 20px;
                                                ">
                                                <label for="deviceId">Device ID/Product Id</label>
                                                <input type="text" id="deviceId" formControlName="deviceId"
                                                    class="form-control" style="
                                                        width: 150px;
                                                        font-size: 0.8rem;
                                                        height: 56px;
                                                        margin-top: 7px;
                                                    " />
                                            </div>
                                            &nbsp; &nbsp;

                                            <div class="mt-3" style="
                                                    flex: 0 0 auto;
                                                    margin-top: 10px;
                                                ">
                                                <label for="editDeviceModel" class="col-form-label">Model Name</label>
                                                <div style="width: 310px">
                                                    <mat-form-field style="width: 100%">
                                                        <mat-label>Select an
                                                            option</mat-label>
                                                        <mat-select [value]="
                                                                selectedModelName
                                                            " (selectionChange)="
                                                                changeModelName(
                                                                    $event.value
                                                                )
                                                            ">
                                                            <mat-option>None</mat-option>
                                                            <mat-option *ngFor="
                                                                    let datas1 of deviceData
                                                                " [value]="
                                                                    datas1[
                                                                        'model_name'
                                                                    ]
                                                                ">{{
                                                                datas1[
                                                                'model_name'
                                                                ]
                                                                }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div class="mt-3" style="
                                                    flex: 0 0 auto;
                                                    margin-top: 10px;
                                                    padding-left: 68px;
                                                ">
                                                <label for="editDeviceModel" class="col-form-label">Test Type</label>
                                                <div style="width: 150px">
                                                    <mat-form-field style="width: 100%">
                                                        <mat-label>Select an
                                                            option</mat-label>
                                                        <mat-select [value]="
                                                                selectedTestType
                                                            " (selectionChange)="
                                                                changeTestType(
                                                                    $event.value
                                                                )
                                                            ">
                                                            <mat-option>None</mat-option>
                                                            <mat-option *ngFor="
                                                                    let datas1 of showTestTypeModel
                                                                " [value]="
                                                                    datas1[
                                                                        'testType'
                                                                    ]
                                                                ">{{
                                                                datas1[
                                                                'testType'
                                                                ]
                                                                }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            &nbsp;

                                            <div class="mt-3" style="
                                                    flex: 0 0 auto;
                                                    margin-top: 10px;
                                                ">
                                                <label for="editDeviceModel" class="col-form-label">Success Fail
                                                    Status</label>
                                                <div style="width: 150px">
                                                    <mat-form-field style="width: 100%">
                                                        <mat-label>Select an
                                                            option</mat-label>
                                                        <mat-select [value]="
                                                                selectedSuccessFailType
                                                            " (selectionChange)="
                                                                changeSuccessFailType(
                                                                    $event.value
                                                                )
                                                            ">
                                                            <mat-option>None</mat-option>
                                                            <mat-option *ngFor="
                                                                    let datas1 of showSuccessFailModel
                                                                " [value]="
                                                                    datas1[
                                                                        'status'
                                                                    ]
                                                                ">{{
                                                                datas1[
                                                                'status'
                                                                ]
                                                                }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex">
                                            <div>
                                                <button type="submit" id="searchButton" (click)="
                                                        changeColor($event)
                                                    " class="btn btn-primary mr-1">
                                                    Submit
                                                </button>

                                                <button type="button" *ngIf="showDownloadButtons" id="downloadPDFButton"
                                                    class="btn btn-secondary mr-1" (click)="onDownloadPDF()">
                                                    Download .PDF
                                                </button>
                                                <button type="button" *ngIf="showDownloadButtons" id="downloadCSVButton"
                                                    class="btn btn-secondary mr-1" (click)="onDownloadCSV()">
                                                    Download .CSV
                                                </button>
                                                <button type="button" *ngIf="showDownloadButtons"
                                                    id="downloadXLSXButton" class="btn btn-secondary mr-1"
                                                    (click)="onDownloadXLSX()">
                                                    Download .XLSX
                                                </button>

                                                <button type="submit" class="ml-10" id="cancelSearch"
                                                    (click)="reloadPage()" class="btn btn-primary">
                                                    Cancel Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!-- /.card -->
                            </section>
                        </div>
                        <!-- <div class="mb-3" style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            ">
                            <div>Current Page: {{ pageIndex + 1 }}</div>
                            <mat-paginator (page)="handlePage($event)" [length]="length" [pageSize]="pageSize"
                                [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                                [showFirstLastButtons]="true">
                            </mat-paginator>
                        </div> -->

                        <table id="example2" class="table table-bordered table-hover mt-2">
                            <thead>
                                <tr>
                                    <th *ngFor="let field of prop">
                                        {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngFor="
                                    let datas of this.displayedLogData;
                                    let i = index
                                ">
                                <tr>
                                    <ng-container *ngFor="let val of prop">
                                        <td>
                                            {{ datas[val] }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->

                    <div style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        ">
                        <div class="ml-3">
                            Current Page: {{ pageIndex + 1 }}
                        </div>
                        <mat-paginator class="mr-3" (page)="handlePage($event)" [length]="length" [pageSize]="pageSize"
                            [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true">
                        </mat-paginator>
                    </div>
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->

<!-- In your HTML -->
<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>
<!-- Your other HTML goes here -->