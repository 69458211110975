import { IsAuthService } from '@/service/is-auth.service';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { MatPaginatorModule } from '@angular/material/paginator';
import { log } from 'console';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatPaginatorModule
  ],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent implements OnInit {

  userName = localStorage.getItem('UserName');
  
  isLoading = false;

  showSummaryProp:any;
  showSummaryData:any;
  showSummaryTotal:any;

  dataCompanyName:any;
  selectedCompanyName:any;

  deviceProp:any;
  devicetotal:any;
  data:any;

  showModelListAllData:any;
  showDevicesProp:any;
  showDeviceModelDataTotal:any;
  showDeviceHeaderData:any;
  showExpandedDataStatus:boolean = false;

  constructor(private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private router: Router,
    private isAuth: IsAuthService,
    private http:HttpClient,
    private renderer: Renderer2){}

  ngOnInit(): void {
    this.summaryReport();
    this.modelList();
  }

  displayedSummaryData:any

  filterFromTable(input: any) {
    //console.log(input);
  
    if (!input) {
      // If there's no input, reset to the original dataset
      this.displayedSummaryData = [...this.showSummaryData];
    } else {
      const res = this.showSummaryData.filter(device => 
        Object.values(device).some(prop => 
          prop !== null && prop !== undefined && prop.toString().toLowerCase().includes(input.toLowerCase())
        )
      );
      this.displayedSummaryData = res;
    }
  }
  
  sortData(sortField: string, sortDirection: string) {
    this.displayedSummaryData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  capitalizeAndReplace(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  }

  summaryReport(){

    this.isLoading = true;
    try {
      this.service.getSummaryReport().subscribe((data:any)=>{
        //console.log(data);

        if (data['status'] == true) {
          //console.log("here");
  
          this.showSummaryProp = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total') {
                this.showSummaryProp.push(property);
              }
              //console.log(this.showLogProp);
              
            }
          }
  
          //this.rearrangeArray();
  
          this.showSummaryData = data['data'];
          this.displayedSummaryData = [...this.showSummaryData];
          this.showSummaryTotal = data['total_entries'];
          this.isLoading = false; // Stop loading after the data is fetched
        } else {
          this.showSummaryData = null;
          this.isLoading = false; // Stop loading after the data is fetched
          this.toast.error('No Data Found');
          
        }
        
      })
    } catch (error) {
      console.log(error);
      
    }
  }

  modelList(){
    try {
      this.service.getModelListAPI().subscribe((data1: any) => {
        //console.log('Model list', data1);
  
        if (data1['status'] == true) {
          //console.log("here");
  
          this.deviceProp = [];
          for (var propertyDevice in data1['data'][0]) {
            if (data1['data'][0].hasOwnProperty(propertyDevice)) {
              //console.log("PROPERTY",property);
              if (propertyDevice.toLowerCase() != 'total') {
                this.deviceProp.push(propertyDevice);
              }
              //console.log("prop",this.deviceProp);
            }
          }
          this.dataCompanyName = data1['data'];
          this.devicetotal = data1['total_models'];
          //console.log("data",this.data);
        } else {
          this.data = null;
          this.toast.error("Data Not Found");
        }
      });
    } catch (error) {
      console.log(error);
      
    }
  }


  closeResult: any;
  modal:any
  modalReference: any;

  selectCompany(content:any){

    this.modalService.open(content, { ariaLabelledBy: 'openCompany' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);


    // this.modalReference = this.modalService.open(open, { ariaLabelledBy: 'openCompany' });
    // this.modalReference.result.then((result:any) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason:any) => {
    //   this.closeResult = `Dismissed `;
    // });
  }

  

  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}

//   rearrangeArray() {

//     const dateIdx = this.prop.indexOf('id');
//     if (dateIdx > -1) {
//       const userNameElement = this.prop.splice(dateIdx, 1)[0];
//       this.prop.unshift(userNameElement);
//     }
// }

  sendCompanyName:any;

  changeCompanyName(comapanyName:any){
    //console.log(comapanyName);
    this.sendCompanyName = comapanyName;
  }

  openselectCompanyData(openCompanyData:any){

    //console.log("here");
    


    this.modalReference = this.modalService.open(openCompanyData, { ariaLabelledBy: 'openCompany' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);

    // this.modalReference = this.modalService.open(openCompanyData, { ariaLabelledBy: 'openCompany' });
    // this.modalReference.result.then((result:any) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason:any) => {
    //   this.closeResult = `Dismissed `;
    // });

  }

  submitAndClose() {
    // perform your actions here, like sending the form data

    //console.log("here submit");
    

    try {
      const sendCompanyNameData = {
        device_model: this.sendCompanyName
      };

      this.service.postSummaryFromDevieModel(sendCompanyNameData).subscribe(
        (data: any) => {
          //console.log("received response", data);
          if (data['status'] == true) {
            this.showExpandedDataStatus = true
            this.showDevicesProp = [];
            this.showDeviceHeaderData = data['headers'];
            this.showModelListAllData = data['data'];
            this.showDeviceModelDataTotal = data['total_count'];
          } else {
            this.modalService.dismissAll();
            this.showExpandedDataStatus = false;
            if (data['total_entries'] === 0) {
              this.toast.info(data['data']);  // Assuming you have a toast service or function set up
            }
          }
        },
        (err: any) => {
          //console.error(err); // Log the entire error object for debugging
          this.modalService.dismissAll();
          this.toast.error("An unexpected error occurred. Please try again later.");
        }
      );
      

    } catch (error) {
      console.error(error);
    }

    this.modalReference.close();
    
    //this.modalService.dismissAll();

  }

  
  
  downloadXLS(): void {
    const table = document.getElementById('example');  // the table ID
    const worksheet = XLSX.utils.table_to_sheet(table);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${this.sendCompanyName}.xlsx`);
  }

  logout() {
    this.isAuth.logout();
  }

}
