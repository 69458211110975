import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-testaudiodevice',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './testaudiodevice.component.html',
    styleUrl: './testaudiodevice.component.scss'
})
export class TestaudiodeviceComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {}

    userName = localStorage.getItem('UserName');

    sendAudioToDeviceForm = new FormGroup({
        device_id: new FormControl('', [Validators.required]),
        apiKey: new FormControl('', [Validators.required]),
        audioFileCode: new FormControl('', [Validators.required])
    });

    get device_id(): FormControl {
        return this.sendAudioToDeviceForm.get('device_id') as FormControl;
    }

    get apiKey(): FormControl {
        return this.sendAudioToDeviceForm.get('apiKey') as FormControl;
    }

    get audioFileCode(): FormControl {
        return this.sendAudioToDeviceForm.get('audioFileCode') as FormControl;
    }

    sendAudioToDevice() {
        let sendToDevice = {
            ip_key: this.sendAudioToDeviceForm.value.apiKey,
            device_id: this.sendAudioToDeviceForm.value.device_id,
            audiofile_code: this.sendAudioToDeviceForm.value.audioFileCode
        };

        //console.log(sendToDevice);

        this.service
            .postSendToDeviceAudio(sendToDevice)
            .subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                } else {
                    this.toast.error(data['message']);
                }
            });
    }

    logout() {
        this.isAuth.logout();
    }

    getcURL1 = `curl --location "http://91.107.200.91:6500/test_audio_device" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"ip_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"device_id\\\":\\\"ctGVOPt26yxU263FFA1BiDZ9Ed1yMzCT\\\",\\\"audiofile_code\\\":\\\"TiBzqctD\\\"}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    sendAudioToDeviceNameForm = new FormGroup({
        device_name: new FormControl('', [Validators.required]),
        apiKeyDeviceName: new FormControl('', [Validators.required]),
        audioFileCodeDeviceName: new FormControl('', [Validators.required])
    });

    get device_name(): FormControl {
        return this.sendAudioToDeviceNameForm.get('device_name') as FormControl;
    }

    get apiKeyDeviceName(): FormControl {
        return this.sendAudioToDeviceNameForm.get(
            'apiKeyDeviceName'
        ) as FormControl;
    }

    get audioFileCodeDeviceName(): FormControl {
        return this.sendAudioToDeviceNameForm.get(
            'audioFileCodeDeviceName'
        ) as FormControl;
    }

    sendAudioToDeviceName() {
        let sendToDevice = {
            api_key: this.sendAudioToDeviceNameForm.value.apiKeyDeviceName,
            device_name: this.sendAudioToDeviceNameForm.value.device_name,
            audiofile_code:
                this.sendAudioToDeviceNameForm.value.audioFileCodeDeviceName
        };

        //console.log(sendToDevice);

        this.service.posttestDeviceName(sendToDevice).subscribe((data: any) => {
            //console.log(data);
            if (data['status'] == true) {
                this.toast.success(data['message']);
            } else {
                this.toast.error(data['message']);
            }
        });
    }

    getcURL2 = `curl --location "http://91.107.200.91:6500/test_audio_deviceName" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"api_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"device_name\\\":\\\"ctGVOPt26yxU263FFA1BiDZ9Ed1yMzCT\\\",\\\"audiofile_code\\\":\\\"TiBzqctD\\\"}"
  `;

    copyText2(): void {
        const textToCopy: any =
            document.getElementById('textToCopy2')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }
}
