import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-languagepackapis',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './languagepackapis.component.html',
    styleUrl: './languagepackapis.component.scss'
})
export class LanguagepackapisComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {}

    userName = localStorage.getItem('UserName');

    lPSendForm = new FormGroup({
        api_key: new FormControl('', [Validators.required]),
        device_name: new FormControl('', [Validators.required])
    });

    get api_key(): FormControl {
        return this.lPSendForm.get('api_key') as FormControl;
    }

    get device_name(): FormControl {
        return this.lPSendForm.get('device_name') as FormControl;
    }

    apiResponse: any;

    sendLPSubmit() {
        //console.log('submit', this.lPSendForm.value);
        //console.log('before', this.apiResponse);

        try {
            let sendData = {
                api_key: this.lPSendForm.value.api_key,
                device_name: this.lPSendForm.value.device_name
            };
            //console.log(sendData);

            this.service.postLPApisActDefLanguage(sendData).subscribe({
                next: (data) => {
                    //console.log(data);
                    this.apiResponse = data; // Store the API response
                    this.lPSendForm.reset();
                    //console.log('after', this.apiResponse);
                },
                error: (err) => {
                    console.log(err);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    copyResponse(): void {
        const textToCopy: any =
            document.getElementById('textToCopyResponse')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('Response Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('Response Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    getcURL = `curl --location "http://91.107.200.91:6500/list_act_def_lang" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"api_key\\\":\\\"mhKmz62VrgfZndvxoIAwiNL2QF7HLmuv\\\",\\\"device_name\\\":\\\"6959108\\\"}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    lPSendForm1 = new FormGroup({
        api_key1: new FormControl('', [Validators.required]),
        device_name1: new FormControl('', [Validators.required])
    });

    get api_key1(): FormControl {
        return this.lPSendForm1.get('api_key1') as FormControl;
    }

    get device_name1(): FormControl {
        return this.lPSendForm1.get('device_name1') as FormControl;
    }
    apiResponese1: any;

    sendLPSubmit1() {
        //console.log('submit', this.lPSendForm.value);
        try {
            let sendData = {
                api_key: this.lPSendForm1.value.api_key1,
                device_name: this.lPSendForm1.value.device_name1
            };
            //console.log('submit 1', sendData);

            this.service.postLPApisActiveLanguage(sendData).subscribe({
                next: (data) => {
                    //console.log(data);
                    this.apiResponese1 = data;
                    this.lPSendForm1.reset();
                },
                error: (err) => {
                    console.log(err);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    copyResponse1(): void {
        const textToCopy: any = document.getElementById(
            'textToCopyResponse1'
        )?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('Response Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('Response Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    getcURL1 = `curl --location "http://91.107.200.91:6500/list_act_def_lang" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"api_key\\\":\\\"mhKmz62VrgfZndvxoIAwiNL2QF7HLmuv\\\",\\\"device_name\\\":\\\"6959108\\\"}"
  `;

    copyText1(): void {
        const textToCopy: any =
            document.getElementById('textToCopy1')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    getcURL2 = `curl --location "http://91.107.200.91:6500/testapi/get_langpack_byname" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"username\\\":\\\"6959108^&MN3041\\\",\\\"password\\\":\\\"gdgrgdtrgdtrgdtrgt\\\",\\\"clientid\\\":\\\"hbfgbhftgtgb\\\",\\\"lang_name\\\":\\\"jp\\\"}"
  `;

    copyText2(): void {
        const textToCopy: any =
            document.getElementById('textToCopy2')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    showResponseOfCurl2 = `{
          "files": [
              {
                  "file_name": "100.wav",
                  "file_size": "112.54 KB"
              },
              {
                  "file_name": "sample.wav",
                  "file_size": "541.68 KB"
              }
          ],
          "langpack_id": 12,
          "status": true,
          "total_size": "654.22 KB"
      }`;

    copyshowResponseOfCurl2(): void {
        const textToCopy: any = document.getElementById(
            'copyshowResponseOfCurl2'
        )?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('Response Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('Response Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    getcURL3 = `curl --location "http://91.107.200.91:6500/testapi/get_langpack_bylang" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"username\\\":\\\"6959108^&MN3041\\\",\\\"password\\\":\\\"gdgrgdtrgdtrgdtrgt\\\",\\\"clientid\\\":\\\"hbfgbhftgtgb\\\",\\\"language\\\":\\\"hindi\\\"}"
  `;

    copyText3(): void {
        const textToCopy: any =
            document.getElementById('textToCopy3')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    showResponseOfcURL3 = `{
          "files": [
              {
                  "file_name": "100.wav",
                  "file_size": "112.54 KB"
              },
              {
                  "file_name": "sample.wav",
                  "file_size": "541.68 KB"
              }
          ],
          "langpack_id": 12,
          "status": true,
          "total_size": "654.22 KB"
      }`;

    copyshowResponseOfcURL3(): void {
        const textToCopy: any = document.getElementById(
            'copyshowResponseOfcURL3'
        )?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('Response Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('Response Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    getcURL4 = `curl --location "http://91.107.200.91:6500/testapi/download_langpack_file" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"username\\\":\\\"6959108^&MN3041\\\",\\\"password\\\":\\\"gdgrgdtrgdtrgdtrgt\\\",\\\"clientid\\\":\\\"hbfgbhftgtgb\\\",\\\"langpack_id\\\":\\\"12\\\",\\\"file_name\\\":\\\"100.wav\\\"}"
  `;

    copyText4(): void {
        const textToCopy: any =
            document.getElementById('textToCopy4')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }
}
