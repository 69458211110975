<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Devices</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Device List Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            Device List
                        </h2>

                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">

                        <div class="flex justify-between items-start mb-3">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;{{ showAddedDeviceTotal }}&nbsp;Records
                                </h3>

                                <div>
                                    <label for="avatar"></label>
                                    <input type="file" (change)="onFileSelected($event)" id="deviceFileUpload"
                                        name="deviceFileUpload" accept=".xlsx, .xls, .csv" #fileInput /> &nbsp;

                                    <button type="button" (click)="clearFile()" [hidden]="!fileSelected"
                                        class="btn btn-primary"><i class="fas fa-trash"></i></button>
                                </div>

                                <div class="mt-2">
                                    <button type="submit" (click)="uploadFile()" class="btn btn-primary">Upload File</button>
                                </div>

                            </div>
                            <div class="flex-column text-right">
                                <button type="button" id="addGroupBtn" (click)="open(content)"
                                    class="btn btn-primary mb-2">
                                    Add Device
                                </button>
                                <div class="search-container flex items-center ml-2">
                                    <label for="searchDevicesInput" class="form-label mt-2 mr-2">Search:</label>
                                    <input type="text" id="searchDevicesInput" placeholder="Search Devices..."
                                        (input)="filterFromTable($event.target.value)" class="form-control">
                                </div>
                                <!-- Add your search input here -->
                                <!-- <input type="text" placeholder="Search Devices..." (input)="filterFromTable($event.target.value)"
                                       class="form-control"> -->

                                <button type="button" id="searchBtn" (click)="toggleSearchForm()"
                                    class="btn btn-primary mb-2 mt-2">
                                    Search
                                </button>

                            </div>




                        </div>


                        <div>
                            <section class="content" *ngIf="displaySearchForm">
                                <!-- Default box -->
                                <form [formGroup]="searchFormData" (ngSubmit)="onSearch()" class="flex">
                                    <div class="container-fluid">
                                        <div class="flex">


                                            <div class="form-group" style="flex: 0 0 auto; margin-top: 20px;">
                                                <label for="searchDeviceId">Device Data</label>
                                                <input type="text" id="searchDeviceId" formControlName="searchDeviceId"
                                                    class="form-control"
                                                    style="width: 200px; font-size: 0.8rem; height: 56px; margin-top: 7px;">
                                            </div>&nbsp; &nbsp;

                                            <div class="form-group" style="flex: 0 0 auto; margin-top: 10px;">
                                                <label for="editDeviceModel" class="col-form-label">Model Name</label>
                                                <div style="width: 310px;">
                                                    <mat-form-field style="width: 100%; margin-top: 10px;">
                                                        <mat-label>Select an option</mat-label>
                                                        <mat-select [value]="selectedModelName"
                                                            (selectionChange)="changeModelName($event.value)">
                                                            <mat-option>None</mat-option>
                                                            <mat-option *ngFor="let datas1 of deviceData"
                                                                [value]="datas1['model_name']">{{ datas1["model_name"]
                                                                }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>&nbsp; &nbsp;&nbsp;


                                            <div class="form-group">
                                                <label for="editEventTypeModel" class="col-form-label"
                                                    style="margin-top: 10px;">Group Name</label>
                                                <div style="width: 150px;">
                                                    <mat-form-field style="width: 100%; margin-top: 10px;">
                                                        <mat-label>Select an option</mat-label>
                                                        <mat-select [value]="selectedGroupName"
                                                            (selectionChange)="changeGroupName($event.value)">
                                                            <mat-option>None</mat-option>
                                                            <mat-option *ngFor="let datas1 of dataGroupName"
                                                                [value]="datas1['group_name']">{{ datas1["group_name"]
                                                                }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>


                                        </div>

                                        <!-- <div class="flex">
                                        </div> -->

                                        <div class="flex">
                                            <div>
                                                <button type="submit" id="searchButton" (click)="
                                                        changeColor($event)
                                                    " class="btn btn-primary mr-1" style="margin-bottom: 20px;">
                                                    Submit
                                                </button>

                                                <button type="button" *ngIf="showDownloadButtons" id="downloadPDFButton"
                                                    class="btn btn-secondary mr-1" style="margin-bottom: 20px;" (click)="onDownloadPDF()">
                                                    Download .PDF
                                                </button>
                                                <button type="button" *ngIf="showDownloadButtons" id="downloadCSVButton"
                                                    class="btn btn-secondary mr-1" style="margin-bottom: 20px;" (click)="onDownloadCSV()">
                                                    Download .CSV
                                                </button>
                                                <button type="button" *ngIf="showDownloadButtons"
                                                    id="downloadXLSXButton" style="margin-bottom: 20px;" class="btn btn-secondary mr-1"
                                                    (click)="onDownloadXLSX()">
                                                    Download .XLSX
                                                </button>

                                                <button type="submit" class="ml-10" id="cancelSearch"
                                                    (click)="reloadPage()" style="margin-bottom: 20px;" class="btn btn-primary">
                                                    Cancel Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!-- /.card -->
                            </section>
                        </div>



                        <!-- <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div class="ml-3">Current Page: {{ pageIndex + 1 }}</div>
                            <mat-paginator class="mr-3" (page)="handlePage($event)" [length]="length"
                                [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                                [showFirstLastButtons]="true">
                            </mat-paginator>
                        </div> -->



                        <table id="example2" class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th *ngFor="
                                            let field of showAddedDeviceProp
                                        ">
                                        {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="
                                    let datas of displayedDeviceData;
                                    let i = index
                                ">
                                <tr>
                                    <ng-container *ngFor="let val of showAddedDeviceProp">
                                        <!-- <td>
                              {{ datas[val] }}
                            </td> -->

                                        <td *ngIf="val.toLowerCase() !== 'device_status' " id="id_{{ i }}" attr.data-value="{{ datas[val] }}">
                                            {{ datas[val] }}
                                        </td>
                                        <td *ngIf="
                                                datas[val] === 1 &&
                                                val.toLowerCase() ===
                                                    'device_status'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateDeviceStatusActive(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" checked />
                                            </label>
                                        </td>

                                        <td *ngIf="
                                                datas[val] !== 1 &&
                                                val.toLowerCase() ===
                                                    'device_status'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateDeviceStatusActive(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" unchecked />
                                            </label>
                                        </td>
                                    </ng-container>

                                    <!-- <td class="px-4 py-2 border text-center">1</td>
                  <td class="px-4 py-2 border text-center">John Doe</td> -->
                                    <td>
                                        <button type="button" class="btn btn-primary mt-1 ml-1"
                                            value="{{ datas['id'] }}" id="editUpdateGroup_{{
                                                datas['id']
                                            }}" (click)="
                                                openEditUpdateDeviceForm(
                                                    editUpdateDeviceForm,
                                                    datas['id'],
                                                    datas['device_name'],
                                                    datas['group_name'],
                                                    datas['product_key'],
                                                    datas['device_model'],
                                                    datas['device_secret'],
                                                    datas['merchant_id'],
                                                    datas['pos_id'],
                                                    datas['language_code']
                                                )
                                            " ng-click="getValue($event)" matTooltip="Edit Device">
                                            <i class="fas fa-edit"></i></button>

                                        <button class="btn btn-primary mt-1 ml-1" value="{{ datas['id'] }}"
                                            id="sendAmount_{{ datas['id'] }}" (click)="
                                                openSendAmount(
                                                    sendAmountToDevice,
                                                    datas['product_key']
                                                )
                                            " ng-click="getValue($event)" matTooltip="Send Amount">
                                            <i class="fas fa-rupee-sign"></i>
                                        </button>


                                        <button class="btn btn-primary mt-1 ml-1" value="{{ datas['id'] }}"
                                            id="sendDeviceId_{{ datas['id'] }}" (click)="
                                                openSendDeviceId(
                                                    sendDeviceId,
                                                    datas['product_key']
                                                )
                                            " ng-click="getValue($event)" matTooltip="Send Command">
                                            <i class="fas fa-desktop"></i>
                                        </button>


                                        <button class="btn btn-primary mt-1 ml-1" value="{{ datas['id'] }}"
                                            id="sendDeviceId_{{ datas['id'] }}" (click)="
                                                deviceHistory(
                                                    datas['product_key']
                                                )
                                            " ng-click="getValue($event)"
                                            matTooltip="Go to payment history of this Device">
                                            <i class="fas fa-history"></i>
                                        </button>


                                        <button type="button" class="btn btn-primary ml-1 mt-1"
                                            value="{{ datas['id'] }}" id="sendAudio_{{ datas['product_key'] }}" (click)="
                                                audioSend1(
                                                    audioSend,
                                                    datas['product_key']
                                                )
                                            " ng-click="getValue($event)" matTooltip="Send audio to device">
                                            <i class="fas fa-volume-up"></i>
                                        </button>

                                        <button class="btn btn-primary ml-1 mt-1" value="{{ datas['id'] }}"
                                            id="deviceInfoId_{{ datas['id'] }}" (click)="
                                                deviceinfo(
                                                    deviceInfo,
                                                    datas['product_key']
                                                )
                                            " ng-click="getValue($event)" matTooltip="More info about the device">
                                            <i class="fas fa-info-circle"></i>
                                        </button>


                                        <button class="btn btn-danger ml-1 mt-1" value="{{ datas['id'] }}"
                                            id="conf_{{ datas['id'] }}" (click)="
                                                confarmation(
                                                    openConfirmation,
                                                    datas['id'],
                                                    datas['product_key']
                                                )
                                            " ng-click="getValue($event)" matTooltip="Delete Device">
                                            <i class="fas fa-trash"></i>
                                        </button>


                                        <button class="btn btn-primary ml-1 mt-1" value=""
                                            id="viewDetails_{{datas['id']}}" (click)="viewDetails(
                                                    deviceDetails,
                                                    datas['id'],
                                                    datas['product_key'],
                                                    datas['device_model'],
                                                    datas['device_name'],
                                                    datas['device_secret'],
                                                    datas['group_name'],
                                                    datas['language_code'],
                                                    datas['merchant_id'],
                                                    datas['pos_id']
                                                )" ng-click="getValue($event)" matTooltip="View Device Details">
                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                        </button>


                                        <button class="btn btn-primary ml-1 mt-1" value=""
                                            id="mqttDetails_{{datas['id']}}" (click)="viewMqttDetails(
                                                    mqttDetails,
                                                    datas['id'],
                                                    datas['product_key']
                                                )" ng-click="getValue($event)" matTooltip="View MQTT Details">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="23" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M10.657 23.994h-9.45A1.21 1.21 0 0 1 0 22.788v-9.18h.071c5.784 0 10.504 4.65 10.586 10.386m7.606 0h-4.045C14.135 16.246 7.795 9.977 0 9.942V6.038h.071c9.983 0 18.121 8.044 18.192 17.956m4.53 0h-.97C21.754 12.071 11.995 2.407 0 2.372v-1.16C0 .55.544.006 1.207.006h7.64C15.733 2.49 21.257 7.789 24 14.508v8.291c0 .663-.544 1.195-1.207 1.195M16.713.006h6.092A1.19 1.19 0 0 1 24 1.2v5.914c-.91-1.242-2.046-2.65-3.158-3.762C19.588 2.11 18.122.987 16.714.005Z" />
                                            </svg>
                                        </button>


                                    </td>
                                </tr>
                            </tbody>
                            <!-- <tfoot>
                <tr>
                  <th>Rendering engine</th>
                  <th>Browser</th>
                  <th>Platform(s)</th>
                  <th>Engine version</th>
                  <th>CSS grade</th>
                </tr>
                </tfoot> -->
                        </table>
                    </div>
                    <!-- /.card-body -->

                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div class="ml-3">Current Page: {{ pageIndex + 1 }}</div>
                        <mat-paginator class="mr-3" (page)="handlePage($event)" [length]="length" [pageSize]="pageSize"
                            [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true">
                        </mat-paginator>
                    </div>


                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->
<!-- <ng-template #mqttDetails let-modal>
    <form method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                MQTT Information
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body" *ngIf="mqttResponseTrue === true">

            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client Status</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <ul id="clientStatus">


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client Id</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientIdMqtt}}</p>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>

        

        </form>
</ng-template> -->


<ng-template #mqttDetails let-modal>
    <form method="post">
        <div class="modal-header">
            <h4 class="modal-title font-medium" id="modal-basic-title">
                Device Connection Information
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>

        <div *ngIf="mqttResponseTrue === true">
            <button class="btn btn-success mt-2 ml-3">Device Connected With MQTT</button>
        </div>

        <div *ngIf="mqttResponseTrue === false">
            <button class="btn btn-danger mt-2 ml-3">Device Not Connected With MQTT</button>
        </div>

        <div class="modal-body scrollable-content" *ngIf="mqttResponseTrue === true">

            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client Status</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <ul id="clientStatus">


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client Id</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.clientid}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Connected</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p id="connected" [ngClass]="{
                                    'status-online': clientStatus.connected === true,
                                    'status-offline': clientStatus.connected === false
                                }">{{clientStatus.connected}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Clean Start</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p id="connected" [ngClass]="{
                                    'status-online': clientStatus.clean_start === true,
                                    'status-offline': clientStatus.clean_start === false
                                }">{{clientStatus.clean_start}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Enable Authentication</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p [ngClass]="{
                                    'status-online': clientStatus.enable_authn === true,
                                    'status-offline': clientStatus.enable_authn === false
                                }">{{clientStatus.enable_authn}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">IP Address</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.ip_address}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Connected At</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.connected_at}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Created At</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.created_at}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Expiry Interval</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.expiry_interval}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Awaiting Rel Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.awaiting_rel_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Awaiting Rel Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.awaiting_rel_max}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Heap Size</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.heap_size}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Inflight Cnt</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.inflight_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Inflight Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.inflight_max}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Is Bridge</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.is_bridge}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Is Persistent</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.is_persistent}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Keep Alive</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.keepalive}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Listener</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.listener}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mailbox Len</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mailbox_len}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mount Point</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{mountPoint || 'null'}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mqueue Dropped</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mqueue_dropped}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mqueue Len</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mqueue_len}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mqueue Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mqueue_max}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Node</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.node}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Port</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.port}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Proto Name</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.proto_name}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Proto Version</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.proto_ver}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_msg}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message Dropped</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgDropped}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message Dropped Await Pubrel Timeout</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgDroppedAwaitPubRelTimeOut}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message Qos 0</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgQos0}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message Qos 1</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgQos1}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message Qos 2</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgQos2}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Oct</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_oct}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Packets</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_pkt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Reductions</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.reductions}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Messages</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_msg}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message Dropped</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDropped}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message Dropped Expired</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDroppedExpired}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message Dropped Queue Full</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDroppedQueueFull}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message Dropped Too Large</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDroppedTooLarge}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message Qos 0</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgQos0}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message Qos 1</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgQos1}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message Qos 2</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgQos2}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Oct</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_oct}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Packets</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_pkt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Subscriptions Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.subscriptions_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Subscriptions Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.subscriptions_max}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Username</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.username}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Zone</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.zone}}</p>
                            </div>
                        </div>

                    </ul>
                </div>
            </div>
            &nbsp;


            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Subscription Status</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <ul id="clientStatus">


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client Id</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{subStatusData.clientid}}</p>
                            </div>
                        </div>

                        

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Node</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p>{{subStatusData.node}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Topic</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p>{{subStatusData.topic}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Nl</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p>{{subStatusData.nl}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Qos</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p>{{subStatusData.qos}}</p>
                            </div>
                        </div>


                        

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Rap</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p>{{subStatusData.rap}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Rh</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                
                                <p>{{subStatusData.rh}}</p>
                            </div>
                        </div>





                    </ul>
                </div>
            </div>

            
        </div>

        <div class="modal-body scrollable-content" *ngIf="mqttResponseTrue === false">
            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-red-700">{{mqttServerNotConnected}}</label>
               
            </div>
            &nbsp;
        </div>

        <div class="modal-footer">
            
        </div>
    </form>
</ng-template>

<!-- device details pop up starts-->

<ng-template #deviceDetails let-modal>
    <form method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Device Data Details
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Id</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="deviceName">{{viewId}}</p>
                </div>
            </div>&nbsp;

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Id</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p>
                        {{ viewDeviceId }}
                    </p>
                </div>
            </div>&nbsp;
            

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Model</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p>
                        {{ viewDeviceModel }}
                    </p>
                </div>
            </div>&nbsp;
            

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p>{{viewDeviceName}}</p>
                </div>
            </div>&nbsp;
            

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Secret</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="serialNumber">{{viewDeviceScret}}</p>
                </div>
            </div>&nbsp;

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Group Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="serialNumber">{{viewGroupName}}</p>
                </div>
            </div>&nbsp;

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Language Code</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="languageCode">{{viewLanguageCode || notAvailable}}</p>
                </div>
            </div>&nbsp;

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">merchant Id</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="serialNumber">{{viewMerchantId || notAvailable}}</p>
                </div>
            </div>&nbsp;

            <div class="mt-3">
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Pos Id</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="serialNumber">{{viewPosId || notAvailable}}</p>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <!-- <button type="submit"
                class="rounded-md bg-blue-500 px-3 py-2 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                (click)="modal.close('Save click')">
                Close
            </button> -->
        </div>
    </form>
</ng-template>

<!-- device details pop up ends -->

<!-- action pop up -->

<ng-template #audioSend let-modal>
    <form [formGroup]="sendTTSToDeviceForm" (ngSubmit)="sendTTSToDevice()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Send text to speech audio to device
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="editDeviceName" class="block text-2xl font-medium leading-6 text-gray-900">Text</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="audioText" id="audioText" formControlName="audioText"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter text to send to device" />
                </div>
            </div>
            &nbsp;
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Send
            </button>
        </div>
    </form>
</ng-template>

<ng-template #deviceInfo let-modal>
    <form method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Device Information
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="deviceName">Smart Sound Box V2</p>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Status</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="deviceStatus" [ngClass]="{
                            'status-online': deviceStatus === 'Online',
                            'status-offline': deviceStatus === 'Offline'
                        }">
                        {{ deviceStatus }}
                    </p>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Battery
                    Status</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="BatteryStatus" [ngClass]="{
                            'status-online': batteryStatus >= 35,
                            'status-offline': batteryStatus <= 43
                        }">
                        {{ batteryStatus }} %
                    </p>
                    <p id="BatteryStatus" *ngIf="batteryStatus <= 34">
                        {{
                        batteryStatus > 34
                        ? batteryStatus + ' %'
                        : 'Please connect charger'
                        }}
                    </p>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device
                    Specifications</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <ul id="deviceSpecs">
                        <li>Connectivity: Wi-Fi, Bluetooth</li>
                        <li>Battery life: 48 hours</li>
                    </ul>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Device Serial
                    Number</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <p id="serialNumber">SN1234567890</p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <!-- <button type="submit"
                class="rounded-md bg-blue-500 px-3 py-2 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                (click)="modal.close('Save click')">
                Close
            </button> -->
        </div>
    </form>
</ng-template>

<ng-template #sendDeviceId let-modal>
    <form [formGroup]="commandForm" (ngSubmit)="sendCommandToDevice()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit Group Name</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="commandName" class="block text-2xl font-medium leading-6 text-gray-900">Command Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select a Command</mat-label>
                        <mat-select (selectionChange)="changeCommandName($event.value)" formControlName="commandName">
                            <mat-option *ngFor="let datas1 of showCommands" value="{{ datas1['command_name'] }}">{{
                                datas1['command_name'] }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <label for="jsonPayload" class="block text-2xl font-medium leading-6 text-gray-900">Command JSON
                    Pay-Load</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <textarea name="jsonPayload" id="jsonPayload" formControlName="jsonPayload"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        cols="30" rows="10" placeholder="Enter JSON Command Details"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Send JSON Command
            </button>
        </div>
    </form>
</ng-template>

<ng-template #sendAmountToDevice let-modal>
    <form [formGroup]="SendAmountForm" (ngSubmit)="sendAmountToDevice1()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Send Amount</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="amount" class="block text-2xl font-medium leading-6 text-gray-900">Enter Amount</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="amount" id="amount" formControlName="amount"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Amount" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Send Amount
            </button>
        </div>
    </form>
</ng-template>

<ng-template #openConfirmation let-modal>
    <form method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Delete Device</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <h2 class="confirm-heading">
                Are You Sure You Want to Delete {{ delDeviceId }} Device?
            </h2>
            <div>
                <button type="button" value="yes" (click)="yes()" class="btn btn-primary">
                    Yes</button>&nbsp;
                <button type="button" value="no" (click)="no()" class="btn btn-danger">
                    No
                </button>
            </div>
        </div>
        <div class="modal-footer">
            <!-- <button
                type="submit"
                class="rounded-md bg-blue-500 px-3 py-2 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                (click)="modal.close('Save click')"
            >
                Close
            </button> -->
        </div>
    </form>
</ng-template>

<ng-template #editUpdateDeviceForm let-modal>
    <form [formGroup]="editDeviceNameForm" (ngSubmit)="editDeviceNameSubmit()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit Device</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="editDeviceName" class="block text-2xl font-medium leading-6 text-gray-900">Device
                    Id/Product Key</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="editDeviceName" id="editDeviceName" formControlName="editDeviceName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Device Id/Product Key" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editDeviceId" class="block text-2xl font-medium leading-6 text-gray-900">Device ID</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="editDeviceId" id="editDeviceId" formControlName="editDeviceId"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Device ID" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editGroupName" class="block text-2xl font-medium leading-6 text-gray-900">Group Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select [value]="editSelectedGroupName" (selectionChange)="changeGroupName($event.value)">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let datas of dataGroupName" value="{{ datas['group_name'] }}">{{
                                datas['group_name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editDeviceModel" class="block text-2xl font-medium leading-6 text-gray-900">Device
                    Model</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select [value]="selectedDeviceModelName"
                            (selectionChange)="chnageDeviceName($event.value)">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let datas1 of deviceData" value="{{ datas1['model_name'] }}">{{
                                datas1['model_name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editDeviceSecret" class="block text-2xl font-medium leading-6 text-gray-900">Device
                    Secret</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="editDeviceSecret" id="editDeviceSecret" formControlName="editDeviceSecret"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Device Secret" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editMerchantId" class="block text-2xl font-medium leading-6 text-gray-900">Merchant
                    ID</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="editMerchantId" id="editMerchantId" formControlName="editMerchantId"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Merchant ID" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editPosId" class="block text-2xl font-medium leading-6 text-gray-900">POS ID</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="editPosId" id="editPosId" formControlName="editPosId"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter POS ID" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="editLanguage" class="block text-2xl font-medium leading-6 text-gray-900">Change
                    Language</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select a language</mat-label>
                        <mat-select formControlName="editLanguage">
                            <mat-option value="en">English</mat-option>
                            <mat-option value="hi">Hindi</mat-option>
                            <mat-option value="gu">Gujarati</mat-option>
                            <mat-option value="mr">Marathi</mat-option>
                            <mat-option value="kn">Kannada</mat-option>
                            <mat-option value="ml">Malayalam</mat-option>
                            <mat-option value="pa">Punjabi</mat-option>
                            <mat-option value="ta">Tamil</mat-option>
                            <mat-option value="te">Telugu</mat-option>
                            <mat-option value="bn">Bengali</mat-option>
                            <!-- Add more language options as needed -->
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp;
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Update
            </button>
        </div>
    </form>
</ng-template>

<ng-template #content let-modal>
    <form [formGroup]="addDeviceNameForm" (ngSubmit)="addDeviceInGroupName()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Device</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="deviceName" class="block text-2xl font-medium leading-6 text-gray-900">Device Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="deviceName" id="deviceName" formControlName="deviceName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Device Name" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="deviceId" class="block text-2xl font-medium leading-6 text-gray-900">Device ID/Product Key</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="deviceId" id="deviceId" formControlName="deviceId"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Device Id/Product key" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="deviceId" class="block text-2xl font-medium leading-6 text-gray-900">Group Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select [value]="selectedGroupName" (selectionChange)="changeGroupName($event.value)">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let datas of dataGroupName" value="{{ datas['group_name'] }}">{{
                                datas['group_name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="deviceId" class="block text-2xl font-medium leading-6 text-gray-900">Device Model</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select [value]="selectedDeviceModelName"
                            (selectionChange)="chnageDeviceName($event.value)">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let datas1 of deviceData" value="{{ datas1['model_name'] }}">{{
                                datas1['model_name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp;

            <div>
                <label for="deviceSecret" class="block text-2xl font-medium leading-6 text-gray-900">Device
                    Secret</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="deviceSecret" id="deviceSecret" formControlName="deviceSecret"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Device Secret" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="merchantId" class="block text-2xl font-medium leading-6 text-gray-900">Merchant Id</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="merchantId" id="merchantId" formControlName="merchantId"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Merchant ID" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="posId" class="block text-2xl font-medium leading-6 text-gray-900">POS Id</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="posId" id="posId" formControlName="posId"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter POS ID" />
                </div>
            </div>
            &nbsp;

            <div>
                <label for="deviceId" class="block text-2xl font-medium leading-6 text-gray-900">Select Language</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select formControlName="language">
                            <mat-option value="en">English</mat-option>
                            <mat-option value="hi">Hindi</mat-option>
                            <mat-option value="gu">Gujarati</mat-option>
                            <mat-option value="mr">Marathi</mat-option>
                            <mat-option value="kn">Kannada</mat-option>
                            <mat-option value="ml">Malayalam</mat-option>
                            <mat-option value="pa">Punjabi</mat-option>
                            <mat-option value="ta">Tamil</mat-option>
                            <mat-option value="te">Telugu</mat-option>
                            <mat-option value="bn">Bengali</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp;
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Save
            </button>
        </div>
    </form>
</ng-template>

<!-- In your HTML -->
<!-- <div *ngIf="isLoading" class="custom-loader"></div> -->
<!-- Your other HTML goes here -->

<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>

<script>
    $(function () {
        $("#example2").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            // "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
        }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        $('#example2').DataTable({
            "paging": true,
            "lengthChange": false,
            "searching": false,
            "ordering": true,
            "info": true,
            "autoWidth": false,
            "responsive": true,
        });
    });
</script>