import { IsAuthService } from '@/service/is-auth.service';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { interval, map, startWith } from 'rxjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-testdevice',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatPaginatorModule
  ],
  templateUrl: './testdevice.component.html',
  styleUrl: './testdevice.component.scss'
})
export class TestdeviceComponent implements OnInit {

  private headers: HttpHeaders;
  private requestOptions:any;

  constructor(
    private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private router: Router,
    private isAuth: IsAuthService,
    private http:HttpClient,
    private renderer: Renderer2
  ){
    let auth_token = localStorage.getItem('Token');
    
    this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token': `${auth_token}`
      });
      //console.log("token",this.headers);
      
    this.requestOptions = { headers: this.headers };
  }

  ngOnInit(): void {
    this.setMqttSetting();
  }


  setMqttSetting(){
    //console.log(this.method);

    const mqttSetting:any = {
      "domain":"nodered-mqtt.bonrix.in",
      "port":"21883",
      "user":"nxon",
      "password":"nxon1234",
      "topic":"test1"
    }
    this.testAPIForm.controls.mqttSettingJson.setValue(JSON.stringify(mqttSetting));

    //set global key
    // const globalKey = '876d78s2601j653fg54'
    // this.testAPIForm.controls.globalUserKey.setValue(globalKey)

    // for to show Device date and time
    interval(1000)
      .pipe(map(() => {
        let date = new Date();
        return date.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      }))
      .subscribe(date => {
        this.testAPIForm.controls.deviceDateTime.setValue(date, { emitEvent: false });
      });

    // for system date time show

    interval(1000)
      .pipe(map(() => {
        let date = new Date();
        return date.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      }))
      .subscribe(time => {
        this.testAPIForm.controls.systemDateTime.setValue(time, { emitEvent: false });
      });
  }

  method: string = 'POST';

  changeRequestMethod(newMethod:any){
    //console.log(newMethod);
    this.method = newMethod;
  }

  userName = localStorage.getItem('UserName');


  testAPIForm = new FormGroup({
    globalUserKey : new FormControl('',[Validators.required]),
    deviceID : new FormControl('',[Validators.required]),
    mqttSettingJson : new FormControl('',[Validators.required]),
    deviceDateTime : new FormControl('',[Validators.required]),
    systemDateTime : new FormControl('',[Validators.required]),
    wifiSSID : new FormControl('',[Validators.required]),
    wifiPassword : new FormControl('',[Validators.required]),
    localIPAddress : new FormControl('',[Validators.required]),
    globalIPAddress : new FormControl('',[Validators.required]),
    remark : new FormControl('',[Validators.required]),
  })

  get globalUserKey():FormControl {
    return this.testAPIForm.get('globalUserKey') as FormControl;
  }
  
  get deviceID():FormControl {
    return this.testAPIForm.get('deviceID') as FormControl;
  }

  get mqttSettingJson():FormControl {
    return this.testAPIForm.get('mqttSettingJson') as FormControl;
  }

  get deviceDateTime():FormControl {
    return this.testAPIForm.get('deviceDateTime') as FormControl;
  }

  get systemDateTime():FormControl {
    return this.testAPIForm.get('systemDateTime') as FormControl;
  }

  get wifiSSID():FormControl {
    return this.testAPIForm.get('wifiSSID') as FormControl;
  }

  get wifiPassword():FormControl {
    return this.testAPIForm.get('wifiPassword') as FormControl;
  }

  get localIPAddress():FormControl {
    return this.testAPIForm.get('localIPAddress') as FormControl;
  }

  get globalIPAddress():FormControl {
    return this.testAPIForm.get('globalIPAddress') as FormControl;
  }

  get remark():FormControl {
    return this.testAPIForm.get('remark') as FormControl;
  }

  deviceDateandTime = new Date();

  onSubmit(){
    //console.log("Submit",this.deviceDateandTime);
    let unixdeviceDate = Math.floor(this.deviceDateandTime.getTime()/1000);
    //console.log(unixdeviceDate);
    
    try {
      let sendData = {
        "event": this.selectedPowerOnOff,
        "device_model": this.selectedDeviceModelName,
        "global_user_key":this.testAPIForm.value.globalUserKey,
        "device_id": this.testAPIForm.value.deviceID,
        "mqtt_setting_json": this.testAPIForm.value.mqttSettingJson,
        "device_date_time":unixdeviceDate,
        "wifi_SSID":this.testAPIForm.value.wifiSSID,
        "wifi_password":this.testAPIForm.value.wifiPassword,
        "local_ip_address":this.testAPIForm.value.localIPAddress,
        "remarks":this.testAPIForm.value.remark
      }
      //console.log(sendData);

      if(this.method === 'GET'){
        //console.log("Get Method");
        const url = `http://91.107.200.91:6501/admin/add_terminallog_get?global_user_key=${sendData['global_user_key']}&device_id=${sendData['device_id']}&device_model=${sendData['device_model']}&event=${sendData['event']}&device_date_time=${sendData['device_date_time']}&mqtt_setting_json=${sendData['mqtt_setting_json']}&local_ip_address=${sendData['local_ip_address']}&remarks=${sendData['remarks']}&wifi_SSID=${sendData['wifi_SSID']}&wifi_password=${sendData['wifi_password']}`

        //console.log(url);
        

        try {
          this.http.get(url,{headers:this.headers}).subscribe((data:any)=>{
            //console.log(data);
            if(data['status'] == true){
              this.toast.success(data['message']);
              this.testAPIForm.reset();
              this.selectedPowerOnOff = null; // reset the selected power option
              this.selectedDeviceModelName = null; // reset the selected device model
              this.method = 'POST'; // reset the method to POST
            }else{
              this.toast.error(data['message'])
            }
            
          })
        } catch (error) {
          console.log(error);
          
        }
        
      }else{
        //console.log("In Else For Post Method",sendData);
        
        this.service.postAddTerminalLog(sendData).subscribe((data:any)=>{
          //console.log(data);
          if(data['status'] == true){
            this.toast.success(data['message']);
            this.testAPIForm.reset();
            this.selectedPowerOnOff = null; // reset the selected power option
            this.selectedDeviceModelName = null; // reset the selected device model
            this.method = 'POST'; // reset the method to POST
          }else{
            this.toast.error(data['message'])
          }
        })
      }

    } catch (error) {
      console.log(error);
      
    }
  }

  power = {
    "data":[{id:1,power:"POWER ON"},{id:2,power:"POWER OFF"},{id:3,power:"HEART BEAT"}]
  }
  selectedPowerOnOff:any;
  showPowerOnOff:any = this.power['data'];
  chnagePowerOnOffDevice(value:any){
    //console.log(value);
    this.selectedPowerOnOff = value
  }

  deviceModel = {
    "data":[{id:1,model:"ABCD"},{id:2,model:"ABCDEFGH"}]
  }
  selectedDeviceModelName:any;
  showDeviceModel:any ;
  chnageDeviceName(value:any){
    //console.log(value);
    this.selectedDeviceModelName = value
  }

  getJson = "curl --location --globoff 'http://91.107.200.91:6501/admin/add_terminallog_get?global_user_key=0rtuFwE9ExWRtrLcJZFH4ICGIEExYPaB&device_id=tNW9LE7WXsbNVbAYrX04DnYM48zD913w&device_"+"\r\n"+"model=BEI-CFD-001-WIFI&event=HEART%20BEAT&device_date_time=1688528016&mqtt_setting_json={%22domain%22%3A%22nodered-mqtt.bonrix."+"\r\n"+"in%22%2C%22port%22%3A%2221883%22%2C%22user%22%3A%22nxon%22%2C%22password%22%3A%22nxon1234%22%"+"\r\n"+"2C%22topic%22%3A%22test1%22}&local_ip_address=96.32.2252.21&remarks=testing&wifi_SSID=easo-jio&wifi_password=2343ewde' \\ \r\n --header 'token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6MSwiZXhwIjoxNjg4NTY0OTQ0fQ.BqIusU1EO5u-9jhYThpukBpqlpGmhawnX4Tv139w_sc'";

  copyToClipboard(elementId: string): void {
    let element:any = document.getElementById('copyable-content'); // get the element
    let elementText = element.textContent; // get the text content from the element
    navigator.clipboard.writeText(elementText) // copy the text content to clipboard
      .then(() => {
        // Success feedback here, e.g. show a success message
        //console.log('Copied to clipboard successfully!');
        this.toast.success("cURL Copied Successfully")
      })
      .catch(() => {
        // Error feedback here, e.g. show an error message
        //console.log('Failed to copy to clipboard.');
        this.toast.error("cURL Not Copied...Try Again")
      });
  }
  

  logout() {
    this.isAuth.logout();
  }

}
