import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {
    Component,
    NgZone,
    OnInit,
    ChangeDetectorRef,
    TemplateRef
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-zipfiles',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule,
        FormsModule,
        MatPaginatorModule
    ],
    templateUrl: './zipfiles.component.html',
    styleUrls: ['./zipfiles.component.scss']
})
export class ZipfilesComponent implements OnInit {
    viewZipFileProps: any;
    viewZipFileData: any;
    viewZipFileDisplayedData: any;
    viewZipFileTotal: any;
    viewZipFileFolderPath: any;
    viewZiptotalFileSize: any;
    isLoading = true;

    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        const deviceId = this.isAuth.getDeviceId();
        //console.log(deviceId);

        this.viewUploadedFiles();
    }

    filterFromTable(input: any) {
        if (!input) {
            this.viewZipFileDisplayedData = [...this.viewZipFileData];
        } else {
            const res = this.viewZipFileData.filter((device) =>
                Object.values(device).some(
                    (prop) =>
                        prop !== null &&
                        prop !== undefined &&
                        prop
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                )
            );
            this.viewZipFileDisplayedData = res;
        }
    }

    sortData(sortField: string, sortDirection: string) {
        this.viewZipFileDisplayedData.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortField] > b[sortField]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    capitalizeAndReplace(str: string): string {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    }

    roundFigOfFileSize: any;

    viewUploadedFiles() {
        const deviceId = this.isAuth.getDeviceId();
        const langPackId = {
            langpack_id: deviceId
        };

        this.service.viewZipFiles(langPackId).subscribe({
            next: (data: any) => {
                // console.log('Response from viewZipFiles:', data['files']);
                if (data.status === true) {
                    this.viewZipFileProps = Object.keys(data.files[0]).filter(
                        (prop) => prop.toLowerCase() !== 'folder_path'
                    );

                    this.viewZipFileData = data.files;
                    this.viewZipFileFolderPath =
                        data['files'][0]['folder_path'];
                    this.viewZipFileDisplayedData = [...this.viewZipFileData];
                    this.viewZipFileTotal =
                        this.viewZipFileDisplayedData.length;

                    // Calculate the total file size
                    this.viewZiptotalFileSize =
                        this.viewZipFileDisplayedData.reduce((acc, file) => {
                            const fileSize = parseFloat(
                                file.file_size.replace('kb', '').trim()
                            );
                            return acc + fileSize;
                        }, 0);

                    this.roundFigOfFileSize = Math.round(
                        this.viewZiptotalFileSize
                    );

                    // console.log(
                    //     'Total File Size (KB):',
                    //     this.viewZiptotalFileSize
                    // );
                } else {
                    this.router.navigate(['/languagePack']);
                    this.viewZipFileData = null;
                    this.toast.error(data['message']);
                }
                this.isLoading = false;
            },
            error: (err) => {
                console.error('Error in viewZipFiles:', err);
                this.isLoading = false;
            }
        });
    }

    //delete single language pack

    deleteSingleFiles(fileName: any) {
        try {
            let sendDelData = {
                folder_path: this.viewZipFileFolderPath,
                file_name: fileName
            };

            this.service
                .deleteSingleFiles(sendDelData)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] == true) {
                        //console.log("heer");
                        this.toast.success(data['message']);
                        this.ngOnInit();
                    } else {
                        this.toast.error(data['message']);
                        this.ngOnInit();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    // download single files

    downloadSingleFiles(fileName: any) {
        try {
            const sendData = {
                folder_path: this.viewZipFileFolderPath,
                file_name: fileName
            };

            this.service.downloadSingleFiles(sendData).subscribe({
                next: (response) => {
                    const contentDisposition = response.headers.get(
                        'content-disposition'
                    );
                    const fileNameMatch = contentDisposition
                        ? contentDisposition.match(/filename="?([^"]*)"?;?/)
                        : null;
                    const actualFileName =
                        fileNameMatch && fileNameMatch.length > 1
                            ? fileNameMatch[1]
                            : fileName;

                    const blob = new Blob([response.body], {
                        type: response.headers.get('content-type')
                    });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = actualFileName; // Use the actual file name from the response
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                },
                error: (err) => {
                    console.error('Error in downloadSingleFiles:', err);
                }
            });
        } catch (error) {
            console.error('Error in downloadSingleFiles:', error);
        }
    }

    // play audio file

    playAudioFile(fileName: any, rowIndex: number) {
        //console.log(fileName);

        try {
            const sendData = {
                folder_path: this.viewZipFileFolderPath,
                file_name: fileName
            };

            this.service.playAudioFile(sendData).subscribe({
                next: (response: any) => {
                    //console.log(response);

                    const audioContainerId = `audioContainer_${rowIndex}`;
                    const audioContainer =
                        document.getElementById(audioContainerId);

                    if (audioContainer) {
                        // Clear any existing audio elements
                        audioContainer.innerHTML = '';

                        // Create a new Blob object from the response data
                        const blob = new Blob([response.body], {
                            type: response.headers.get('content-type')
                        });

                        // Create a new audio element
                        const audioElement = document.createElement('audio');
                        audioElement.src = window.URL.createObjectURL(blob);
                        audioElement.controls = true;
                        audioElement.autoplay = true;

                        // Append the audio element to the container
                        audioContainer.appendChild(audioElement);
                    } else {
                        console.error('Audio container not found');
                    }
                },
                error: (err) => {
                    console.error('Error in playAudioFile:', err);
                }
            });
        } catch (error) {
            console.error('Error in playAudioFile:', error);
        }
    }

    // add single file

    triggerFileInput(): void {
        const fileInput = document.getElementById(
            `fileInput`
        ) as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    }

    onFileSelected(event: any): void {
        const file: File = event.target.files[0];
        if (file) {
            this.uploadFile(file);
        }
    }

    uploadFile(file: File): void {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('folder_path', this.viewZipFileFolderPath);

        // Your service method to upload the file
        this.service.uploadSingleFile(formData).subscribe({
            next: (data) => {
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.viewUploadedFiles();
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                }
            },
            error: (err) => {
                this.toast.error(err['message']);
                console.error('File upload failed:', err);
            }
        });
    }
}
