import { IsAuthService } from '@/service/is-auth.service';
import {
    Component,
    OnInit,
    Renderer2,
    OnDestroy,
    HostBinding
} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'register-box';

    //public registerForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private service:ApiService,
        private toast:ToastrService,
        private router: Router,
        private isAuth: IsAuthService
    ) {}

    ngOnInit() {

        if(this.isAuth.isAuthenticated()){
            this.router.navigate(['/']);
          }

        this.renderer.addClass(
            document.querySelector('app-root'),
            'register-page'
        );
        // this.registerForm = new UntypedFormGroup({
        //     email: new UntypedFormControl(null, Validators.required),
        //     password: new UntypedFormControl(null, [Validators.required]),
        //     retypePassword: new UntypedFormControl(null, [Validators.required])
        // });
    }


    registerForm = new FormGroup({
        name: new FormControl('', [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(30),
        ]),
        password: new FormControl('', [Validators.required]),
        contactno: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        companyname: new FormControl('', [Validators.required]),
        retypePassword:new FormControl('', [Validators.required]),
      });
    
      get name(): FormControl {
        return this.registerForm.get('name') as FormControl;
      }
    
      get password(): FormControl {
        return this.registerForm.get('password') as FormControl;
      }
    
      get contactno(): FormControl {
        return this.registerForm.get('contactno') as FormControl;
      }
    
      get email(): FormControl {
        return this.registerForm.get('email') as FormControl;
      }
    
      get companyname(): FormControl {
        return this.registerForm.get('companyname') as FormControl;
      }
    
      registerUser() {
        //let registerFormData = this.registerForm.value;
        let registerFormDataSend = {
          name: this.registerForm.value.name,
          password: this.registerForm.value.password,
          contactno: this.registerForm.value.contactno,
          email: this.registerForm.value.email,
          companyname: this.registerForm.value.companyname,
        };
        //console.log(this.registerForm.value);
    
        // if(this.registerForm.valid){
        //   this.service.postRegisterAPI(registerFormData).subscribe((data: any) => {
        //     console.log(data);
        //   });
        // }else{
        //   this.toast.warning('Please Enter Valid Data')
        // }

        if(this.registerForm.value.name == ''){
          //console.log("Name is Blank");
          this.toast.error("Name can not be empty...Please write your name.");
        }else if(this.registerForm.value.contactno == ''){
          this.toast.error("Contact No can not be empty...Please write your contact no.");
        }else if(this.registerForm.value.companyname == ''){
          this.toast.error("Company Name can not be empty...Please write your Company name.");
        }else if(this.registerForm.value.email == ''){
          this.toast.error("E-Mail can not be empty...Please write your E-Mail Address.");
        }else if(this.registerForm.value.password == ''){
          this.toast.error("Password can not be empty...Enter Password");
        }else if(this.registerForm.value.retypePassword == ''){
          this.toast.error("ReType Password can not be empty...Enter ReType Password");
        }else{
          if(this.registerForm.value.password === this.registerForm.value.retypePassword){
            //console.log("Same password");
            this.service.postRegisterAPI(registerFormDataSend).subscribe((data:any) => {
              //console.log(data['Message']);
              this.toast.success(data['Message']);
              this.router.navigateByUrl("/login");
            });
            
          }else{
              //console.log("Not same");
              this.toast.warning("Password And Re-Type Password Must Be Same")
          }
        }

        
        
        
    
        // this.service.postRegisterAPI(registerFormDataSend).subscribe((data:any) => {
        //   //console.log(data['Message']);
        //   this.toast.success(data['Message']);
        //   this.router.navigateByUrl("/login");
        // });
      }

    // async registerByAuth() {
    //     if (this.registerForm.valid) {
    //         this.isAuthLoading = true;
    //         await this.appService.registerByAuth(this.registerForm.value);
    //         this.isAuthLoading = false;
    //     } else {
    //         this.toastr.error('Form is not valid!');
    //     }
    // }

    async registerByGoogle() {
        this.isGoogleLoading = true;
        await this.appService.registerByGoogle();
        this.isGoogleLoading = false;
    }

    async registerByFacebook() {
        this.isFacebookLoading = true;
        await this.appService.registerByFacebook();
        this.isFacebookLoading = false;
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }
}
