import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public email;
    public sidebarPicture;
    public menu = MENU;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        //this.user = "admin@example.com";
        this.user = localStorage.getItem('UserName');
        // this.email = "admin@example.com";
        //this.sidebarPicture = sadsa
        //console.log(this.user);
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/']
    },
    // {
    //     name: 'Blank',
    //     iconClasses: 'fas fa-file',
    //     path: ['/blank']
    // },

    // {
    //     name: 'Language Pack',
    //     iconClasses: 'fa fa-language',
    //     path: ['/languagePack']
    // },

    {
        name: 'Group List',
        iconClasses: 'fa fa-users',
        path: ['/groupList']
    },
    {
        name: 'Device List',
        iconClasses: 'fa fa-mobile',
        path: ['/addDevice']
    },

    {
        name: 'Developer Api',
        iconClasses: 'fa fa-envelope-open',
        path: ['/developerApi']
    },

    {
        name: 'Payment History',
        iconClasses: 'fa fa-credit-card',
        path: ['/paymentHistory']
    },

    {
        name: 'Test API',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Test API',
                iconClasses: 'fa fa-code',
                path: ['/developerDocument']
            },
            {
                name: 'Text To Speech',
                iconClasses: 'fa fa-comment',
                path: ['/textToSpeech']
            },
            {
                name: 'Text To Speech (Group)',
                iconClasses: 'fa fa-comment',
                path: ['/textToSpeechGroup']
            },
            {
                name: 'Test Audio (Device)',
                iconClasses: 'fa fa-headphones',
                path: ['/testAudioDevice']
            },
            {
                name: 'Test Audio (Group)',
                iconClasses: 'fa fa-headphones',
                path: ['/testAudioGroup']
            },
            {
                name: 'Test To Send Amount',
                iconClasses: 'fa fa-credit-card',
                path: ['/testToSendAmount']
            },
            {
                name: 'Printer Test',
                iconClasses: 'fa fa-print',
                path: ['/printerTest']
            },
            {
                name: 'Language Pack APIs',
                iconClasses: 'fa fa-terminal',
                path: ['/languagePackAPI']
            }
        ]
    },
    {
        name: 'Provisioning By Bank',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Activate Merchant',
                iconClasses: 'fa fa-university',
                path: ['/merchantActivate']
            },
            {
                name: 'Enable/Disable Merchant',
                iconClasses: 'fa fa-university',
                path: ['/enableMerchantOrPos']
            },
            {
                name: 'Enable/Disable Device',
                iconClasses: 'fa fa-university',
                path: ['/enableDisableByDevice']
            }
        ]
    },

    {
        name: 'API Log',
        iconClasses: 'fa fa-chevron-right',
        path: ['/log']
    },
    {
        name: 'Terminal Management',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Device Event Log',
                iconClasses: 'fa fa-terminal',
                path: ['/terminalLog']
            },
            {
                name: 'Test Device',
                iconClasses: 'fa fa-mobile',
                path: ['/testDevice']
            },
            // {
            //     name: 'Online Dashboard',
            //     iconClasses: 'fas fa-tachometer-alt',
            //     path: ['/onlineDashboard']
            // },
            {
                name: 'Summary',
                iconClasses: 'fa fa-list-ul',
                path: ['/summary']
            }
        ]
    },

    {
        name: 'Audio Collection',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Text To Speech Audio Collection',
                iconClasses: 'fa fa-headphones',
                path: ['/ttsAudioCollection']
            },
            {
                name: 'Upload Audio',
                iconClasses: 'fa fa-headphones',
                path: ['/uploadAudio']
            }
        ]
    },
    {
        name: 'Language Pack',
        iconClasses: 'fa fa-language',
        path: ['/languagePack']
    },
    {
        name: 'Staff Account',
        iconClasses: 'fa fa-user-circle',
        path: ['/staff']
    }

    // {
    //     name: 'Payment History',
    //     iconClasses: 'fas fa-tachometer-alt',
    //     path: ['/paymentHistory']
    // },

    // {
    //     name: 'Main Menu',
    //     iconClasses: 'fas fa-folder',
    //     children: [
    //         {
    //             name: 'Sub Menu',
    //             iconClasses: 'far fa-address-book',
    //             path: ['/sub-menu-1']
    //         },
    //         {
    //             name: 'Blank',
    //             iconClasses: 'fas fa-file',
    //             path: ['/sub-menu-2']
    //         }
    //     ]
    // }
];
