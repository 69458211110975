<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Test Printer</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Test Printer</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="printerTestForm" (ngSubmit)="sendData()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Test Api To Print Message Via MQQT</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="apiKey" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="apiKey" id="apiKey" formControlName="apiKey"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                                <div class="form-text text-danger" *ngIf="apiKey.errors?.['required'] && (apiKey.touched || apiKey.dirty)">
                                    Api Key is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="deviceId" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device Id/Product Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="deviceId" id="deviceId" formControlName="deviceId"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Id/Product Key" />
                                <div class="form-text text-danger" *ngIf="deviceId.errors?.['required'] && (deviceId.touched || deviceId.dirty)">
                                    Device Id/Product Key is required
                                </div>
                        </div>
                    </div>

                    
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="deviceId" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Text or JSON</label>
                        </div>
                        <div class="w-2/3">
                            <textarea placeholder="Enter Text Or JSON" id="jsonPayLoad" formControlName="jsonPayLoad" name="jsonPayLoad" cols="30" rows="10" class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                        </div>
                    </div>



                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="noOfPrints" class="ml-10 block text-lg font-medium leading-6 text-gray-900">No. of Prints</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="noOfPrints" id="noOfPrints" formControlName="noOfPrints"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Number Of Prints" />
                                <div class="form-text text-danger" *ngIf="noOfPrints.errors?.['required'] && (noOfPrints.touched || noOfPrints.dirty)">
                                    Amount is required
                                </div>
                                <div class="form-text text-danger" *ngIf="noOfPrints.errors?.['pattern'] && (noOfPrints.touched || noOfPrints.dirty)">
                                    Only numbers are allowed and up to four numbers are allowed after the dot.
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="btn btn-primary">
                                Send
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->




<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                
                <button class="btn btn-primary" (click)="copyText()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL1}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
    
</section>
<!-- /.content -->