<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Online Dashboard</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Online Dashboard Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title text-2xl">Device Event Log Information</h3>
            </div>
            <div class="card-body">
                <div class="flex justify-between items-center">
                    <div>
                        <h1 class="text-2xl">
                            Total:&nbsp;{{ totalDashboard }}&nbsp;Dashboard
                        </h1>
                    </div>
                    
                </div>

                <div>
                    <section class="content">
                        <div class="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6 md:gap-8 lg:gap-10">
                            <!-- Card 1 -->                            

                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">5 min</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice5Min}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button id="activeClicked5MinCount" (click)="min5()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active5MinCount}}
                                </div>
                                <!-- DeActive part -->
                                <div class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive5MinCount}}
                                </div>
                            </div>
                            

                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">10 min</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice10Min}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button (click)="min10()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active10MinCount}}
                                </div>
                                <!-- DeActive part -->
                                <div class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive10MinCount}}
                                </div>
                            </div>


                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">15 min</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice15Min}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button (click)="min15()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active15MinCount}}
                                </div>
                                <!-- DeActive part -->
                                <div class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive15MinCount}}
                                </div>
                            </div>


                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">30 min</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice30Min}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button (click)="min30()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active30MinCount}}
                                </div>
                                <!-- DeActive part -->
                                <div class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive30MinCount}}
                                </div>
                            </div>


                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">1 Hour</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice1Hour}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button (click)="hour1()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active1HourCount}}
                                </div>
                                <!-- DeActive part -->
                                <div class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive1HourCount}}
                                </div>
                            </div>


                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">6 Hour</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice6Hour}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button (click)="hour6()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active6HourCount}}
                                </div>
                                <!-- DeActive part -->
                                <div class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive6HourCount}}
                                </div>
                            </div>


                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">12 Hour</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice12Hour}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button (click)="hour12()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active12HourCount}}
                                </div>
                                <!-- DeActive part -->
                                <div class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive12HourCount}}
                                </div>
                            </div>


                            <div class="bg-blue-700 rounded-lg shadow-lg p-24 relative" style="width: 300px; height: 200px;">
                                <!-- Text "5 min" (top-left corner) -->
                                <p class="absolute top-0 left-0 mt-2 ml-2 text-lg text-white">24 Hour</p>
                                <!-- Text "Total Device" (below "5 min") -->
                                <p class="absolute left-0 ml-2 text-white text-lg" style="top: 60px;">Total Devices :   {{totalDevice24Hour}}</p>
                                <!-- Refresh Button (top-right corner) -->
                                <button (click)="hour24()" class="absolute top-0 right-0 mt-2 mr-2 bg-blue-700 text-white rounded-full p-2 focus:outline-none">
                                    <i class="fas fa-sync text-lg"></i>
                                </button>
                                <!-- Active part -->
                                <div (click)="activeClicked(openActiveModalShowdata,1440)" [ngStyle]="{'color': isActiveClicked24Hour ? 'yellow' : '#fafafa'}" class="absolute left-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    Active<br>{{active24HourCount}}
                                </div>
                                <!-- DeActive part -->
                                <div (click)="deActiveClicked(openDeActiveModalShowdata,1440)" [ngStyle]="{'color': isDeActiveClicked24Hour ? 'yellow' : '#fafafa'}" class="absolute right-0 p-4 text-center text-lg bg-blue-700 text-white w-1/2">
                                    DeActive<br>{{deActive24HourCount}}
                                </div>
                            </div>
                            
                            

                            
                        </div>
                    </section>
                    
                </div>

            </div>

            <!-- /.card-body -->
            <div class="card-footer">Footer</div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->


<ng-template #openActiveModalShowdata>
    <section id="extended" style="width: 1000px;" >
      <div class="row">
        <div class="col-12">
          <div class="card" style="margin-right: 120px; margin-left: -390px">
            <div class="card-header">
                        <h4 class="card-title">Active Data Report</h4>
                    </div>
            <form method="post">
              <div>
                <div style="overflow-y: auto;">
                  <!-- ngx-datatable-filter -->
                  <table id="example" class="table text-center m-0 display wrap table-responsive" >
                    <thead>
                      <tr>
                        <!-- <th>Actions</th> -->
                        <th *ngFor="let field of showActiveLogProp">{{ field }}</th>
                        <!-- <th>Group Name</th> -->
                        <!-- <th>Name</th>
                              <th>Created By</th>
                              <th>Created Name</th>
                              <th>Date</th> -->
                      </tr>
                    </thead>
                    <tbody *ngFor="let datas of showActiveLogData; let i = index">
                      <tr>
  
                        <ng-container *ngFor="let val of showActiveLogProp;">
                          <td>
                            {{ datas[val] }}
                          </td>
  
                         
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
    
              </div>
            </form>
            <div class="card-content">
              <div class="card-body">
                <div class="row border rounded">
                  <div class="col-12 col-sm-6 col-lg-4 d-flex align-items-center">
                    <h4 class="card-title" style="color: black;">Total:&nbsp;{{ showActiveLogTotal }}&nbsp;Records</h4>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-8 d-flex align-items-center">
                    <!-- <a class="btn btn-primary" (click)="onSubmitAdd()">Add</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
  </ng-template>


<ng-template #openDeActiveModalShowdata>
    <section id="extended" style="width: 1000px;" >
      <div class="row">
        <div class="col-12" style="margin-left: 130px;">
          <div class="card" style="margin-right: 485px;margin-left: -300px;padding-left: 0px;">
            <div class="card-header">
                        <h4 class="card-title">Deactive Data Report</h4>
                    </div>
            <form method="post">
              <div>
                <div style="overflow-y: auto;">
                  <!-- ngx-datatable-filter -->
                  <table id="example" class="table text-center m-0 display wrap table-responsive" >
                    <thead>
                      <tr>
                        <!-- <th>Actions</th> -->
                        <th *ngFor="let field of showDeActiveLogProp">{{ field }}</th>
                        <!-- <th>Group Name</th> -->
                        <!-- <th>Name</th>
                              <th>Created By</th>
                              <th>Created Name</th>
                              <th>Date</th> -->
                      </tr>
                    </thead>
                    <tbody *ngFor="let datas of showDeActiveLogData; let i = index">
                      <tr>
  
                        <ng-container *ngFor="let val of showDeActiveLogProp;">
                          <td>
                            {{ datas[val] }}
                          </td>
  
                         
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
    
              </div>
            </form>
            <div class="card-content">
              <div class="card-body">
                <div class="row border rounded">
                  <div class="col-12 col-sm-6 col-lg-4 d-flex align-items-center">
                    <h4 class="card-title" style="color: black;">Total:&nbsp;{{ showDeActiveLogTotal }}&nbsp;Records</h4>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-8 d-flex align-items-center">
                    <!-- <a class="btn btn-primary" (click)="onSubmitAdd()">Add</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
  </ng-template>




<!-- In your HTML -->
<div class="loader-container" *ngIf="isLoading">
  <div class="lds-hourglass"></div>
</div>
<!-- Your other HTML goes here -->
