<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Activate Merchant</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Activate Merchant</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="sendMerchantDataForm" (ngSubmit)="onSubmit()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Activate Merchant</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="ip_key" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="ip_key" id="ip_key" formControlName="ip_key"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                                <div class="form-text text-danger" *ngIf="ip_key.errors?.['required'] && (ip_key.touched || ip_key.dirty)">
                                    Api Key is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="merchant_id" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Merchant Id</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="merchant_id" id="merchant_id" formControlName="merchant_id"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Merchant ID" />
                                <div class="form-text text-danger" *ngIf="merchant_id.errors?.['required'] && (merchant_id.touched || merchant_id.dirty)">
                                    Merchant Id is required
                                </div>
                        </div>
                    </div>

                    

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="pos_id" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Pos Id</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="pos_id" id="pos_id" formControlName="pos_id"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter POS ID" />
                                <!-- <div class="form-text text-danger" *ngIf="pos_id.errors?.['required'] && (pos_id.touched || pos_id.dirty)">
                                    Amount is required
                                </div>
                                <div class="form-text text-danger" *ngIf="amount.errors?.['pattern'] && (amount.touched || amount.dirty)">
                                    Only numbers are allowed and up to four numbers are allowed after the dot.
                                </div> -->
                        </div>
                    </div>


                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="device_id" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device Id/Product Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="device_id" id="device_id" formControlName="device_id"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Id/Product Key" />
                                <!-- <div class="form-text text-danger" *ngIf="device_id.errors?.['required'] && (device_id.touched || device_id.dirty)">
                                    Device ID is required
                                </div> -->
                        </div>
                    </div>


                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="btn btn-primary">
                                Activate Merchant
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->




<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                
                <button class="btn btn-primary" (click)="copyText()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL1}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
    
</section>
<!-- /.content -->