import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

@Component({
  selector: 'app-developerapi',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    MatTooltipModule
  ],
  templateUrl: './developerapi.component.html',
  styleUrl: './developerapi.component.scss'
})
export class DeveloperapiComponent implements OnInit {

  prop: any;
  data: any;
  total: any;

  isLoading = true; // Add this line to show the loader at the beginning
  isLoadingEdit= false;

  constructor(private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private router:Router){}

  ngOnInit(): void {
    this.getAPIkeyData()
  }

  displayedDeviceData:any;

  filterFromTable(input: any) {
    //console.log(input);
  
    if (!input) {
      // If there's no input, reset to the original dataset
      this.displayedDeviceData = [...this.data];
    } else {
      const res = this.data.filter(device => 
        Object.values(device).some(prop => 
          prop !== null && prop !== undefined && prop.toString().toLowerCase().includes(input.toLowerCase())
        )
      );
      this.displayedDeviceData = res;
    }
  }

  sortData(sortField: string, sortDirection: string) {
    this.displayedDeviceData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  capitalizeAndReplace(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

  getAPIkeyData(){
    this.service.getShowAPIKey().subscribe((data:any)=>{
      //console.log("data",data);

      if (data['status'] == true) {
        //console.log("here",data['data']);

        this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total') {
                this.prop.push(property);
              }
              //console.log(this.prop);
            }
          } 
        
        this.data = data['data'];
        this.displayedDeviceData = [...this.data];
        this.total = data['total_apis'];
        this.isLoading = false;
      } else {
        this.data = null;
        this.toast.error(data['ErrorMsg']);
        this.isLoading = false;
      }
    })
  }

  addAPIForm = new FormGroup({
    IPName : new FormControl('',[Validators.required])
  })

  get IPName(): FormControl {
    return this.addAPIForm.get('IPName') as FormControl;
  }

  addAPISubmit(){
    //console.log(this.addAPIForm.value);
    try {
      let sendAPIData = {
        "ip_address": this.addAPIForm.value.IPName
      }

      //console.log(sendAPIData);
      
      this.service.postGenerateKeyAPI(sendAPIData).subscribe((data:any)=>{
        //console.log(data);
        if (data['status'] == true) {
          this.toast.success(data['message'])
          this.ngOnInit();
        }else{
          this.toast.error(data['message']);
          this.ngOnInit()
        }
        
      })

    } catch (error) {
      console.log(error);
      
    }
    
  }

  closeResult:any;
  openAddAPIForm(content: TemplateRef<any>){


    this.modalService.open(content, { ariaLabelledBy: 'addGroupBtn' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);

  }
  modal:any
  submitAndClose(modal: any) {
    // perform your actions here, like sending the form data

    // then close the modal
    //this.modalReference.close('Save Clicked');
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}

  editAPIForm = new FormGroup({
    editAPIName : new FormControl('',[Validators.required])
  })

  get editAPIName(): FormControl {
    return this.editAPIForm.get('editAPIName') as FormControl;
  }

  APIId:any;
  APIName:any;


  openEditUpdateAPIForm(editAPIFormdATA:TemplateRef<any>,editAPIId:any,APIName:any){
    //console.log("edit id",editAPIId);
    this.APIId = editAPIId;
    this.APIName = APIName;
    this.isLoadingEdit = true;
    try {
      this.service.getShowAPIKey().subscribe((data:any)=>{
        //console.log("data",data);
        if(data['status'] == true){
          if (data['data'] != null) {
            if (data['data'].length > 0) {
              this.editAPIForm.controls.editAPIName.setValue(APIName);
              this.isLoadingEdit = false;
            } else {
              this.isLoadingEdit = false;
              this.toast.error('No Data Found')
            }
          } else {
            this.isLoadingEdit = false;
            this.toast.error('No Data Found')
          }
  
        }
      });
    } catch (error:any) {
      this.toast.error(error);
    }
   
    
    this.modalService.open(editAPIFormdATA, { ariaLabelledBy: `editUpdateAPI_${editAPIId}` }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }

  navigateTo(){
    //routerLink="/developerDocument"
    this.router.navigate(['/developerDocument'])
  }

  editAPINameSubmit(){
    try {
      let editAPINameSendData = {
        "id": this.APIId,
        "ip_address":this.editAPIForm.value.editAPIName
      }

      //console.log(editAPINameSendData);
      

      this.service.postEditAPIKey(editAPINameSendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("heer");
          
          this.toast.success(data['message']);
          this.ngOnInit();
        }else{
          this.toast.error("Not Updated");
          this.ngOnInit();
        }
        
      })

    } catch (error:any) {
      this.toast.error(error);
    }
  }


  deleteAPIKey(deleteId:any){
    //console.log(deleteId);
    try {
      let sendDelId = {
        "id":deleteId
      }

      this.service.postDeleteAPIKey(sendDelId).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("heer");
          
          this.toast.success(data['message']);
          this.ngOnInit();
        }else{
          this.toast.error(data['message']);
          this.ngOnInit();
        }
      })

    } catch (error) {
      console.log(error);
      
    }
    
  }

}
