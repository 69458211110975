import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-enable-merchant-or-pos',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './enable-merchant-or-pos.component.html',
    styleUrl: './enable-merchant-or-pos.component.scss'
})
export class EnableMerchantOrPosComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {}

    sendMerchantDataForm = new FormGroup({
        ip_key: new FormControl('', [Validators.required]),
        merchant_id: new FormControl('', [Validators.required]),
        pos_id: new FormControl('', [Validators.required]),
        activationStatus: new FormControl('', [Validators.required])
    });

    get ip_key(): FormControl {
        return this.sendMerchantDataForm.get('ip_key') as FormControl;
    }

    get merchant_id(): FormControl {
        return this.sendMerchantDataForm.get('merchant_id') as FormControl;
    }

    get activationStatus(): FormControl {
        return this.sendMerchantDataForm.get('activationStatus') as FormControl;
    }

    get pos_id(): FormControl {
        return this.sendMerchantDataForm.get('pos_id') as FormControl;
    }

    enableDisableStatus: number = 1;

    onSubmit() {
        // Handle form submission here
        //console.log(this.sendMerchantDataForm.value);

        if (this.sendMerchantDataForm.value.ip_key == '') {
            this.toast.error('Please enter IP Key.');
        }
        // else if(this.sendMerchantDataForm.value.merchant_id == ''){
        //   this.toast.error("Please enter Merchant Id.")
        // }
        else if (this.sendMerchantDataForm.value.activationStatus == '') {
            this.toast.error('Please Select Active or De-Active');
        }
        // else if(this.sendMerchantDataForm.value.pos_id == ''){
        //   this.toast.error("Please Enter POS Id.")
        // }

        try {
            if (this.sendMerchantDataForm.value.activationStatus == 'Enable') {
                //console.log(`active`);
                this.enableDisableStatus = 1;
            }

            if (this.sendMerchantDataForm.value.activationStatus == 'Disable') {
                //console.log(`deactive`);
                this.enableDisableStatus = 0;
            }

            const sendEnableDeviceData = {
                ip_key: this.sendMerchantDataForm.value.ip_key,
                merchant_id: this.sendMerchantDataForm.value.merchant_id,
                status: this.enableDisableStatus,
                pos_id: this.sendMerchantDataForm.value.pos_id
            };

            //console.log(sendEnableDeviceData);

            this.service
                .postEnableDisableMerchantId(sendEnableDeviceData)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] === true) {
                        this.toast.success(data['message']);
                    } else {
                        this.toast.error(data['message']);
                    }
                });
        } catch (error) {
            console.log(`something went wrong`, error);
        }
    }

    getcURL1 = `curl --location "http://3.109.153.23:8500/enable_disable_device" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"ip_key\\\":\\\"0rtuFwE9ExWRtrLcJZFH4ICGIEExYPaB\\\",\\\"merchant_id\\\":\\\"sujsuj\\\",\\\"pos_id\\\":\\\"suj876\\\",\\\"status\\\":\\\"0\\\"}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    userName = localStorage.getItem('UserName');

    logout() {
        this.isAuth.logout();
    }
}
