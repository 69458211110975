import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {
    Component,
    ElementRef,
    NgZone,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faIndianRupee} from '@fortawesome/free-solid-svg-icons';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {catchError, throwError} from 'rxjs';
import {FormsModule} from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {ChangeDetectorRef} from '@angular/core';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-languagepack',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule,
        FormsModule,
        MatPaginatorModule
    ],
    templateUrl: './languagepack.component.html',
    styleUrl: './languagepack.component.scss'
})
export class LanguagepackComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        private ngZONE: NgZone
    ) {}

    ngOnInit(): void {
        this.getLanguagePack();

        //console.log('ng on init', this.viewZipFileDisplayedData);
    }

    displayedDeviceData: any;

    prop: any;
    data: any;
    total: any;
    isLoading = true;
    isLoadingEdit = false;

    filterFromTable(input: any) {
        //console.log(input);

        if (!input) {
            // If there's no input, reset to the original dataset
            this.displayedDeviceData = [...this.data];
        } else {
            const res = this.data.filter((device) =>
                Object.values(device).some(
                    (prop) =>
                        prop !== null &&
                        prop !== undefined &&
                        prop
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                )
            );
            this.displayedDeviceData = res;
        }
    }

    sortData(sortField: string, sortDirection: string) {
        this.displayedDeviceData.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortField] > b[sortField]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    capitalizeAndReplace(str: string): string {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    }

    closeResult: any;

    open(content: TemplateRef<any>) {
        //console.log("open add group");

        this.modalService
            .open(content, {ariaLabelledBy: 'addLanguagePackBtn'})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    private getDismissReason(reason: any): string {
        switch (reason) {
            case ModalDismissReasons.ESC:
                return 'by pressing ESC';
            case ModalDismissReasons.BACKDROP_CLICK:
                return 'by clicking on a backdrop';
            default:
                return `with: ${reason}`;
        }
    }

    addLanguagePackForm = new FormGroup({
        languageName: new FormControl('', [Validators.required]),
        language: new FormControl('', [Validators.required])
    });

    get language(): FormControl {
        return this.addLanguagePackForm.get('language') as FormControl;
    }

    get languageName(): FormControl {
        return this.addLanguagePackForm.get('languageName') as FormControl;
    }

    addLanguagePackSubmit() {
        //console.log(this.addGroupNameForm.value);

        let SendData = {
            lang_name: this.addLanguagePackForm.value.languageName,
            language: this.addLanguagePackForm.value.language
        };

        //console.log(SendData);

        this.service.postAddLanguagePack(SendData).subscribe((data: any) => {
            //console.log(data);
            if (data['status'] == true) {
                this.addLanguagePackForm.reset();
                this.toast.success(data['message']);
                this.ngOnInit();
            } else {
                this.toast.error(data['message']);
                this.ngOnInit();
            }
        });
    }

    // get language pack

    getLanguagePack() {
        this.service.GetLanguagePack().subscribe((data: any) => {
            //console.log('data', data);

            if (data['status'] == true) {
                //console.log("here");

                this.prop = [];
                for (var property in data['data'][0]) {
                    if (data['data'][0].hasOwnProperty(property)) {
                        //console.log("PROPERTY",property);
                        if (property.toLowerCase() != 'total') {
                            this.prop.push(property);
                        }
                        //console.log(this.prop);
                    }
                }
                this.rearrangeArray();
                this.data = data['data'];
                this.displayedDeviceData = [...this.data];
                this.total = data['total_langs'];
                this.isLoading = false;
            } else {
                this.data = null;
                this.toast.error('No Language Packs Found');
                this.isLoading = false;
            }
        });
    }

    // rearrange array

    rearrangeArray() {
        const dateIdx3 = this.prop.indexOf('folder_path');
        if (dateIdx3 > -1) {
            const userNameElement = this.prop.splice(dateIdx3, 1)[0];
            this.prop.unshift(userNameElement);
        }

        const dateIdx2 = this.prop.indexOf('language');
        if (dateIdx2 > -1) {
            const userNameElement = this.prop.splice(dateIdx2, 1)[0];
            this.prop.unshift(userNameElement);
        }

        const dateIdx1 = this.prop.indexOf('lang_name');
        if (dateIdx1 > -1) {
            const userNameElement = this.prop.splice(dateIdx1, 1)[0];
            this.prop.unshift(userNameElement);
        }

        const dateIdx = this.prop.indexOf('id');
        if (dateIdx > -1) {
            const userNameElement = this.prop.splice(dateIdx, 1)[0];
            this.prop.unshift(userNameElement);
        }
    }

    //delete language pack

    deleteLangPack(deleteId: any) {
        //console.log(deleteId);
        try {
            let sendDelId = {
                id: deleteId
            };

            this.service
                .deleteLanguagePack(sendDelId)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] == true) {
                        //console.log("heer");
                        this.toast.success(data['message']);
                        this.ngOnInit();
                    } else {
                        this.toast.error(data['message']);
                        this.ngOnInit();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    //delete Zip Files

    deleteZipFiles(deleteId: any) {
        //console.log(deleteId);
        try {
            let sendDelId = {
                langpack_id: deleteId
            };

            this.service.deleteZipFiles(sendDelId).subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    //console.log("heer");
                    this.toast.success(data['message']);
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    // download zip files

    downloadZipFiles(id: any) {
        try {
            const sendData = {
                langpack_id: id
            };

            this.service.downloadZipFiles(sendData).subscribe({
                next: (response) => {
                    //console.log('Response from downloadZipFiles:', response);
                    const blob = new Blob([response.body], {
                        type: 'application/zip'
                    });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'languageAllFiles.zip'; // Set the file name
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    //console.log('File downloaded successfully'); // Debug log
                },
                error: (err) => {
                    console.error('Error in downloadZipFiles:', err);
                }
            });
        } catch (error) {
            console.error('Error in downloadZipFiles:', error);
        }
    }

    // upload zip files

    triggerFileInput(id: number): void {
        const fileInput = document.getElementById(
            `fileInput_${id}`
        ) as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    }

    onFileSelected(event: any, id: number): void {
        const file: File = event.target.files[0];
        if (file) {
            this.uploadFile(file, id);
        }
    }

    uploadFile(file: File, id: number): void {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('langpack_id', id.toString());

        // Your service method to upload the file
        this.service.uploadZipFile(formData).subscribe(
            (response) => {
                if (response['status'] == true) {
                    this.toast.success(response['message']);
                    //console.log('File uploaded successfully:', response);
                    // Handle the response from the server
                } else {
                    this.toast.error(response['message']);
                }
            },
            (error) => {
                this.toast.error(error['message']);
                console.error('File upload failed:', error);
                // Handle the error from the server
            }
        );
    }

    // go to zip files

    goToZipFiles(id: any) {
        //console.log(id);
        this.isAuth.setDeviceId(id);
        this.router.navigate(['/zipFiles']);
    }

    // update language status

    activeLanguageStatus: any;
    activeLanguageId: any;

    updateLanguageStatusActive(e: any) {
        //console.log(e);
        this.activeLanguageStatus = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).checked;
        this.activeLanguageId = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).value;
        //console.log(this.activeLanguageId,this.activeLanguageStatus);

        if (this.activeLanguageStatus.toString().toLowerCase().trim() == 1) {
            this.activeLanguageStatus = true;
        } else {
            this.activeLanguageStatus = false;
        }
        //console.log(this.activeLanguageStatus, this.activeLanguageId);

        let sendLanguageStatus = {
            langpack_id: this.activeLanguageId
            //"status":this.activeLanguageStatus
        };
        //console.log(sendLanguageStatus);

        this.service
            .updateLanguageStatusActive(sendLanguageStatus)
            .subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
    }

    // update default language

    activeDefaultLanguageStatus: any;
    activeDefaultLanguageId: any;

    updateDefaultLanguageStatus(e: any) {
        //console.log(e);
        this.activeDefaultLanguageStatus = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).checked;
        this.activeDefaultLanguageId = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).value;
        //console.log(this.activeDefaultLanguageId,this.activeDefaultLanguageStatus);

        if (
            this.activeDefaultLanguageStatus.toString().toLowerCase().trim() ==
            1
        ) {
            this.activeDefaultLanguageStatus = true;
        } else {
            this.activeDefaultLanguageStatus = false;
        }
        //console.log(this.activeDefaultLanguageStatus, this.activeDefaultLanguageId);

        let sendDefaultLanguageStatus = {
            langpack_id: this.activeDefaultLanguageId
            //"status":this.activeDefaultLanguageStatus
        };
        //console.log(sendDefaultLanguageStatus);

        this.service
            .updateDefaultLanguageStatusActive(sendDefaultLanguageStatus)
            .subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
    }
}
