<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h2>Online Dashboard</h2>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">
            Online Dashboard Page
          </li>
        </ol>
      </div>
    </div>
  </div>
  <h2 class="text-2xl ml-3">
    Total:&nbsp;{{ totalDashboard }}&nbsp;Dashboard
  </h2>
  <!-- /.container-fluid -->
</section>

<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-info">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" id="activeClicked5MinCount" (click)="min5()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>5 Min</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice5Min }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                5
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked5Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active5MinCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                5
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked5Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive5MinCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
            <i class="ion ion-bag mt-3"></i>
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-success">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" (click)="min10()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>10 Min</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice10Min }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                10
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked10Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active10MinCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                10
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked10Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive10MinCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
            <i class="ion ion-pie-graph mt-3"></i>
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-warning">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" (click)="min15()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>15 Min</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice15Min }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                15
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked15Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active15MinCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                15
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked15Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive15MinCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
            <i class="ion ion-person-add mt-3"></i>
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-danger">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" (click)="min30()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>30 Min</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice30Min }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                30
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked30Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active30MinCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                30
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked30Min
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive30MinCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
            <i class="ion ion-bag mt-3"></i>
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-info">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" (click)="hour1()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>1 Hour</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice1Hour }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                60
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked1Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active1HourCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                60
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked1Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive1HourCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
            <i class="ion ion-bag mt-3"></i>
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-success">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" (click)="hour6()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>6 Hour</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice6Hour }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                360
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked6Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active6HourCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                360
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked6Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive6HourCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
            <i class="ion ion-pie-graph mt-3"></i>
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-warning">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" (click)="hour12()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>12 Hour</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice12Hour }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                720
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked12Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active12HourCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                720
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked12Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive12HourCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
            <i class="ion ion-person-add mt-3"></i>
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-danger">
          <!-- Refresh icon/button -->

          <button type="button" class="btn" style="margin-left: 330px" (click)="hour24()">
            <h2><i class="fas fa-sync-alt text-white"></i></h2>
          </button>

          <div class="inner" style="margin-top: -45px">
            <h3>24 Hour</h3>
            <!-- <p>Min</p> -->
            <h2>Total Devices: {{ totalDevice24Hour }}</h2>

            <div class="status-buttons">
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            activeClicked(
                                                openActiveModalShowdata,
                                                1440
                                            )
                                        " [ngStyle]="{
                                            color: isActiveClicked24Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    Active
                  </button>
                </h2>
                <h2>{{ active24HourCount }}</h2>
              </div>
              <div class="button-group">
                <h2>
                  <button type="button" (click)="
                                            deActiveClicked(
                                                openDeActiveModalShowdata,
                                                1440
                                            )
                                        " [ngStyle]="{
                                            color: isDeActiveClicked24Hour
                                                ? 'yellow'
                                                : '#fafafa'
                                        }">
                    De-Active
                  </button>
                </h2>
                <h2>{{ deActive24HourCount }}</h2>
              </div>
            </div>
          </div>
          <div class="icon">
            <!-- Optional icon placement -->
          </div>
          <a routerLink="/addDevice" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>
    </div>
    <!-- /.row -->
    <!-- Main row -->

    <!-- /.row (main row) -->
  </div>
  <!-- /.container-fluid -->
</section>


<ng-template #openActiveModalShowdata>
  <section id="extended" style="width: 1000px">
    <div class="row">
      <div class="col-12">
        <div class="card" style="margin-right: 120px; margin-left: -390px">
          <div class="card-header">
            <h4 class="card-title">Active Data Report</h4>
          </div>
          <form method="post">
            <div>
              <div style="overflow-y: auto">
                <!-- ngx-datatable-filter -->
                <table id="example" class="table text-center m-0 display wrap table-responsive">
                  <thead>
                    <tr>
                      <!-- <th>Actions</th> -->
                      <th *ngFor="
                                                    let field of showActiveLogProp
                                                ">
                        {{ field }}
                      </th>
                      <!-- <th>Group Name</th> -->
                      <!-- <th>Name</th>
                              <th>Created By</th>
                              <th>Created Name</th>
                              <th>Date</th> -->
                    </tr>
                  </thead>
                  <tbody *ngFor="
                                            let datas of showActiveLogData;
                                            let i = index
                                        ">
                    <tr>
                      <ng-container *ngFor="
                                                    let val of showActiveLogProp
                                                ">
                        <td>
                          {{ datas[val] }}
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div class="card-content">
            <div class="card-body">
              <div class="row border rounded">
                <div class="col-12 col-sm-6 col-lg-4 d-flex align-items-center">
                  <h4 class="card-title" style="color: black">
                    Total:&nbsp;{{
                    showActiveLogTotal
                    }}&nbsp;Records
                  </h4>
                </div>
                <div class="col-12 col-sm-6 col-lg-8 d-flex align-items-center">
                  <!-- <a class="btn btn-primary" (click)="onSubmitAdd()">Add</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #openDeActiveModalShowdata>
  <section id="extended" style="width: 1000px">
    <div class="row">
      <div class="col-12" style="margin-left: 130px">
        <div class="card" style="
                        margin-right: 485px;
                        margin-left: -300px;
                        padding-left: 0px;
                    ">
          <div class="card-header">
            <h4 class="card-title">Deactive Data Report</h4>
          </div>
          <form method="post">
            <div>
              <div style="overflow-y: auto">
                <!-- ngx-datatable-filter -->
                <table id="example" class="table text-center m-0 display wrap table-responsive">
                  <thead>
                    <tr>
                      <!-- <th>Actions</th> -->
                      <th *ngFor="
                                                    let field of showDeActiveLogProp
                                                ">
                        {{ field }}
                      </th>
                      <!-- <th>Group Name</th> -->
                      <!-- <th>Name</th>
                              <th>Created By</th>
                              <th>Created Name</th>
                              <th>Date</th> -->
                    </tr>
                  </thead>
                  <tbody *ngFor="
                                            let datas of showDeActiveLogData;
                                            let i = index
                                        ">
                    <tr>
                      <ng-container *ngFor="
                                                    let val of showDeActiveLogProp
                                                ">
                        <td>
                          {{ datas[val] }}
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div class="card-content">
            <div class="card-body">
              <div class="row border rounded">
                <div class="col-12 col-sm-6 col-lg-4 d-flex align-items-center">
                  <h4 class="card-title" style="color: black">
                    Total:&nbsp;{{
                    showDeActiveLogTotal
                    }}&nbsp;Records
                  </h4>
                </div>
                <div class="col-12 col-sm-6 col-lg-8 d-flex align-items-center">
                  <!-- <a class="btn btn-primary" (click)="onSubmitAdd()">Add</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<!-- In your HTML -->
<div *ngIf="isLoading" class="custom-loader"></div>
<!-- Your other HTML goes here -->