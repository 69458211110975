import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import myUrl from '../../assets/url.json';
import { Observable, retry } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static register:any = '';
  public static login:any = '';
  public static addDevice:any = '';
  public static addGroup:any = '';
  public static showGroups:any = '';
  public static modellist:any = '';
  public static showDevices:any = '';
  public static editGroupName:any = '';
  public static editDevice:any = '';
  public static deleteDevice:any = '';
  public static deleteGroup:any = '';
  public static deviceStatusActiveDeactive:any = '';
  public static generateKey:any = '';
  public static showAPIKey:any = '';
  public static editAPIKey:any = '';
  public static deleteAPIKey:any = '';
  public static devDocumentTestAPI:any = '';
  public static tts:any = '';

  public static showCommands:any = '';
  public static commandJSONPayload:any = '';

  public static showLog:any = '';
  public static searchLog:any = '';

  public static downlaodLogs:any = '';

  public static showTerminalLogs:any = '';

  public static searchTerminalLog:any = '';

  public static downloadTerminalLogs:any = '';

  public static deviceStatusCount:any = '';
  public static activeDeviceList:any = '';
  public static deActiveDeviceList:any = '';

  public static sendGroupdevice:any = '';

  public static addTerminalLog:any = '';

  public static getSummaryReport:any = '';

  public static uploadExcelFile:any = '';

  public static uploadAudioFileCollection:any = '';
  public static showAudioFilesCollection:any = '';
  public static deleteAudioFilesCollection:any = '';
  public static sendToDeviceAudioCollection:any = '';
  public static sendToGroupAudioCollection:any = '';

  public static uploadAudioTTSFilesCollection:any = '';
  public static showAudioTTSFilesCollection:any = '';
  public static deleteTTSAUdioFilesCollection:any = '';

  public static getAudioFilesCodes:any = '';

  public static sendToDeviceAudio:any = '';

  public static sendToGroupAudio:any = '';

  public static summaryFromDevieModel:any = '';

  public static printer:any = '';

  public static testAmount:any = '';

  public static enableDisableBydeviceid:any = ''; 
  public static enableDisableByMerchantId:any = '';
  public static activateMerchant:any = '';

  public static soundBoxDemo:any = '';

  public static sendAmountToDevice:any = '';

  public static paymentHistory:any = '';

  public static sendDeviceId:any = '';
  public static sendCommandDevice:any = '';

  public static paymentHistoryByDeviceId:any = '';

  public static paymentHistorySearch:any = '';

  public static getIdsInPayment:any = '';

  public static audioCollectionTTS:any = '';
  public static audioCollectionGroupTTS:any = '';

  public static sendViaPOSMerchant:any = '';

  public static read(){
    for(let i = 0; i < myUrl.urls.length; i++){

      if(myUrl.urls[i].operationType.toLowerCase().trim() == "register".toLowerCase().trim()){
        AuthService.register = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "login".toLowerCase().trim()){
        AuthService.login = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "adddevice".toLowerCase().trim()){
        AuthService.addDevice = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "addgroup".toLowerCase().trim()){
        AuthService.addGroup = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showgroups".toLowerCase().trim()){
        AuthService.showGroups = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "modellist".toLowerCase().trim()){
        AuthService.modellist = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showdevices".toLowerCase().trim()){
        AuthService.showDevices = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "editgroupname".toLowerCase().trim()){
        AuthService.editGroupName = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "editdevice".toLowerCase().trim()){
        AuthService.editDevice = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "deletedevice".toLowerCase().trim()){
        AuthService.deleteDevice = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "deletegroup".toLowerCase().trim()){
        AuthService.deleteGroup = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "devicestatusactivedeactive".toLowerCase().trim()){
        AuthService.deviceStatusActiveDeactive = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "generatekey".toLowerCase().trim()){
        AuthService.generateKey = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showapikey".toLowerCase().trim()){
        AuthService.showAPIKey = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "editapikey".toLowerCase().trim()){
        AuthService.editAPIKey = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "deleteAPIkey".toLowerCase().trim()){
        AuthService.deleteAPIKey = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "devdocumenttestapi".toLowerCase().trim()){
        AuthService.devDocumentTestAPI = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "tts".toLowerCase().trim()){
        AuthService.tts = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showcommands".toLowerCase().trim()){
        AuthService.showCommands = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "commandjsonpayload".toLowerCase().trim()){
        AuthService.commandJSONPayload = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showlog".toLowerCase().trim()){
        AuthService.showLog = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "searchlog".toLowerCase().trim()){
        AuthService.searchLog = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "downloadlog".toLowerCase().trim()){
        AuthService.downlaodLogs = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showterminallog".toLowerCase().trim()){
        AuthService.showTerminalLogs = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "searchterminallog".toLowerCase().trim()){
        AuthService.searchTerminalLog = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "downloadterminallogs".toLowerCase().trim()){
        AuthService.downloadTerminalLogs = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "devicestatuscount".toLowerCase().trim()){
        AuthService.deviceStatusCount = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "getactivedevicelist".toLowerCase().trim()){
        AuthService.activeDeviceList = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "getdeactivedevicelist".toLowerCase().trim()){
        AuthService.deActiveDeviceList = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendgroupdevice".toLowerCase().trim()){
        AuthService.sendGroupdevice = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "addterminallog".toLowerCase().trim()){
        AuthService.addTerminalLog = myUrl.urls[i].url;
      }
      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "summaryreport".toLowerCase().trim()){
        AuthService.getSummaryReport = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "uploadexcelfile".toLowerCase().trim()){
        AuthService.uploadExcelFile = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "uploadaudiofile".toLowerCase().trim()){
        AuthService.uploadAudioFileCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showaudiofiles".toLowerCase().trim()){
        AuthService.showAudioFilesCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "deleteaudiofiles".toLowerCase().trim()){
        AuthService.deleteAudioFilesCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendtodeviceaudiocollection".toLowerCase().trim()){
        AuthService.sendToDeviceAudioCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendtogroupvoiceaudiocollection".toLowerCase().trim()){
        AuthService.sendToGroupAudioCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "uploadttsfiles".toLowerCase().trim()){
        AuthService.uploadAudioTTSFilesCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "showttsfiles".toLowerCase().trim()){
        AuthService.showAudioTTSFilesCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "deletettsaudiofiles".toLowerCase().trim()){
        AuthService.deleteTTSAUdioFilesCollection = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "audiofilecodes".toLowerCase().trim()){
        AuthService.getAudioFilesCodes = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendtodeviceaudio".toLowerCase().trim()){
        AuthService.sendToDeviceAudio = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendtogroupaudio".toLowerCase().trim()){
        AuthService.sendToGroupAudio = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "summaryfromdeviemodel".toLowerCase().trim()){
        AuthService.summaryFromDevieModel = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "printer".toLowerCase().trim()){
        AuthService.printer = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "testamount".toLowerCase().trim()){
        AuthService.testAmount = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "activateMerchant".toLowerCase().trim()){
        AuthService.activateMerchant = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "enableDisableMerchantOrPos".toLowerCase().trim()){
        AuthService.enableDisableByMerchantId = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "enableDisableDeviceId".toLowerCase().trim()){
        AuthService.enableDisableBydeviceid = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "soundBoxPaymentDemo".toLowerCase().trim()){
        AuthService.soundBoxDemo = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendAmountToDevice".toLowerCase().trim()){
        AuthService.sendAmountToDevice = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "paymentHistory".toLowerCase().trim()){
        AuthService.paymentHistory = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendDeviceId".toLowerCase().trim()){
        AuthService.sendDeviceId = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendCommandDevice".toLowerCase().trim()){
        AuthService.sendCommandDevice = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "paymentHistoryByDeviceId".toLowerCase().trim()){
        AuthService.paymentHistoryByDeviceId = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "paymentHistorySearch".toLowerCase().trim()){
        AuthService.paymentHistorySearch = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "getIdsInPayment".toLowerCase().trim()){
        AuthService.getIdsInPayment = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "audioCollectionTTS".toLowerCase().trim()){
        AuthService.audioCollectionTTS = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "audioCollectionGroupTTS".toLowerCase().trim()){
        AuthService.audioCollectionGroupTTS = myUrl.urls[i].url;
      }

      else if(myUrl.urls[i].operationType.toLowerCase().trim() == "sendViaPOSMerchant".toLowerCase().trim()){
        AuthService.sendViaPOSMerchant = myUrl.urls[i].url;
      }

    }
  }

  constructor(private http:HttpClient) {
    let auth_token = localStorage.getItem('Token');
  
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
      });
 
    const requestOptions = { headers: headers };
   }

   sendMerchantOrPosIdViaMQTT(param:any){
    return this.http.post(AuthService.sendViaPOSMerchant,param);
   }

   audioCollectionGroupTTS(param:any){
    return this.http.post(AuthService.audioCollectionGroupTTS,param);
   }

   audioCollectionTTS(param:any){
    return this.http.post(AuthService.audioCollectionTTS,param);
   }

   getIdsInPaymentHistory(){
    return this.http.get(AuthService.getIdsInPayment);
   }

   postSearchPaymentHistory(param:any){
    return this.http.post(AuthService.paymentHistorySearch,param);
   }

   postFilterPaymentHistoryByDeviceId(param:any){
    return this.http.post(AuthService.paymentHistoryByDeviceId,param);
   }

   postSendCommandDevice(param:any){
    return this.http.post(AuthService.sendCommandDevice,param);
   }

   postSendDeviceId(param:any){
    return this.http.post(AuthService.sendDeviceId,param);
   }

   postPaymentHistory(param:any){
    return this.http.post(AuthService.paymentHistory,param);
   }

   postSendAmount(param:any){
    return this.http.post(AuthService.sendAmountToDevice,param);
   }

   postSoundBoxDemo(param:any){
    return this.http.post(AuthService.soundBoxDemo,param);
   }

   postActivateMerchant(param:any){
    return this.http.post(AuthService.activateMerchant,param);
   }

   postEnableDisableMerchantId(param:any){
    return this.http.post(AuthService.enableDisableByMerchantId,param);
   }
  
   postEnableDisableDeviceId(param:any){
    return this.http.post(AuthService.enableDisableBydeviceid,param);
   }

  postRegisterAPI(param:any){
    return this.http.post(AuthService.register,param);
  }

  postLoginAPI(param:any){
    return this.http.post(AuthService.login,param);
  }

  postAddDeviceAPI(param:any){
    return this.http.post(AuthService.addDevice,param);
  }

  postAddGroupAPI(param:any){
    return this.http.post(AuthService.addGroup,param);
  }

  getShowGroupAPI(){
    return this.http.get(AuthService.showGroups);
  }

  getModelListAPI(){
    return this.http.get(AuthService.modellist);
  }

  getShowDevicesAPI(){
    return this.http.get(AuthService.showDevices);
  }

  postEditGroupNameAPI(param:any){
    return this.http.post(AuthService.editGroupName,param);
  }

  postEditDeviceAPI(param:any){
    return this.http.post(AuthService.editDevice,param);
  }

  postDleteDeviceAPI(param:any){
    return this.http.post(AuthService.deleteDevice,param);
  }

  postDeleteGroupAPI(param:any){
    return this.http.post(AuthService.deleteGroup,param);
  }

  postDeviceStatusActiveDeactive(param:any){
    return this.http.post(AuthService.deviceStatusActiveDeactive,param);
  }

  postGenerateKeyAPI(param:any){
    return this.http.post(AuthService.generateKey,param)
  }

  getShowAPIKey(){
    return this.http.get(AuthService.showAPIKey);
  }

  postEditAPIKey(param:any){
    return this.http.post(AuthService.editAPIKey,param);
  }

  postDeleteAPIKey(param:any){
    return this.http.post(AuthService.deleteAPIKey,param);
  }

  postDevDocumentTestAPI(param:any,param1:any){
    return this.http.post(AuthService.devDocumentTestAPI,param);
  }

  postTTSAPI(param:any){
    return this.http.post(AuthService.tts,param);
  }

  postShowCommandsAPI(param:any){
    return this.http.post(AuthService.showCommands,param);
  }

  postCommandJSONPayload(param:any){
    return this.http.post(AuthService.commandJSONPayload,param);
  }

  getShowLogAPI(param:any){
    return this.http.post(AuthService.showLog,param);
  }

  postSearchLogsAPI(param:any){
    return this.http.post(AuthService.searchLog,param);
  }

  downloadLogsAPI(param: any): Observable<Blob> {
    return this.http.post(AuthService.downlaodLogs, param, { responseType: 'blob' });
  }

  postShowTerminalLog(param:any){
    return this.http.post(AuthService.showTerminalLogs,param);
  }

  postSearchTerminalLog(param:any){
    return this.http.post(AuthService.searchTerminalLog,param)
  }

  downloadTerminalLogs(param: any): Observable<Blob> {
    return this.http.post(AuthService.downloadTerminalLogs, param, { responseType: 'blob' });
  }

  postDeviceStatusCount(param:any){
    return this.http.post(AuthService.deviceStatusCount,param);
  }

  postGetActiveDeviceList(param:any){
    return this.http.post(AuthService.activeDeviceList,param);
  }

  postGetDeActiveDeviceList(param:any){
    return this.http.post(AuthService.deActiveDeviceList,param)
  }

  postSendGroupDevice(param:any){
    return this.http.post(AuthService.sendGroupdevice,param);
  }

  postAddTerminalLog(param:any){
    return this.http.post(AuthService.addTerminalLog,param);
  }

  getSummaryReport(){
    return this.http.get(AuthService.getSummaryReport);
  }

  // uploadExcelFile(param:any){
  //   return this.http.post(AuthService.uploadExcelFile,param);
  // }

  uploadExcelFile(formData: FormData): Observable<any> {
    return this.http.post(AuthService.uploadExcelFile, formData);
  }

  uploadAudioFileCollection(formData: FormData):Observable<any>{
    return this.http.post(AuthService.uploadAudioFileCollection,formData);
  }

  showAudioFilesCollection(){
    return this.http.get(AuthService.showAudioFilesCollection);
  }

  deleteAudioFilesCollection(param:any){
    return this.http.post(AuthService.deleteAudioFilesCollection,param);
  }

  sendToDeviceAudioCollectionAPI(param:any){
    return this.http.post(AuthService.sendToDeviceAudioCollection,param);
  }

  sendToGroupAudioCollectionAPI(param:any){
    return this.http.post(AuthService.sendToGroupAudioCollection,param);
  }


  uploadAudioTTSFileCollection(param:any){
    return this.http.post(AuthService.uploadAudioTTSFilesCollection,param);
  }

  showAudioTTSFilesCollection(){
    return this.http.get(AuthService.showAudioTTSFilesCollection)
  }

  deleteTTSAudioFilesCollection(param:any){
    return this.http.post(AuthService.deleteTTSAUdioFilesCollection,param);
  }

  getAudioFileCodesAPI(){
    return this.http.get(AuthService.getAudioFilesCodes);
  }

  postSendToDeviceAudio(param:any){
    return this.http.post(AuthService.sendToDeviceAudio,param);
  }

  postSendToGroupAudio(param:any){
    return this.http.post(AuthService.sendToGroupAudio,param);
  }

  postSummaryFromDevieModel(param:any){
    return this.http.post(AuthService.summaryFromDevieModel,param);
  }

  postPrinter(param:any){
    return this.http.post(AuthService.printer,param)
  }

  postTestAmount(param:any){
    return this.http.post(AuthService.testAmount,param);
  }

}
