import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {GrouplistComponent} from '@pages/grouplist/grouplist.component';
import {AdddeviceComponent} from '@pages/adddevice/adddevice.component';
import {DeveloperapiComponent} from '@pages/developerapi/developerapi.component';
import {DeveloperdocumentComponent} from '@pages/testapi/developerdocument/developerdocument.component';
import {TtsComponent} from '@pages/testapi/tts/tts.component';
import {TtsgroupComponent} from '@pages/testapi/ttsgroup/ttsgroup.component';
import {TestaudiodeviceComponent} from '@pages/testapi/testaudiodevice/testaudiodevice.component';
import {TestaudiogroupComponent} from '@pages/testapi/testaudiogroup/testaudiogroup.component';
import {TestamountComponent} from '@pages/testapi/testamount/testamount.component';
import {PrintertestComponent} from '@pages/testapi/printertest/printertest.component';
import {MerchantactivateComponent} from '@pages/provisioningbybank/merchantactivate/merchantactivate.component';
import {EnableMerchantOrPosComponent} from '@pages/provisioningbybank/enable-merchant-or-pos/enable-merchant-or-pos.component';
import {EnablebydeviceComponent} from '@pages/provisioningbybank/enablebydevice/enablebydevice.component';
import {LogComponent} from '@pages/log/log.component';
import {TerminallogComponent} from '@pages/terminalmanagement/terminallog/terminallog.component';
import {TestdeviceComponent} from '@pages/terminalmanagement/testdevice/testdevice.component';
import {OnlinedashboardComponent} from '@pages/terminalmanagement/onlinedashboard/onlinedashboard.component';
import {SummaryComponent} from '@pages/terminalmanagement/summary/summary.component';
import {PaymenthistoryComponent} from '@pages/paymenthistory/paymenthistory.component';
import {TtsaudiocollectionComponent} from '@pages/audiocollection/ttsaudiocollection/ttsaudiocollection.component';
import {UploadaudioComponent} from '@pages/audiocollection/uploadaudio/uploadaudio.component';
import {StaffComponent} from '@pages/staff/staff.component';
import {LanguagepackComponent} from '@pages/languagepack/languagepack.component';
import {ZipfilesComponent} from '@pages/zipfiles/zipfiles.component';
import {LanguagepackapisComponent} from '@pages/languagepackapis/languagepackapis.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'staff',
                component: StaffComponent
            },
            {
                path: 'languagePack',
                component: LanguagepackComponent
            },
            {
                path: 'languagePackAPI',
                component: LanguagepackapisComponent
            },
            {
                path: 'zipFiles',
                component: ZipfilesComponent
            },
            {
                path: 'groupList',
                component: GrouplistComponent
            },
            {
                path: 'addDevice',
                component: AdddeviceComponent
            },
            {
                path: 'developerApi',
                component: DeveloperapiComponent
            },
            {
                path: 'developerDocument',
                component: DeveloperdocumentComponent
            },
            {
                path: 'printerTest',
                component: PrintertestComponent
            },
            {
                path: 'textToSpeech',
                component: TtsComponent
            },
            {
                path: 'textToSpeechGroup',
                component: TtsgroupComponent
            },
            {
                path: 'testAudioDevice',
                component: TestaudiodeviceComponent
            },
            {
                path: 'testAudioGroup',
                component: TestaudiogroupComponent
            },
            {
                path: 'testToSendAmount',
                component: TestamountComponent
            },
            {
                path: 'merchantActivate',
                component: MerchantactivateComponent
            },

            {
                path: 'enableMerchantOrPos',
                component: EnableMerchantOrPosComponent
            },

            {
                path: 'enableDisableByDevice',
                component: EnablebydeviceComponent
            },

            {
                path: 'log',
                component: LogComponent
            },
            {
                path: 'terminalLog',
                component: TerminallogComponent
            },
            {
                path: 'testDevice',
                component: TestdeviceComponent
            },
            {
                path: 'onlineDashboard',
                component: OnlinedashboardComponent
            },
            {
                path: 'summary',
                component: SummaryComponent
            },
            {
                path: 'paymentHistory',
                component: PaymenthistoryComponent
            },
            {
                path: 'ttsAudioCollection',
                component: TtsaudiocollectionComponent
            },
            {
                path: 'uploadAudio',
                component: UploadaudioComponent
            },

            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
