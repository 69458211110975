import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {
    Component,
    ElementRef,
    NgZone,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faIndianRupee} from '@fortawesome/free-solid-svg-icons';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {catchError, throwError} from 'rxjs';
import {FormsModule} from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {ChangeDetectorRef} from '@angular/core';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-adddevice',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule,
        FormsModule,
        MatPaginatorModule
    ],
    templateUrl: './adddevice.component.html',
    styleUrl: './adddevice.component.scss'
})
export class AdddeviceComponent implements OnInit {
    selectedGroupName: any;
    selectedDeviceModelName: any;

    editSelectedGroupName: any;
    editSelectedDeviceModelName: any;

    prop: any;
    data: any;
    dataGroupName: any;
    total: any;

    deviceData: any;
    deviceProp: any;
    devicetotal: any;

    showAddedDeviceProp: any;
    showAddedDeviceData: any;
    showAddedDeviceTotal: any;

    closeResult: any;
    selectedFileName: any;

    isLoading = true; // Add this line to show the loader at the beginning

    faIndRupee = faIndianRupee;

    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        private ngZONE: NgZone
    ) {}

    length: any; // Replace with your total data count from API
    page = 1;
    pageSize = 10; // Replace with your default page size
    pageSizeOptions: number[] = [10, 25, 50, 100]; // Or your page size options
    pageEvent: PageEvent;

    ngOnInit(): void {
        // Initially, no filter is applied
        this.callMethodsIn();
    }

    capitalizeAndReplace(str: string): string {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    }

    callMethodsIn() {
        this.data = null;
        this.dataGroupName = null;

        this.getShowGroupList();

        this.getModelList();

        this.getShowDeviceList();
    }

    displaySearchForm = false;

    toggleSearchForm() {
        this.displaySearchForm = !this.displaySearchForm;
    }

    searchFormData = new FormGroup({
        searchDeviceId: new FormControl('', [Validators.required])
    });

    get searchDeviceId(): FormControl {
        return this.searchFormData.get('searchDeviceId') as FormControl;
    }

    showDownloadButtons = false;

    onSearch() {
        this.isLoading = true;

        try {
            this.showDownloadButtons = true;

            let sendData = {
                device_data: this.searchFormData.value.searchDeviceId,
                model_name: this.selectedModelName,
                group_name: this.selectedGroupName,
                page: this.page,
                per_page: this.pageSize
            };

            //console.log("Search send data",sendData);

            this.service
                .postSearchDevicesAPi(sendData)
                .subscribe((data: any) => {
                    //console.log("search Response",data);
                    if (data['status'] == true) {
                        //console.log("here");

                        this.showAddedDeviceProp = [];
                        for (var property in data['data'][0]) {
                            if (data['data'][0].hasOwnProperty(property)) {
                                //console.log("PROPERTY",property);
                                if (
                                    property.toLowerCase() != 'total' &&
                                    property.toLowerCase() != 'id'
                                ) {
                                    this.showAddedDeviceProp.push(property);
                                }
                                //console.log(this.prop);
                            }
                        }

                        this.rearrangeArray();

                        this.showAddedDeviceData = data['data'];
                        this.displayedDeviceData = [
                            ...this.showAddedDeviceData
                        ];
                        this.length = data['total_entries'];
                        this.showAddedDeviceTotal = data['total_entries'];
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.displayedDeviceData = null;
                        this.toast.error('No Data Found');
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    changeColor(event: any) {
        this.renderer.setStyle(event.target, 'background-color', 'green');
        this.renderer.setStyle(event.target, 'border-color', 'green');
    }

    reloadPage() {
        // Navigate to a dummy route first
        this.router
            .navigateByUrl('/refresh', {skipLocationChange: true})
            .then(() => {
                // Then navigate back to the current route
                this.router.navigateByUrl('/addDevice');
            });
    }

    selectedModelName: any;

    changeModelName(value: any) {
        //console.log(value);
        this.selectedModelName = value;
    }

    getShowGroupList() {
        this.service.getShowGroupAPI().subscribe((data: any) => {
            //console.log("show group",data['data']);
            // console.log("show group",data['status']);
            // this.data = data['data'];
            // this.dataGroupName = data['data'];
            //console.log("data",this.data);
            if (data['status'] == true) {
                //console.log("here");

                this.prop = [];
                for (var property in data['data'][0]) {
                    if (data['data'][0].hasOwnProperty(property)) {
                        //console.log("PROPERTY",property);
                        if (property.toLowerCase() != 'total') {
                            this.prop.push(property);
                        }
                        //console.log("prop",this.prop);
                    }
                }

                //console.log(data.data);

                this.dataGroupName = data['data'];
                this.total = data['total_groups'];
                this.isLoading = false;
                //console.log("data",this.data);
            } else {
                this.data = null;
                this.toast.error(data['ErrorMsg']);
                this.isLoading = false;
            }
        });
    }

    getModelList() {
        this.service.getModelListAPI().subscribe((data1: any) => {
            //console.log('Model list', data1);

            if (data1['status'] == true) {
                //console.log("here");

                this.deviceProp = [];
                for (var propertyDevice in data1['data'][0]) {
                    if (data1['data'][0].hasOwnProperty(propertyDevice)) {
                        //console.log("PROPERTY",property);
                        if (propertyDevice.toLowerCase() != 'total') {
                            this.deviceProp.push(propertyDevice);
                        }
                        //console.log("prop",this.deviceProp);
                    }
                }
                this.deviceData = data1['data'];
                this.devicetotal = data1['total_models'];
                //console.log("data",this.data);
            } else {
                this.data = null;
                this.toast.error('Data Not Found');
            }
        });
    }

    displayedDeviceData: any;

    getShowDeviceList() {
        const sendData = {
            page: this.page,
            per_page: this.pageSize
        };

        //console.log(sendData);

        this.service.postShowDevicesAPI(sendData).subscribe((data2: any) => {
            //console.log("show added devices",data2);

            if (data2['status'] == true) {
                //console.log("here");

                this.showAddedDeviceProp = [];
                for (var propertyAddedDevice in data2['data'][0]) {
                    if (data2['data'][0].hasOwnProperty(propertyAddedDevice)) {
                        //console.log("PROPERTY",property);
                        if (
                            propertyAddedDevice.toLowerCase() != 'total' &&
                            propertyAddedDevice.toLocaleLowerCase() != 'id' &&
                            // propertyAddedDevice.toLocaleLowerCase() != 'merchant_id' &&
                            // propertyAddedDevice.toLocaleLowerCase() != 'pos_id' &&
                            propertyAddedDevice.toLocaleLowerCase() !=
                                'device_secret'
                        ) {
                            this.showAddedDeviceProp.push(propertyAddedDevice);
                        }
                        //console.log("prop",this.showAddedDeviceProp);
                    }
                }

                this.rearrangeArray();

                this.showAddedDeviceData = data2['data'];
                this.displayedDeviceData = [...this.showAddedDeviceData];
                this.length = data2['total_entries'];
                this.showAddedDeviceTotal = data2['total_entries'];
                //console.log("data",this.data);
            } else {
                this.data = null;
                this.toast.error('No Device Found');
            }
        });
    }

    rearrangeArray() {
        // Use the JavaScript splice and unshift methods to move 'Date' to the beginning of the array

        // const dateIdx3 = this.showAddedDeviceProp.indexOf('allow_qr_generate_deactive');
        // if (dateIdx3 > -1) {
        //   const allowQRGenerateDeActive = this.showAddedDeviceProp.splice(dateIdx3, 1)[0];
        //   this.showAddedDeviceProp.unshift(allowQRGenerateDeActive);
        // }

        // const dateIdx2 = this.showAddedDeviceProp.indexOf('allow_qr_generate_active');
        // if (dateIdx2 > -1) {
        //   const allowQRGenerateActive = this.showAddedDeviceProp.splice(dateIdx2, 1)[0];
        //   this.showAddedDeviceProp.unshift(allowQRGenerateActive);
        // }

        const dateIdx4 = this.showAddedDeviceProp.indexOf('product_key');
        if (dateIdx4 > -1) {
            const active = this.showAddedDeviceProp.splice(dateIdx4, 1)[0];
            this.showAddedDeviceProp.unshift(active);
        }

        const dateIdx1 = this.showAddedDeviceProp.indexOf('device_name');
        if (dateIdx1 > -1) {
            const active = this.showAddedDeviceProp.splice(dateIdx1, 1)[0];
            this.showAddedDeviceProp.unshift(active);
        }
    }

    pageIndex: number = 0;

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        //console.log(event); // This will give you the current items per page
        this.loadData(event.pageIndex, event.pageSize);
    }

    loadData(pageIndex: number, pageSize: number) {
        // Call your API here with pageIndex and pageSize as parameters
        this.isLoading = true;
        if (pageIndex == 0) {
            pageIndex = 1;
        } else {
            pageIndex++;
        }

        let sendData = {
            device_data: this.searchFormData.value.searchDeviceId,
            model_name: this.selectedModelName,
            group_name: this.selectedGroupName,
            page: pageIndex,
            per_page: pageSize
        };

        //console.log(sendData);

        if (
            sendData['device_data'] != '' ||
            sendData['model_name'] != undefined ||
            sendData['group_name'] != undefined
        ) {
            //console.log("in if");
            //console.log("Search send data",sendData);

            this.service
                .postSearchDevicesAPi(sendData)
                .subscribe((data: any) => {
                    //console.log("search Response",data);
                    if (data['status'] == true) {
                        //console.log("here");

                        this.showAddedDeviceProp = [];
                        for (var property in data['data'][0]) {
                            if (data['data'][0].hasOwnProperty(property)) {
                                //console.log("PROPERTY",property);
                                if (
                                    property.toLowerCase() != 'total' &&
                                    property.toLowerCase() != 'id'
                                ) {
                                    this.showAddedDeviceProp.push(property);
                                }
                                //console.log(this.prop);
                            }
                        }

                        this.rearrangeArray();

                        this.showAddedDeviceData = data['data'];
                        this.displayedDeviceData = [
                            ...this.showAddedDeviceData
                        ];
                        this.length = data['total_entries'];
                        this.showAddedDeviceTotal = data['total_entries'];
                        this.isLoading = false;
                    } else {
                        this.data = null;
                        this.toast.error('No Data Found');
                    }
                });
        } else {
            //console.log("in else");

            this.service.postShowDevicesAPI(sendData).subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    //console.log("here");
                    this.isLoading = false;
                    this.showAddedDeviceProp = [];
                    for (var property in data['data'][0]) {
                        if (data['data'][0].hasOwnProperty(property)) {
                            //console.log("PROPERTY",property);
                            if (
                                property.toLowerCase() != 'total' &&
                                property.toLowerCase() != 'id' &&
                                property.toLocaleLowerCase() != 'device_secret'
                            ) {
                                this.showAddedDeviceProp.push(property);
                            }
                            //console.log(this.prop);
                        }
                    }

                    this.rearrangeArray();

                    this.showAddedDeviceData = data['data'];
                    this.displayedDeviceData = [...this.showAddedDeviceData];
                    this.length = data['total_entries'];
                    this.showAddedDeviceTotal = data['total_entries'];
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                    this.data = null;
                    this.toast.error('No Data Found');
                }
            });
        }
    }

    filterFromTable(input: any) {
        //console.log(input);

        if (!input) {
            // If there's no input, reset to the original dataset
            this.displayedDeviceData = [...this.showAddedDeviceData];
        } else {
            const res = this.showAddedDeviceData.filter((device) =>
                Object.values(device).some(
                    (prop) =>
                        prop !== null &&
                        prop !== undefined &&
                        prop
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                )
            );
            this.displayedDeviceData = res;
        }
    }

    sortData(sortField: string, sortDirection: string) {
        this.displayedDeviceData.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortField] > b[sortField]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    userName = localStorage.getItem('UserName');

    addDeviceNameForm = new FormGroup({
        deviceName: new FormControl('', [Validators.required]),
        deviceId: new FormControl('', [Validators.required]),
        deviceSecret: new FormControl('', [Validators.required]),
        merchantId: new FormControl('', [Validators.required]),
        posId: new FormControl('', [Validators.required]),
        language: new FormControl('en', [Validators.required])
    });

    get language(): FormControl {
        return this.addDeviceNameForm.get('language') as FormControl;
    }

    get groupName(): FormControl {
        return this.addDeviceNameForm.get('deviceName') as FormControl;
    }

    get deviceId(): FormControl {
        return this.addDeviceNameForm.get('deviceId') as FormControl;
    }

    get deviceSecret(): FormControl {
        return this.addDeviceNameForm.get('deviceSecret') as FormControl;
    }

    get merchantId(): FormControl {
        return this.addDeviceNameForm.get('merchantId') as FormControl;
    }

    get posId(): FormControl {
        return this.addDeviceNameForm.get('posId') as FormControl;
    }

    changeGroupName(value: any) {
        //console.log("hello",value);
        this.selectedGroupName = value;
        this.editSelectedGroupName = value;
    }

    deviceModel: any;

    chnageDeviceName(value: any) {
        //console.log(value);

        this.selectedDeviceModelName = value;
    }
    modal: any;
    modalReference: any;
    openAddDeviceForm(open: any) {
        this.modalReference = this.modalService.open(open, {
            ariaLabelledBy: 'addDeviceBtn'
        });
        this.modalReference.result.then(
            (result: any) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason: any) => {
                this.closeResult = `Dismissed `;
            }
        );

        // this.modalService.open(open, { ariaLabelledBy: 'addGroupBtn' }).result.then(
        // 	(result) => {
        // 		this.closeResult = `Closed with: ${result}`;
        // 	},
        // 	(reason) => {
        // 		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        // 	},
        // );
    }

    open(content: TemplateRef<any>) {
        //console.log("open add group");

        this.modalService
            .open(content, {ariaLabelledBy: 'addDeviceBtn'})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    submitAndClose(modal: any) {
        // perform your actions here, like sending the form data

        // then close the modal
        //this.modalReference.close('Save Clicked');
        this.modalService.dismissAll();
    }

    private getDismissReason(reason: any): string {
        switch (reason) {
            case ModalDismissReasons.ESC:
                return 'by pressing ESC';
            case ModalDismissReasons.BACKDROP_CLICK:
                return 'by clicking on a backdrop';
            default:
                return `with: ${reason}`;
        }
    }

    addDeviceInGroupName() {
        // console.log(this.addDeviceNameForm.value);

        // console.log("in device group name",this.selectedGroupName);
        // console.log("in device device Name",this.selectedDeviceModelName);

        let addDeviceSendData = {
            device_name: this.addDeviceNameForm.value.deviceName,
            device_id: this.addDeviceNameForm.value.deviceId,
            device_model: this.selectedDeviceModelName,
            group_name: this.selectedGroupName,
            device_secret: this.addDeviceNameForm.value.deviceSecret,
            merchant_id: this.addDeviceNameForm.value.merchantId,
            pos_id: this.addDeviceNameForm.value.posId,
            language_code: this.addDeviceNameForm.value.language
        };

        //console.log(addDeviceSendData);

        this.service
            .postAddDeviceAPI(addDeviceSendData)
            .subscribe((data: any) => {
                //console.log(data);

                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.addDeviceNameForm.reset();
                    this.selectedGroupName = null;
                    this.selectedDeviceModelName = null;
                    this.callMethodsIn();
                } else {
                    this.toast.error(data['message']);
                    this.callMethodsIn();
                }
            });
    }

    editDeviceNameForm = new FormGroup({
        editDeviceName: new FormControl('', [Validators.required]),
        editDeviceId: new FormControl('', [Validators.required]),
        editDeviceSecret: new FormControl('', [Validators.required]),
        editMerchantId: new FormControl('', [Validators.required]),
        editPosId: new FormControl('', [Validators.required]),
        editLanguage: new FormControl('', [Validators.required])
    });

    get editLanguage(): FormControl {
        return this.editDeviceNameForm.get('editLanguage') as FormControl;
    }

    get editDeviceName(): FormControl {
        return this.editDeviceNameForm.get('editDeviceName') as FormControl;
    }

    get editDeviceSecret(): FormControl {
        return this.editDeviceNameForm.get('editDeviceSecret') as FormControl;
    }

    get editMerchantId(): FormControl {
        return this.editDeviceNameForm.get('editMerchantId') as FormControl;
    }

    get editPosId(): FormControl {
        return this.editDeviceNameForm.get('editPosId') as FormControl;
    }

    editDeviceNameSubmit() {
        //console.log(this.actionMianId,this.editDeviceNameForm.value,this.editSelectedGroupName,this.editSelectedDeviceModel);

        try {
            let sendEditDeviceData = {
                id: this.editDeviceMainId,
                device_name: this.editDeviceNameForm.value.editDeviceName,
                device_id: this.editDeviceNameForm.value.editDeviceId,
                device_model: this.selectedDeviceModelName,
                group_name: this.editSelectedGroupName,
                device_secret: this.editDeviceNameForm.value.editDeviceSecret,
                merchant_id: this.editDeviceNameForm.value.editMerchantId,
                pos_id: this.editDeviceNameForm.value.editPosId,
                language_code: this.editDeviceNameForm.value.editLanguage
            };

            //console.log("send edit data",sendEditDeviceData);

            this.service
                .postEditDeviceAPI(sendEditDeviceData)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] == true) {
                        this.toast.success(data['message']);
                        this.ngOnInit();
                    } else {
                        this.toast.error(data['message']);
                        this.ngOnInit();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    editDeviceMainId: any;
    editselectedDeviceId: any;
    editSelectedDeviceModel: any;
    editSelectedDeviceSecret: any;
    editSelectedMerchantId: any;
    editSelectedPosId: any;
    editSelectedLanguage: any;

    openEditUpdateDeviceForm(
        editUpdateDeviceForm: TemplateRef<any>,
        editId: any,
        editDeviceName: any,
        editGroupName: any,
        editDeviceId: any,
        editDeviceModel: any,
        editDeviceSecret: any,
        editMerchantId: any,
        editPosId: any,
        editLanguageCode: any
    ) {
        this.isLoading = true;
        this.editDeviceMainId = editId;
        this.editSelectedDeviceModelName = editId;
        this.editSelectedGroupName = editGroupName;
        this.editselectedDeviceId = editDeviceId;
        this.editSelectedDeviceModel = editDeviceModel;
        this.editSelectedDeviceSecret = editDeviceSecret;
        this.editSelectedMerchantId = editMerchantId;
        this.editSelectedPosId = editPosId;
        this.editSelectedLanguage = editLanguageCode;

        // this.actionMianId = this.editDeviceMainId = editId;
        // this.actionDeviceName = editDeviceName;
        // this.actionGroupName = editGroupName;
        // this.actionDeviceId = editDeviceId;
        // this.actionDeviceModel = editDeviceModel;
        // this.actionDeviceSecret = editDeviceSecret;
        // this.actionMerchantID = editMerchantId;
        // this.actionPosId = editPosId;
        // this.actionLanguageCode = editLanguageCode;

        this.editSelectedGroupName = editGroupName;

        // console.log("edit id",editId);
        // console.log("Device Name",editDeviceName);
        // console.log("Group Name",editGroupName);
        // console.log("Device Id",editDeviceId);
        // console.log("Device secret",editDeviceSecret);
        // console.log("merchant id",editMerchantId);
        // console.log("pos id",editPosId);

        try {
            this.service.getShowDevicesAPI().subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    if (data['data'] != null) {
                        if (data['data'].length > 0) {
                            this.editDeviceNameForm.controls.editDeviceName.setValue(
                                editDeviceName
                            );
                            this.editDeviceNameForm.controls.editDeviceId.setValue(
                                editDeviceId
                            );
                            this.selectedGroupName = editGroupName;
                            this.selectedDeviceModelName = editDeviceModel;
                            this.editDeviceNameForm.controls.editDeviceSecret.setValue(
                                editDeviceSecret
                            );
                            this.editDeviceNameForm.controls.editMerchantId.setValue(
                                editMerchantId
                            );
                            this.editDeviceNameForm.controls.editPosId.setValue(
                                editPosId
                            );
                            this.editDeviceNameForm.controls.editLanguage.setValue(
                                editLanguageCode
                            );

                            this.isLoading = false;
                        } else {
                            this.isLoading = false;
                            this.toast.error('No Data Found');
                        }
                    } else {
                        this.isLoading = false;
                        this.toast.error('No Data Found');
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }

        this.modalService
            .open(editUpdateDeviceForm, {
                ariaLabelledBy: `editUpdateGroup_${editId}`
            })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    commandForm = new FormGroup({
        commandName: new FormControl('', [Validators.required]),
        jsonPayload: new FormControl('', [Validators.required])
    });

    get commandName(): FormControl {
        return this.commandForm.get('commandName') as FormControl;
    }

    get jsonPayload(): FormControl {
        return this.commandForm.get('jsonPayload') as FormControl;
    }

    showCommands: any[] = [];

    changeCommandNameFromOption: any;

    changeCommandName(commandName: any) {
        //console.log(commandName);
        this.changeCommandNameFromOption = commandName;

        const selectedCommand = this.showCommands.find(
            (c) => c.command_name === commandName
        );

        if (selectedCommand) {
            //this.editDeviceNameForm.controls.editDeviceName.setValue(editDeviceName);
            this.commandForm.controls.jsonPayload.setValue(
                selectedCommand.JSON_payload
            );
        } else {
            // If not found, clear the jsonPayload form control
            this.commandForm.controls.jsonPayload.setValue('');
        }
    }

    deviceIdForCommand: any;

    openSendDeviceId(sendDeviceId: TemplateRef<any>, deviceId: any) {
        //console.log("device id",deviceId);
        this.isLoading = true;
        this.deviceIdForCommand = deviceId;
        const sendId = {
            device_id: deviceId
        };

        this.service.postSendDeviceId(sendId).subscribe((data: any) => {
            //console.log(data);
            if (data['status'] === true) {
                this.isLoading = false;
                this.showCommands = data.data;

                // this.modalReference = this.modalService.open(sendDeviceId, {
                //   ariaLabelledBy: `sendDeviceId_${deviceId}`,
                // });
                // this.modalReference.result.then(
                //   (result: any) => {
                //     this.closeResult = `Closed with: ${result}`;
                //   },
                //   (reason: any) => {
                //     this.closeResult = `Dismissed `;
                //   }
                // );

                this.modalService
                    .open(sendDeviceId, {
                        ariaLabelledBy: `sendDeviceId_${deviceId}`
                    })
                    .result.then(
                        (result) => {
                            this.closeResult = `Closed with: ${result}`;
                        },
                        (reason) => {
                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        }
                    );
            } else {
                this.isLoading = false;
                this.toast.info(data['message']);
            }
        });
    }

    sendCommandToDevice() {
        try {
            const sendCommand = {
                device_id: this.deviceIdForCommand,
                json_payload: this.commandForm.value.jsonPayload
            };

            //console.log(sendCommand);
            this.service
                .postSendCommandDevice(sendCommand)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] === true) {
                        this.commandForm.reset();
                        this.toast.success(data['message']);
                    } else {
                        this.toast.error(data['message']);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    deviceStatus = 'Online';
    batteryStatus = 80;

    deviceHistory(deviceId: any) {
        //console.log(deviceId);
        this.isAuth.setDeviceId(deviceId);
        this.router.navigate(['/paymentHistory']);
    }

    sendTTSToDeviceForm = new FormGroup({
        audioText: new FormControl('', [Validators.required])
    });

    get audioText(): FormControl {
        return this.sendTTSToDeviceForm.get('audioText') as FormControl;
    }

    sendTTSToDevice() {
        try {
            let sendData = {
                device_id: this.audioSendDeviceId,
                text: this.sendTTSToDeviceForm.value.audioText
            };

            this.service
                .sendAudioToDevicePost(sendData)
                .pipe(
                    catchError((error) => {
                        // Log the error or handle it in a user-friendly way
                        console.error('Error occurred:', error);

                        // Optionally, you can return a default value to the subscriber
                        // return of(defaultValue);

                        // Or rethrow the error if you want to handle it further upstream
                        return throwError(
                            () =>
                                new Error(
                                    'An error occurred while sending audio to the device'
                                )
                        );
                    })
                )
                .subscribe({
                    next: (data: any) => {
                        // Handle the successful response
                        //console.log(data);
                        if (data['status'] === true) {
                            this.toast.success(data['message']);
                        } else {
                            this.toast.error(data['message']);
                        }
                    },
                    error: (error) => {
                        // This block is for handling errors that might occur after catchError
                        // For example, if you rethrow the error in catchError, it will be caught here
                        console.error('Failed to handle:', error);
                        this.toast.error(error);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    audioSendDeviceId: any;

    audioSend1(audioSend: TemplateRef<any>, deviceId: any) {
        this.audioSendDeviceId = deviceId;

        this.modalService
            .open(audioSend, {ariaLabelledBy: `sendAudio_${deviceId}`})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    deviceinfo(deviceInfo: TemplateRef<any>, deviceId: any) {
        //console.log(deviceId);

        this.modalService
            .open(deviceInfo, {ariaLabelledBy: `deviceInfoId_${deviceId}`})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    mqttResponseTrue = false;

    connectedDevice: boolean;
    cleanStart: boolean;
    enableAuthentication: boolean;
    connectedAt: any;
    createdAt: any;
    awaitingRelCnt: number;
    awaitingRelMax: number;
    clientIdMqtt: string;
    expiryInterval: number;
    heapSize: number;
    inflightCnt: number;
    inflightMax: number;
    ipAddress: any;
    isBridge: boolean;
    isPersistent: boolean;
    keepAlive: number;
    listener: string;
    mailboxLen: number;
    mountPoint: any;
    mQueueDropped: number;
    mQueueLen: number;
    mQueueMax: number;
    node: string;
    port: number;
    protoName: string;
    protoVersion: number;
    recvCnt: number;
    recvMsg: number;
    recvMsgDropped: number;
    recvMsgDroppedAwaitPubRelTimeOut: any;
    recvMsgQos0: number;
    recvMsgQos1: number;
    recvMsgQos2: number;
    recvOct: number;
    recvPkt: number;
    reductions: number;
    sendCnt: number;
    sendMsg: number;
    sendMsgDropped: number;
    sendMsgDroppedExpired: number;
    sendMsgDroppedQueueFull: number;
    sendMsgDroppedTooLarge: number;
    sendMsgQos0: number;
    sendMsgQos1: number;
    sendMsgQos2: number;
    sendOct: number;
    sendPkt: number;
    subscriptionsCount: number;
    subscriptionsMax: string;
    mqttUserName: string;
    zone: string;

    subStatusClientId: string;
    subStatusNode: string;
    subStatusTopic: string;
    subStatusNl: number;
    subStatusQos: number;
    subStatusRap: number;
    subStatusRh: number;

    subStatusData: any;
    clientStatus: any;

    mqttServerNotConnected: any;

    viewMqttDetails(mqttDetails: TemplateRef<any>, id: any, deviceId: any) {
        this.isLoading = true;
        //console.log(deviceId);

        const send = {
            device_id: deviceId
        };

        this.service.postMqttStatus(send).subscribe({
            next: (data: any) => {
                this.mqttResponseTrue = true;
                //console.log(data);

                //console.log(data['client_status']['enable_authn']);

                if (data['status'] == true) {
                    //console.log(data['client_status']['recv_msg.dropped.await_pubrel_timeout']);
                    //this.clientIdMqtt = data['client_status']['clientid'];

                    this.mountPoint = data['client_status']['mountpoint'];
                    this.recvMsgDroppedAwaitPubRelTimeOut =
                        data['client_status'][
                            'recv_msg.dropped.await_pubrel_timeout'
                        ];
                    this.clientStatus = data['client_status'];
                    this.subStatusData = data['client_subscriptions'][0];
                    this.recvMsgDropped =
                        data['client_status']['recv_msg.dropped'];
                    this.recvMsgQos0 = data['client_status']['recv_msg.qos0'];
                    this.recvMsgQos1 = data['client_status']['recv_msg.qos1'];
                    this.recvMsgQos2 = data['client_status']['recv_msg.qos2'];
                    this.sendMsgDropped =
                        data['client_status']['send_msg.dropped'];
                    this.sendMsgDroppedExpired =
                        data['client_status']['send_msg.dropped.expired'];
                    this.sendMsgDroppedQueueFull =
                        data['client_status']['send_msg.dropped.queue_full'];
                    this.sendMsgDroppedTooLarge =
                        data['client_status']['send_msg.dropped.too_large'];
                    this.sendMsgQos0 = data['client_status']['send_msg.qos0'];
                    this.sendMsgQos1 = data['client_status']['send_msg.qos1'];
                    this.sendMsgQos2 = data['client_status']['send_msg.qos2'];

                    this.subStatusClientId =
                        data['client_subscriptions'][0]['clientid'];
                    this.subStatusNode =
                        data['client_subscriptions'][0]['node'];
                    this.subStatusTopic =
                        data['client_subscriptions'][0]['topic'];
                    this.subStatusNl = data['client_subscriptions'][0]['nl'];
                    this.subStatusQos = data['client_subscriptions'][0]['qos'];
                    this.subStatusRap = data['client_subscriptions'][0]['rap'];
                    this.subStatusRh = data['client_subscriptions'][0]['rh'];

                    this.isLoading = false;
                }
            },
            error: (err: any) => {
                this.isLoading = false;
                this.mqttResponseTrue = false;
                if (err instanceof HttpErrorResponse) {
                    // Check if the error status code is specifically 404
                    //console.log("hello",err.error.message);
                    if (err.status === 404) {
                        //console.log('Resource not found:', err.error.message);
                        this.toast.error(err.error.message);
                        this.mqttServerNotConnected = err.error.message;
                    } else if (err.error && err.error.message) {
                        // Handle cases where the server returns an error message in a known format
                        //console.log(err.error.message);
                    } else {
                        // Handle other types of errors generically
                        //console.log('An error occurred:', err.error.message || err);
                        this.toast.error(
                            'An error occurred:',
                            err.error.message || err
                        );
                    }
                } else {
                    // This block catches errors that are not HTTP responses (unlikely in this context)
                    //console.log('An unexpected error occurred:', err);
                    this.toast.error('An unexpected error occurred:', err);
                }
            }
        });

        this.modalService
            .open(mqttDetails, {ariaLabelledBy: `mqttDetails_${id}`})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    viewId: any;
    viewDeviceId: any;
    viewDeviceModel: any;
    viewDeviceName: any;
    viewDeviceScret: any;
    viewGroupName: any;
    viewLanguageCode: any;
    viewMerchantId: any;
    viewPosId: any;

    notAvailable: any = 'Not Available';

    viewDetails(
        deviceDetails: TemplateRef<any>,
        id: any,
        deviceId: any,
        deviceModel: any,
        deviceName: any,
        deviceSecret: any,
        groupName: any,
        languageCode: any,
        merchantId: any,
        posId: any
    ) {
        //console.log(id,deviceSecret);
        this.viewId = id;
        this.viewDeviceId = deviceId;
        this.viewDeviceModel = deviceModel;
        this.viewDeviceName = deviceName;
        this.viewDeviceScret = deviceSecret;
        this.viewGroupName = groupName;
        this.viewLanguageCode = languageCode;
        this.viewMerchantId = merchantId;
        this.viewPosId = posId;

        this.modalService
            .open(deviceDetails, {ariaLabelledBy: `viewDetails_${id}`})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    SendAmountForm = new FormGroup({
        amount: new FormControl('', [
            Validators.required,
            this.numericValidator()
        ])
    });

    get amount(): FormControl {
        return this.SendAmountForm.get('amount') as FormControl;
    }

    numericValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isValid = /^-?\d+(\.\d+)?$/.test(control.value);
            return isValid ? null : {nonNumeric: {value: control.value}};
        };
    }

    deviceIdForSendAmount: any;

    sendAmountToDevice1() {
        try {
            const sendData = {
                device_id: this.deviceIdForSendAmount,
                amount: this.SendAmountForm.value.amount
            };

            //console.log(sendData);

            this.service.postSendAmount(sendData).subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.SendAmountForm.reset();
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.SendAmountForm.reset();
                    this.ngOnInit();
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    openSendAmount(sendAmountToDevice: TemplateRef<any>, deviceId: any) {
        //console.log("send amount",deviceId);
        this.deviceIdForSendAmount = deviceId;

        this.modalService
            .open(sendAmountToDevice, {
                ariaLabelledBy: `sendAmount_${deviceId}`
            })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    delId: any;
    delDeviceId: string = '';

    confarmation(
        openConfirmation: TemplateRef<any>,
        deleteId: any,
        deviceId: any
    ) {
        this.delId = deleteId;
        this.delDeviceId = deviceId;

        this.modalService
            .open(openConfirmation, {ariaLabelledBy: `conf_${deleteId}`})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    yes() {
        this.deleteDevice(this.delId);
        this.modalService.dismissAll();
    }

    no() {
        //this.modalReference.dismiss();
        this.modalService.dismissAll();
    }

    deleteDevice(deleteId: any) {
        //console.log(deleteId);

        let sendDeleteId = {
            id: deleteId
        };
        try {
            this.service
                .postDleteDeviceAPI(sendDeleteId)
                .subscribe((data: any) => {
                    //console.log(data);

                    if (data['status'] == true) {
                        this.modalService.dismissAll();
                        this.callMethodsIn();
                        this.toast.success(data['message']);
                    } else {
                        this.modalService.dismissAll();
                        this.callMethodsIn();
                        this.toast.error(data['message']);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    activeDeviceStatus: any;
    activeDeviceId: any;

    updateDeviceStatusActive(e: any) {
        //console.log(e);
        this.activeDeviceStatus = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).checked;
        this.activeDeviceId = (<HTMLInputElement>(
            document.getElementById('active' + e)
        )).value;
        //console.log(this.activeDeviceId,this.activeDeviceStatus);

        if (this.activeDeviceStatus.toString().toLowerCase().trim() == 1) {
            this.activeDeviceStatus = true;
        } else {
            this.activeDeviceStatus = false;
        }
        //console.log(this.activeDeviceStatus,this.activeDeviceId);

        let sendDeviceStatus = {
            id: this.activeDeviceId
            //"status":this.activeDeviceStatus
        };
        //console.log(sendDeviceStatus);

        this.service
            .postDeviceStatusActiveDeactive(sendDeviceStatus)
            .subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
    }

    // Add this method
    selectedFile: File | null = null; // Add this line to store the selected file
    @ViewChild('fileInput', {static: false})
    fileInputRef!: ElementRef<HTMLInputElement>;

    fileSelected: boolean = false;

    onFileSelected(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileSelected = true;
            const fileNameParts = file.name.split('.');
            const fileType =
                fileNameParts.length > 1 ? fileNameParts.pop() : '';

            // Check if file is an Excel file
            if (fileType && ['xlsx', 'xls', 'csv'].includes(fileType)) {
                this.selectedFileName = file.name; // store the file name
                this.selectedFile = file; // store the file itself
                //console.log('Excel file selected:', file);
            } else {
                //console.log('Please select an Excel file.');
                this.clearFile();
            }
        }
    }

    // Add this new function to send the file when the button is clicked
    uploadFile() {
        //console.log("upload file");

        if (this.selectedFile) {
            // Create a new FormData instance
            let formData: FormData = new FormData();

            // Append the file to the form data. The 'file' parameter will depend on what your API expects
            formData.append('file', this.selectedFile);
            //console.log(formData);

            // Send the form data to your service
            this.service.uploadExcelFile(formData).subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.clearFile();
                    this.ngOnInit();
                } else {
                    this.toast.error(data['message']);
                    this.clearFile();
                    this.ngOnInit();
                }
            });
        } else {
            //console.log('No file selected.');
            this.toast.error('No file selected.');
        }
    }

    clearFile() {
        this.selectedFile = null;

        if (this.fileInputRef && this.fileInputRef.nativeElement) {
            this.fileInputRef.nativeElement.value = '';
        }
        this.selectedFileName = ''; // Clear the selected file name
        this.fileSelected = false; // Update the flag
    }

    onDownloadPDF() {
        // Implement your PDF download functionality here.
        //console.log('Download PDF button clicked!');

        try {
            let buttonPDF = document.getElementById('downloadPDFButton');
            if (buttonPDF) {
                buttonPDF.style.borderColor = 'green';
                buttonPDF.style.backgroundColor = 'green';
                buttonPDF.style.color = 'white';
            }

            let sendData = {
                device_data: this.searchFormData.value.searchDeviceId,
                model_name: this.selectedModelName,
                group_name: this.selectedGroupName,
                download_type: 'pdf'
            };

            //console.log(sendData);

            this.service.postDownloadDeviceListData(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'deviceData.pdf'); // replace 'filename.pdf' with your desired filename
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    onDownloadCSV() {
        try {
            let buttonCSV = document.getElementById('downloadCSVButton');
            if (buttonCSV) {
                buttonCSV.style.borderColor = 'green';
                buttonCSV.style.backgroundColor = 'green';
                buttonCSV.style.color = 'white';
            }

            let sendData = {
                device_data: this.searchFormData.value.searchDeviceId,
                model_name: this.selectedModelName,
                group_name: this.selectedGroupName,
                download_type: 'csv'
            };

            //console.log(sendData);

            this.service.postDownloadDeviceListData(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'deviceData.csv'); // replace 'filename.pdf' with your desired filename
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    onDownloadXLSX() {
        try {
            let buttonXLSX = document.getElementById('downloadXLSXButton');
            if (buttonXLSX) {
                buttonXLSX.style.borderColor = 'green';
                buttonXLSX.style.backgroundColor = 'green';
                buttonXLSX.style.color = 'white';
            }

            let sendData = {
                device_data: this.searchFormData.value.searchDeviceId,
                model_name: this.selectedModelName,
                group_name: this.selectedGroupName,
                download_type: 'excel'
            };

            //console.log(sendData);

            this.service.postDownloadDeviceListData(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'deviceData.xlsx'); // replace 'filename.pdf' with your desired filename
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    logout() {
        //this.isAuth.logout();
    }
}
