import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnInit, TemplateRef} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-developerdocument',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './developerdocument.component.html',
    styleUrl: './developerdocument.component.scss'
})
export class DeveloperdocumentComponent implements OnInit {
    closeResult: any;
    modalReference: any;

    deviceProp: any;

    deviceModelData: any;
    deviceModelTotal: any;

    commandData: any;
    JSONCommandData: any;

    selectedDeviceModelName: any;
    selectedCommandModelName: any;

    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        this.modelListAPI();
    }

    modelListAPI() {
        this.service.getModelListAPI().subscribe((data1: any) => {
            //console.log('Model list', data1);

            if (data1['status'] == true) {
                //console.log("here");

                this.deviceProp = [];
                for (var propertyDevice in data1['data'][0]) {
                    if (data1['data'][0].hasOwnProperty(propertyDevice)) {
                        //console.log("PROPERTY",property);
                        if (propertyDevice.toLowerCase() != 'total') {
                            this.deviceProp.push(propertyDevice);
                        }
                        //console.log("prop",this.deviceProp);
                    }
                }
                this.deviceModelData = data1['data'];
                this.deviceModelTotal = data1['total_models'];
                //console.log("data",this.data);
            } else {
                this.deviceModelData = null;
                this.toast.error('Data Not Found');
            }
        });
    }

    userName = localStorage.getItem('UserName');

    devDocumentForm = new FormGroup({
        apiKey: new FormControl('', [Validators.required]),
        deviceId: new FormControl('', [Validators.required]),
        jsonPayLoad: new FormControl('', [Validators.required])
    });

    get apiKey(): FormControl {
        return this.devDocumentForm.get('apiKey') as FormControl;
    }

    get deviceId(): FormControl {
        return this.devDocumentForm.get('deviceId') as FormControl;
    }

    get jsonPayLoad(): FormControl {
        return this.devDocumentForm.get('jsonPayLoad') as FormControl;
    }

    sendData() {
        try {
            const url = `http://91.107.200.91:6500/send_data/${this.devDocumentForm.value.apiKey}/${this.devDocumentForm.value.deviceId}`;
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            let sendMqttSendData = this.devDocumentForm.value.jsonPayLoad;

            //console.log("url:", url,sendMqttSendData);

            this.http
                .post(url, sendMqttSendData, {headers})
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] == true) {
                        //console.log("heer");
                        this.ngOnInit();
                        this.toast.success(data['message']);
                        this.devDocumentForm.reset();
                        this.selectedDeviceModelName = null;
                        this.selectedCommandModelName = null;
                    } else {
                        this.toast.error(data['message']);
                        this.ngOnInit();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    getcURL1 = `curl --location --request POST "http://91.107.200.91:6500/send_data/DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36/ctGVOPt26yxU263FFA1BiDZ9Ed1yMzCT" ^
                    --header "Content-Type: application/json" ^
                    --header "Cookie: session=eyJlbWFpbCI6Ik1lZXQgTWVodGEifQ.ZEYt0g.lqR-F8rJui-6HnWHrhyIBxCdtRs" ^
                    --data-raw "{\\\"screen\\\": \\\"WelcomeScreen\\\", \\\"data\\\": { \\\"title\\\": \\\"Welcome to Easovation Solutions\\\", \\\"voice_data\\\": \\\"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230512T062002801Z905486.wav\\\", \\\"img\\\": \\\"\\\" }}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    openAddDeviceForm(open: TemplateRef<any>) {
        this.modalService
            .open(open, {ariaLabelledBy: 'selectJSON'})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    private getDismissReason(reason: any): string {
        switch (reason) {
            case ModalDismissReasons.ESC:
                return 'by pressing ESC';
            case ModalDismissReasons.BACKDROP_CLICK:
                return 'by clicking on a backdrop';
            default:
                return `with: ${reason}`;
        }
    }

    deviceModelID: any;

    selectDeviceModel(value: any) {
        //console.log(value);
        this.deviceModelID = value;
        let sendModelID = {
            model_id: value
        };

        this.service.postShowCommandsAPI(sendModelID).subscribe((data: any) => {
            //console.log(data);
            if (data['status'] == true) {
                this.commandData = data['data'];
            } else {
                this.toast.error(data['message']);
            }
        });
    }

    commandModel: any;

    selectCommandModel(value: any) {
        //console.log(value);
        this.commandModel = value;

        try {
            let sendCommandName = {
                model_id: this.deviceModelID,
                command_name: value
            };

            //console.log(sendCommandName);

            this.service
                .postCommandJSONPayload(sendCommandName)
                .subscribe((data: any) => {
                    //console.log(data['data']);
                    const jsonData = data['data'];
                    //console.log(jsonData);

                    if (data['status'] == true) {
                        //console.log("here");

                        this.devDocumentForm.controls.jsonPayLoad.setValue(
                            jsonData
                        );
                        //this.modalReference.close('Save Clicked')
                        this.submitAndClose(this.modal);
                    } else {
                        this.toast.error(data['message']);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    modal: any;

    submitAndClose(modal: any) {
        // perform your actions here, like sending the form data

        // then close the modal
        //this.modalReference.close('Save Clicked');
        this.modalService.dismissAll();
    }

    logout() {
        this.isAuth.logout();
    }
}
