<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Summary</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Summary Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Summary</h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="flex justify-between items-center mb-3">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;{{ showSummaryTotal }}&nbsp;Records
                                </h3>
                            </div>
                            <div class="flex-column text-right">
                                <button type="button" id="openCompany" (click)="selectCompany(content)"
                            class="btn btn-primary mb-2">
                            View Expanded Data
                        </button>
                        <div class="search-container flex items-center ml-2">
                            <label for="searchDevicesInput" class="form-label mt-2 mr-2">Search:</label>
                            <input type="text" id="searchDevicesInput" placeholder="Search Devices..." (input)="filterFromTable($event.target.value)" class="form-control">
                        </div>
                            </div>
                        </div>

                        <table
                            id="example2"
                            class="table table-bordered table-hover"
                        >
                            <thead>
                                <tr>
                                    <th
                                        *ngFor="
                                            let field of showSummaryProp
                                        "
                                    >
                                        {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="
                                    let datas of displayedSummaryData;
                                    let i = index
                                "
                            >
                                <tr>
                                    <ng-container
                                        *ngFor="let val of showSummaryProp"
                                    >
                                        <td>
                              {{ datas[val] }}
                            </td>

                                    </ng-container>

                                    <!-- <td class="px-4 py-2 border text-center">1</td>
                  <td class="px-4 py-2 border text-center">John Doe</td> -->
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->

<ng-template #content let-modal>
    <form method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Model Name</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="deviceId" class="block text-2xl font-medium leading-6 text-gray-900">Model Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select [value]="selectedCompanyName" (selectionChange)="changeCompanyName($event.value)">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let datas of dataCompanyName" value="{{ datas['model_name'] }}">{{
                                datas["model_name"] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" id="openCompanyData" (click)="submitAndClose()"
                (click)="openselectCompanyData(openCompanyData)">
                Model Details
            </button>
        </div>
    </form>
</ng-template>

<ng-template #openCompanyData let-modal>
    <div>
        <form method="post">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Summary Report By Model Name</h4>
                <button type="button" class="btn-close" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <div class="mt-10 overflow-x-auto">
                    <table class="table-auto w-full">
                        <thead>
                            <tr class="bg-gray-200 text-gray-600">
                                <th class="px-4 py-2 text-center" *ngFor="let field of showDeviceHeaderData">
                                    {{ capitalizeAndReplace(field) }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let datas of showModelListAllData; let i = index">
                                <ng-container *ngFor="let val of showDeviceHeaderData">
                                    <td class="px-4 py-2 border text-center">
                                        {{ datas[val] }}
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">

            </div>
            <!-- <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
        </div> -->
        </form>
    </div>

</ng-template>



<!-- In your HTML -->
<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>
<!-- Your other HTML goes here -->