import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import myUrl from '../../assets/url.json';
import {
    Observable,
    catchError,
    map,
    retry,
    switchMap,
    tap,
    throwError
} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {
        let auth_token = localStorage.getItem('Token');

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth_token}`
        });

        const requestOptions = {headers: headers};
    }

    apiUrl: string = '../../assets/newUrl.json';

    getUrlByOperation(operationType: string): Observable<string> {
        return this.http.get<any[]>(this.apiUrl).pipe(
            tap((apis) => {
                //console.log("Loaded APIs:", apis); // Check what's actually loaded
            }),
            map((apis) => {
                const api = apis.find((api) => {
                    //console.log("Checking API:", api.operationType); // See what's being checked
                    return api.operationType === operationType;
                });

                if (!api) {
                    console.error(
                        `No API found for operation type: ${operationType}`
                    );
                    throw new Error(
                        `No API found for operation type: ${operationType}`
                    );
                }

                return api.url;
            })
        );
    }

    // constructor(private http: HttpClient) {
    //     let auth_token = localStorage.getItem('Token');

    //     const headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${auth_token}`
    //     });

    //     const requestOptions = {headers: headers};
    // }

    postMqttStatus(param: any) {
        //return this.http.post(ApiService.mqttStatus, param);
        return this.getUrlByOperation('mqttStatus').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    sendAudioToDevicePost(param: any) {
        //return this.http.post(ApiService.sendAudioToDevice, param);
        return this.getUrlByOperation('sendAudioToDevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    sendMerchantOrPosIdViaMQTT(param: any) {
        //return this.http.post(ApiService.sendViaPOSMerchant, param);
        return this.getUrlByOperation('sendViaPOSMerchant').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    audioCollectionGroupTTS(param: any) {
        //return this.http.post(ApiService.audioCollectionGroupTTS, param);

        return this.getUrlByOperation('audioCollectionGroupTTS').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    audioCollectionTTS(param: any) {
        //return this.http.post(ApiService.audioCollectionTTS, param);
        return this.getUrlByOperation('audioCollectionTTS').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getIdsInPaymentHistory() {
        //return this.http.get(ApiService.getIdsInPayment);
        return this.getUrlByOperation('getIdsInPayment').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    postSearchPaymentHistory(param: any) {
        //return this.http.post(ApiService.paymentHistorySearch, param);
        return this.getUrlByOperation('paymentHistorySearch').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postFilterPaymentHistoryByDeviceId(param: any) {
        //return this.http.post(ApiService.paymentHistoryByDeviceId, param);
        return this.getUrlByOperation('paymentHistoryByDeviceId').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendCommandDevice(param: any) {
        //return this.http.post(ApiService.sendCommandDevice, param);
        return this.getUrlByOperation('sendCommandDevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendDeviceId(param: any) {
        //return this.http.post(ApiService.sendDeviceId, param);
        return this.getUrlByOperation('sendDeviceId').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postPaymentHistory(param: any) {
        //return this.http.post(ApiService.paymentHistory, param);
        return this.getUrlByOperation('paymentHistory').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendAmount(param: any) {
        //return this.http.post(ApiService.sendAmountToDevice, param);
        return this.getUrlByOperation('sendAmountToDevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSoundBoxDemo(param: any) {
        //return this.http.post(ApiService.soundBoxDemo, param);
        return this.getUrlByOperation('soundBoxPaymentDemo').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postActivateMerchant(param: any) {
        //return this.http.post(ApiService.activateMerchant, param);
        return this.getUrlByOperation('activateMerchant').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postEnableDisableMerchantId(param: any) {
        //return this.http.post(ApiService.enableDisableByMerchantId, param);
        return this.getUrlByOperation('enableDisableMerchantOrPos').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postEnableDisableDeviceId(param: any) {
        //return this.http.post(ApiService.enableDisableBydeviceid, param);
        return this.getUrlByOperation('enableDisableDeviceId').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postRegisterAPI(param: any) {
        //return this.http.post(ApiService.register, param);
        return this.getUrlByOperation('register').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postLoginAPI(param: any) {
        //return this.http.post(ApiService.login, param);
        return this.getUrlByOperation('login').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postAddDeviceAPI(param: any) {
        //return this.http.post(ApiService.addDevice, param);
        return this.getUrlByOperation('adddevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postAddGroupAPI(param: any) {
        //return this.http.post(ApiService.addGroup, param);
        return this.getUrlByOperation('addgroup').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getShowGroupAPI() {
        //return this.http.get(ApiService.showGroups);
        return this.getUrlByOperation('showgroups').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    getModelListAPI() {
        //return this.http.get(ApiService.modellist);
        return this.getUrlByOperation('modellist').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    getShowDevicesAPI() {
        //return this.http.get(ApiService.showDevices);
        return this.getUrlByOperation('showdevices').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    postShowDevicesAPI(param: any) {
        //return this.http.post(ApiService.showDevicePagination, param);
        return this.getUrlByOperation('showDevicePagination').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDeviceDetailsById(param: any) {
        //return this.http.post(ApiService.deviceDetailsById, param);
        return this.getUrlByOperation('deviceDetailsById').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSearchDevicesAPi(param: any) {
        //return this.http.post(ApiService.searchDevices, param);
        return this.getUrlByOperation('searchDevices').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDownloadDeviceListData(param: any): Observable<Blob> {
        // return this.http.post(ApiService.downloadDeviceData, param, {
        //     responseType: 'blob'
        // });

        return this.getUrlByOperation('downloadDeviceData').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {
                    responseType: 'blob'
                });
            })
        );
    }

    postEditGroupNameAPI(param: any) {
        //return this.http.post(ApiService.editGroupName, param);
        return this.getUrlByOperation('editgroupname').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postEditDeviceAPI(param: any) {
        //return this.http.post(ApiService.editDevice, param);
        return this.getUrlByOperation('editdevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDleteDeviceAPI(param: any) {
        //return this.http.post(ApiService.deleteDevice, param);
        return this.getUrlByOperation('deletedevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDeleteGroupAPI(param: any) {
        //return this.http.post(ApiService.deleteGroup, param);
        return this.getUrlByOperation('deletegroup').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDeviceStatusActiveDeactive(param: any) {
        //return this.http.post(ApiService.deviceStatusActiveDeactive, param);
        return this.getUrlByOperation('devicestatusactivedeactive').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postGenerateKeyAPI(param: any) {
        //return this.http.post(ApiService.generateKey, param);
        return this.getUrlByOperation('generatekey').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getShowAPIKey() {
        //return this.http.get(ApiService.showAPIKey);
        return this.getUrlByOperation('showapikey').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    postEditAPIKey(param: any) {
        //return this.http.post(ApiService.editAPIKey, param);
        return this.getUrlByOperation('editapikey').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDeleteAPIKey(param: any) {
        //return this.http.post(ApiService.deleteAPIKey, param);
        return this.getUrlByOperation('deleteAPIkey').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDevDocumentTestAPI(param: any, param1: any) {
        //return this.http.post(ApiService.devDocumentTestAPI, param);
        return this.getUrlByOperation('devdocumenttestapi').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postTTSAPI(param: any) {
        //return this.http.post(ApiService.tts, param);
        return this.getUrlByOperation('tts').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postShowCommandsAPI(param: any) {
        //return this.http.post(ApiService.showCommands, param);
        return this.getUrlByOperation('showcommands').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postCommandJSONPayload(param: any) {
        //return this.http.post(ApiService.commandJSONPayload, param);
        return this.getUrlByOperation('commandjsonpayload').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getShowLogAPI(param: any) {
        //return this.http.post(ApiService.showLog, param);
        return this.getUrlByOperation('showlog').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSearchLogsAPI(param: any) {
        //return this.http.post(ApiService.searchLog, param);
        return this.getUrlByOperation('searchlog').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    downloadLogsAPI(param: any): Observable<Blob> {
        // return this.http.post(ApiService.downlaodLogs, param, {
        //     responseType: 'blob'
        // });

        return this.getUrlByOperation('downloadlog').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {
                    responseType: 'blob'
                });
            })
        );
    }

    postShowTerminalLog(param: any) {
        //return this.http.post(ApiService.showTerminalLogs, param);
        return this.getUrlByOperation('showterminallog').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSearchTerminalLog(param: any) {
        //return this.http.post(ApiService.searchTerminalLog, param);
        return this.getUrlByOperation('searchterminallog').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    downloadTerminalLogs(param: any): Observable<Blob> {
        // return this.http.post(ApiService.downloadTerminalLogs, param, {
        //     responseType: 'blob'
        // });

        return this.getUrlByOperation('downloadterminallogs').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {
                    responseType: 'blob'
                });
            })
        );
    }

    postDeviceStatusCount(param: any) {
        //return this.http.post(ApiService.deviceStatusCount, param);
        //console.log(this.getUrlByOperation('deviceStatusCount'));

        return this.getUrlByOperation('devicestatuscount').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postGetActiveDeviceList(param: any) {
        //return this.http.post(ApiService.activeDeviceList, param);
        return this.getUrlByOperation('getactivedevicelist').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postGetDeActiveDeviceList(param: any) {
        //return this.http.post(ApiService.deActiveDeviceList, param);
        return this.getUrlByOperation('getdeactivedevicelist').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendGroupDevice(param: any) {
        //return this.http.post(ApiService.sendGroupdevice, param);
        return this.getUrlByOperation('sendgroupdevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postAddTerminalLog(param: any) {
        //return this.http.post(ApiService.addTerminalLog, param);
        return this.getUrlByOperation('addterminallog').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getSummaryReport() {
        //return this.http.get(ApiService.getSummaryReport);
        return this.getUrlByOperation('summaryreport').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    // uploadExcelFile(param:any){
    //   return this.http.post(ApiService.uploadExcelFile,param);
    // }

    uploadExcelFile(formData: FormData): Observable<any> {
        //return this.http.post(ApiService.uploadExcelFile, formData);
        return this.getUrlByOperation('uploadexcelfile').pipe(
            switchMap((url) => {
                return this.http.post(url, formData);
            })
        );
    }

    uploadAudioFileCollection(formData: FormData): Observable<any> {
        //return this.http.post(ApiService.uploadAudioFileCollection, formData);
        return this.getUrlByOperation('uploadaudiofile').pipe(
            switchMap((url) => {
                return this.http.post(url, formData);
            })
        );
    }

    showAudioFilesCollection() {
        //return this.http.get(ApiService.showAudioFilesCollection);
        return this.getUrlByOperation('showaudiofiles').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    deleteAudioFilesCollection(param: any) {
        //return this.http.post(ApiService.deleteAudioFilesCollection, param);
        return this.getUrlByOperation('deleteaudiofiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    sendToDeviceAudioCollectionAPI(param: any) {
        //return this.http.post(ApiService.sendToDeviceAudioCollection, param);
        return this.getUrlByOperation('sendtodeviceaudiocollection').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    sendToGroupAudioCollectionAPI(param: any) {
        //return this.http.post(ApiService.sendToGroupAudioCollection, param);
        return this.getUrlByOperation('sendtogroupvoiceaudiocollection').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    uploadAudioTTSFileCollection(param: any) {
        //return this.http.post(ApiService.uploadAudioTTSFilesCollection, param);
        return this.getUrlByOperation('uploadttsfiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    showAudioTTSFilesCollection() {
        //return this.http.get(ApiService.showAudioTTSFilesCollection);
        return this.getUrlByOperation('showttsfiles').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    deleteTTSAudioFilesCollection(param: any) {
        //return this.http.post(ApiService.deleteTTSAUdioFilesCollection, param);
        return this.getUrlByOperation('deletettsaudiofiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getAudioFileCodesAPI() {
        //return this.http.get(ApiService.getAudioFilesCodes);
        return this.getUrlByOperation('audiofilecodes').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    postSendToDeviceAudio(param: any) {
        //return this.http.post(ApiService.sendToDeviceAudio, param);
        return this.getUrlByOperation('sendtodeviceaudio').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendToGroupAudio(param: any) {
        //return this.http.post(ApiService.sendToGroupAudio, param);
        return this.getUrlByOperation('sendtogroupaudio').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSummaryFromDevieModel(param: any) {
        //return this.http.post(ApiService.summaryFromDevieModel, param);
        return this.getUrlByOperation('summaryfromdeviemodel').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postPrinter(param: any) {
        //return this.http.post(ApiService.printer, param);
        return this.getUrlByOperation('printer').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postTestAmount(param: any) {
        //return this.http.post(ApiService.testAmount, param);
        return this.getUrlByOperation('testamount').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postTestAmountWithMerchantId(param: any) {
        //return this.http.post(ApiService.publishAmountMerchantPos, param);
        return this.getUrlByOperation('publishAmountMerchantPos').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postcreateWavFileDeviceName(param: any) {
        //return this.http.post(ApiService.createWavFileDeviceName, param);
        return this.getUrlByOperation('createWavFileDeviceName').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    posttestDeviceName(param: any) {
        //return this.http.post(ApiService.createWavFileDeviceName, param);
        return this.getUrlByOperation('testDeviceName').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postPubllishAmountId(param: any) {
        return this.getUrlByOperation('amountDeviceId').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postAddStaff(param: any) {
        return this.getUrlByOperation('addStaff').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getShowStaff() {
        return this.getUrlByOperation('showStaff').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    postEditStaff(param: any) {
        return this.getUrlByOperation('editStaff').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDeleteStaff(param: any) {
        return this.getUrlByOperation('deleteStaff').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postChangeStaffPassword(param: any) {
        return this.getUrlByOperation('changeStaffPassword').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postUpdateStaffStatus(param: any) {
        return this.getUrlByOperation('updateStaffStatus').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    // language pack apis

    postAddLanguagePack(param: any) {
        return this.getUrlByOperation('languagePackAdd').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    GetLanguagePack() {
        return this.getUrlByOperation('languagePackGet').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    deleteLanguagePack(param: any) {
        return this.getUrlByOperation('languagePackDelete').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    uploadZipFile(formData: FormData): Observable<any> {
        return this.getUrlByOperation('uploadZipFile').pipe(
            switchMap((url) => {
                return this.http.post(url, formData);
            })
        );
    }

    viewZipFiles(param: any) {
        return this.getUrlByOperation('viewZipFiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    deleteZipFiles(param: any) {
        return this.getUrlByOperation('deleteZipFiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    downloadZipFiles(param: any): Observable<HttpResponse<Blob>> {
        return this.getUrlByOperation('downloadZipFiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {
                    responseType: 'blob',
                    observe: 'response'
                });
            })
        );
    }

    updateLanguageStatusActive(param: any) {
        return this.getUrlByOperation('updateLanguageStatus').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    updateDefaultLanguageStatusActive(param: any) {
        return this.getUrlByOperation('updateDefaultLanguageStatus').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    deleteSingleFiles(param: any) {
        return this.getUrlByOperation('deleteSingleFiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    downloadSingleFiles(param: any): Observable<HttpResponse<Blob>> {
        return this.getUrlByOperation('downloadSingleFiles').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {
                    responseType: 'blob',
                    observe: 'response'
                });
            })
        );
    }

    // playAudioFile(param: any) {
    //     return this.getUrlByOperation('playAudioFile').pipe(
    //         switchMap((url) => {
    //             return this.http.post(url, param);
    //         })
    //     );
    // }

    playAudioFile(data: any) {
        const options = {
            responseType: 'blob' as 'json', // Expecting binary data
            observe: 'response' as 'body' // To get headers
        };

        return this.getUrlByOperation('playAudioFile').pipe(
            switchMap((url) => {
                return this.http.post(url, data, options);
            })
        );

        //return this.http.post('/your-backend-endpoint', data, options);
    }

    uploadSingleFile(formData: FormData): Observable<any> {
        return this.getUrlByOperation('addSingleFile').pipe(
            switchMap((url) => {
                return this.http.post(url, formData);
            })
        );
    }

    // language pack apis to show and curl command

    postLPApisActDefLanguage(param: any) {
        return this.getUrlByOperation('LPApisActDefLanguage').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postLPApisActiveLanguage(param: any) {
        return this.getUrlByOperation('LPApisActiveLanguage').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }
}
