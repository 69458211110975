import { IsAuthService } from '@/service/is-auth.service';
import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user:any;

    constructor(private appService: AppService,private isAuth:IsAuthService) {}

    ngOnInit(): void {
        //this.user = "admin@example.com";
        this.user = localStorage.getItem('UserName');
    }

    logout() {
        this.appService.logout();
        this.isAuth.logout();
    }

    formatDate(date:any) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
