import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grouplist',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
  ],
  templateUrl: './grouplist.component.html',
  styleUrl: './grouplist.component.scss'
})
export class GrouplistComponent implements OnInit{
 

  //private modalService = inject(NgbModal);

  constructor(private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService){}

  ngOnInit(): void {
    this.getGroups();
  }

  displayedDeviceData:any;

  filterFromTable(input: any) {
    //console.log(input);
  
    if (!input) {
      // If there's no input, reset to the original dataset
      this.displayedDeviceData = [...this.data];
    } else {
      const res = this.data.filter(device => 
        Object.values(device).some(prop => 
          prop !== null && prop !== undefined && prop.toString().toLowerCase().includes(input.toLowerCase())
        )
      );
      this.displayedDeviceData = res;
    }
  }

  sortData(sortField: string, sortDirection: string) {
    this.displayedDeviceData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  capitalizeAndReplace(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

  prop: any;
  data: any;
  total:any;
  isLoading = true;
  isLoadingEdit= false;

  getGroups(){
    this.service.getShowGroupAPI().subscribe((data:any)=>{
      //console.log("data",data);

      if (data['status'] == true) {
        //console.log("here");

        this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total') {
                this.prop.push(property);
              }
              //console.log(this.prop);
              

            }
          } 
        
        this.data = data['data'];
        this.displayedDeviceData = [...this.data];
        this.total = data['total_groups'];
        this.isLoading = false;
      } else {
        this.data = null;
        this.toast.error("No Groups Found");
        this.isLoading = false
      }

      
    });
  }
  editGroupNameForm = new FormGroup({
    editGroupName: new FormControl('',[Validators.required])
  })

  get editGroupName(): FormControl {
    return this.editGroupNameForm.get('editGroupName') as FormControl;
  }

  groupEditId:any;
  groupEditName:any;

  openEditUpdateGroupForm(editUpdateGroupForm:TemplateRef<any>,editId:any,groupName:any){
    //console.log("edit id",editId);
    this.isLoadingEdit = true;
    this.groupEditId = editId;
    this.groupEditName = groupName;
    try {
      this.service.getShowGroupAPI().subscribe((data:any)=>{
        //console.log("data",data);
        if(data['status'] == true){
          this.isLoadingEdit = false;
          if (data['data'] != null) {
            if (data['data'].length > 0) {
              this.editGroupNameForm.controls.editGroupName.setValue(groupName);
            } else {
              this.toast.error('No Data Found')
            }
          } else {
            this.toast.error('No Data Found')
          }
  
        }
        this.isLoading = false;
      });
    } catch (error:any) {
      this.toast.error(error);
    }

    this.modalService.open(editUpdateGroupForm, { ariaLabelledBy: `editUpdateGroup_${editId}` }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }


  editGroupNameSubmit(){
    //console.log("edit",this.editGroupNameForm.value);
    try {
      let editGroupNameSendData = {
        "group_id": this.groupEditId,
        "group_name":this.editGroupNameForm.value.editGroupName
      }

      //console.log(editGroupNameSendData);
      

      this.service.postEditGroupNameAPI(editGroupNameSendData).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("heer");
          
          this.toast.success(data['message']);
          this.ngOnInit();
        }else{
          this.toast.error("Not Updated");
          this.ngOnInit();
        }
        
      })

    } catch (error:any) {
      this.toast.error(error);
    }
  }


  addGroupNameForm = new FormGroup({
    groupName: new FormControl('', [Validators.required]),
  });

  get groupName(): FormControl {
    return this.addGroupNameForm.get('groupName') as FormControl;
  }

  addGroupName(){
    //console.log(this.addGroupNameForm.value);

    let addGroupSendData = {
      "group_name": this.addGroupNameForm.value.groupName,
    };

    //console.log(addGroupSendData);

    this.service.postAddGroupAPI(addGroupSendData).subscribe((data:any)=>{
      //console.log(data);
      if (data['status'] == true) {
        this.toast.success(data['message'])
        this.ngOnInit();
      }else{
        this.toast.error(data['message']);
        this.ngOnInit()
      }
    })

    
  }

  closeResult:any;

  open(content: TemplateRef<any>) {

    //console.log("open add group");
    

		this.modalService.open(content, { ariaLabelledBy: 'addGroupBtn' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}


  deleteGroup(deleteId:any){
    //console.log(deleteId);
    try {
      let sendDelId = {
        "group_id":deleteId
      }

      this.service.postDeleteGroupAPI(sendDelId).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("heer");
          
          this.toast.success(data['message']);
          this.ngOnInit();
        }else{
          this.toast.error(data['message']);
          this.ngOnInit();
        }
      })

    } catch (error) {
      console.log(error);
      
    }
    
  }


}