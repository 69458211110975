<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Enable Disable By Device ID</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">
                        Enable Disable By Device ID
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form
    [formGroup]="sendMerchantDataForm" (ngSubmit)="onSubmit()"
        method="post"
    >
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">
                        Enable Disable By Device Id
                    </h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label
                                for="ip_key"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900"
                                >Api Key</label
                            >
                        </div>
                        <div class="w-2/3">
                            <input
                                type="text"
                                name="ip_key"
                                id="ip_key"
                                formControlName="ip_key"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key"
                            />
                            <!-- <div
                                class="form-text text-danger"
                                *ngIf="
                                    ip_key.errors?.['required'] &&
                                    (ip_key.touched || ip_key.dirty)
                                "
                            >
                                API Key is required
                            </div> -->
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label
                                for="device_id"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900"
                                >Device Id</label
                            >
                        </div>
                        <div class="w-2/3">
                            <input
                                type="text"
                                name="device_id"
                                id="device_id"
                                formControlName="device_id"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device ID"
                            />
                            <!-- <div
                                class="form-text text-danger"
                                *ngIf="
                                    device_id.errors?.['required'] &&
                                    (device_id.touched || device_id.dirty)
                                "
                            >
                                Merchant ID is required
                            </div> -->
                        </div>
                    </div>

                   

                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label
                                for="enable"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900"
                                >Enable / Disable</label
                            >
                        </div>
                        <div class="w-2/3">
                            <mat-form-field class="w-96">
                                <mat-label>Select an option</mat-label>
                                <mat-select formControlName="activationStatus">
                                    <mat-option value="Enable"
                                        >Enable</mat-option
                                    >
                                    <mat-option value="Disable"
                                        >Disable</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label
                                for="selectJSON"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900"
                            ></label>
                        </div>
                        <div class="w-2/3">
                            <button
                                type="submit"
                                class="btn btn-primary"
                            >
                                Enable/Disable By Device ID
                            </button>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <button
                    class="rounded-md bg-blue-500 w-32 px-3 py-2 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    (click)="copyText()"
                >
                    Copy
                </button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL1}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
