<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Test Device API</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">
                        Test Device API
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="testAPIForm" (ngSubmit)="onSubmit()" method="post">
        <div class="container-fluid">
            <div class="card">

                <div class="card-header">


                    <label class="btn" [ngClass]="{'btn-success': method === 'GET', 'btn-danger': method !== 'GET'}">
                        <input type="radio" name="options" id="getBtn" value="GET" autocomplete="off" (click)="changeRequestMethod('GET')"> GET
                      </label>&nbsp; &nbsp;
                      <label class="btn" [ngClass]="{'btn-success': method === 'POST', 'btn-danger': method !== 'POST'}">
                        <input type="radio" name="options" id="postBtn" value="POST" autocomplete="off" checked (click)="changeRequestMethod('POST')"> POST
                      </label>   

                </div>

                <div class="card-body">

                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="ip_key" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Power On Off</label>
                        </div>
                        <div class="w-2/3">
                            <mat-form-field class="w-96">
                                <mat-label>Select an option</mat-label>
                          <mat-select [value]="selectedPowerOnOff" (selectionChange)="chnagePowerOnOffDevice($event.value)">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let datas1 of showPowerOnOff" [value]="datas1['power']">{{datas1['power']}}</mat-option>
                          </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="globalUserKey" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Global API Key/Customer User API Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="globalUserKey" id="globalUserKey" formControlName="globalUserKey"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Global User Key" />
                        </div>
                    </div>


                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="deviceID" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device ID</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="deviceID" id="deviceID" formControlName="deviceID"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device ID" />
                        </div>
                    </div>

                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="mqttSettingJson" class="ml-10 block text-lg font-medium leading-6 text-gray-900">MQTT Setting JSON</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="mqttSettingJson" id="mqttSettingJson" formControlName="mqttSettingJson"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter MQTT Settings" />
                        </div>
                    </div>

                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="deviceDateTime" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device Date-Time</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="deviceDateTime" id="deviceDateTime" formControlName="deviceDateTime"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                        </div>
                    </div>


                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="localIPAddress" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Local IP Address</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="localIPAddress" id="localIPAddress" formControlName="localIPAddress"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Local IP Address" />
                        </div>
                    </div>


                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="wifiSSID" class="ml-10 block text-lg font-medium leading-6 text-gray-900">WI-FI SSID</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="wifiSSID" id="wifiSSID" formControlName="wifiSSID"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter WI-FI SSID" />
                        </div>
                    </div>

                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="wifiPassword" class="ml-10 block text-lg font-medium leading-6 text-gray-900">WI-Fi Password</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="wifiPassword" id="wifiPassword" formControlName="wifiPassword"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter WI-Fi Password" />
                        </div>
                    </div>


                    <div class="flex mb-10 mt-10">
                        <div class="w-1/3 mr-4">
                            <label for="remark" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Remark</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="remark" id="remark" formControlName="remark"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Remark" />
                        </div>
                    </div>

                    

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="send" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="rounded-md bg-blue-500 w-96 px-3 py-2 text-2xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                SEND
                            </button>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->

<!-- Main content -->
<section class="content">
    <div class="header d-flex align-items-center justify-content-between" style="margin-top: 20px;">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="mb-0">cURL Command For <span class="method">{{method}}</span> Method</h4>
        <button type="button" style="margin-bottom: 10px;" class="btn btn-secondary" (click)="copyToClipboard('copyable-content')">Copy cURL</button>
      </div>
    </div>
    
    <section id="extended" *ngIf="method === 'POST' ">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <form>
              
                <div class="card-body" id="copyable-content" ngNonBindable>
                  <pre style="background-color: #000000; padding: 15px; border-radius: 5px; white-space: pre-wrap; word-wrap: break-word; width: 100%; color: #fafafa;">
                    curl --location 'http://91.107.200.91:6501/admin/add_terminal_log' \
                    --header 'token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6MSwiZXhwIjoxNjg4NTY0OTQ0fQ.BqIusU1EO5u-9jhYThpukBpqlpGmhawnX4Tv139w_sc' \
                    --header 'Content-Type: application/json' \
                    --data '{{
                        "global_user_key": "KQ6zddtsW4sqbCa9X5ECZknKYmjtMMea",
                        "device_id": "zvSSEhaVqqPv6DqPZ355fiz21FJuPXPJ",
                        "event": "power_off",
                        "device_model": "virtualsoundbox",
                        "device_date_time": 1688528016,
                        "wifi_SSID": "easo_jio",
                        "wifi_password": "978445dcd",
                        "mqtt_setting_json": {
                            "mqtt_broker": "bonrix.co.in",
                            "mqtt_port": "3012",
                            "mqtt_username": "bonrix",
                            "mqtt_password": "bon2334"
                        },
                        "local_ip_address": "102.312.21.101",
                        "remarks": "not any"
                    }}'
                  </pre>
                </div>
            
            </form>
          </div>
        </div>
      </div>
    </section>


    <section id="extended" *ngIf="method === 'GET' ">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <form>
                
                  <div class="card-body" id="copyable-content" >
                    <pre style="background-color: #000000; padding: 15px; border-radius: 5px; white-space: pre-wrap; word-wrap: break-word; width: 100%; color: #fafafa;">
                      {{getJson}}
                    </pre>
                  </div>
              
              </form>
            </div>
          </div>
        </div>
      </section>




  </section>