import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { sleep } from '@/utils/helpers';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    public isAuthLoading = false;

    constructor(private router: Router, private toastr: ToastrService,
      private apiService:ApiService) {}

    async loginByAuth({email, password}) {
        try {
          //console.log('email',email)
            // await authLogin(email, password);
            //await this.getProfile();
            // this.router.navigate(['/']);
            // this.toastr.success('Login success');

            this.isAuthLoading = true;
            //await this.appService.loginByAuth(this.loginForm.value);
            // this.isAuthLoading = false;

            let loginFormDataSend = {
                password: password,
                email: email
              };
              //console.log(this.loginForm.value);
              let uName:any = email
              this.apiService.postLoginAPI(loginFormDataSend).subscribe({
                next: (data: any) => {
                  //console.log(data);
                      if (data.status === true) {
                        this.isAuthLoading = false;
                        localStorage.setItem('Token', data['token']);
                        //localStorage.setItem('LoginId', data['LoginId']);
                        localStorage.setItem('UserName',uName);
                        //console.log(localStorage.getItem('Token'));
                        this.router.navigate(['/']);
                        this.toastr.success(data['message']);
                        
                      } else {
                          this.toastr.error(data.message);
                      }
                
                    //this.ngOnInit(); // Consider the necessity of reinitializing component
                },
                error: (error: any) => {
                  //console.log(error);
                  
                    // Handle HTTP error status
                    if (error && error.status) {
                        this.isAuthLoading = false;
                        // You can use a switch case to handle various error statuses
                        switch (error.status) {
                            case 400:
                                this.toastr.error("Bad Request: " + error.error.message);
                                break;
                            case 401:
                                this.toastr.error("Unauthorized: " + error.error.message);
                                break;
                            case 403:
                                this.toastr.error("Forbidden: " + error.error.message);
                                break;
                            case 404:
                                this.toastr.error("Not Found: " + error.error.message);
                                break;
                            case 500:
                                this.toastr.error("Internal Server Error: Please try again later.");
                                break;
                            default:
                                this.toastr.error(error.error.message);
                        }
                    } else {
                        // Handle case where error status is not available
                        this.toastr.error("An unexpected error occurred. Please try again.");
                    }
                }
            });


        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
          // await authLogin(email, password);
          // await this.getProfile();
          // this.router.navigate(['/']);
          // this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
          this.toastr.warning('Not implemented');

        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
          this.toastr.warning('Not implemented');

        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
          this.toastr.warning('Not implemented');

        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {

            this.toastr.warning('Not implemented');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async getProfile() {
        try {
            const user = await getAuthStatus();
            if(user) {
              this.user = user;
            } else {
              this.logout();
            }
          } catch (error) {
          this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }
}


export const authLogin = (email: string, password: string) => {
  return new Promise(async (res, rej) => {
    await sleep(500);
    if (email === 'admin@example.com' && password === 'admin') {
      localStorage.setItem(
        'authentication',
        JSON.stringify({ profile: { email: 'admin@example.com' } })
      );
      return res({ profile: { email: 'admin@example.com' } });
    }
    return rej({ message: 'Credentials are wrong!' });
  });
};

export const getAuthStatus = () => {
  return new Promise(async (res, rej) => {
    await sleep(500);
    try {
      let authentication = localStorage.getItem('authentication');
      if (authentication) {
        authentication = JSON.parse(authentication);
        //console.log(authentication);
        
        return res(authentication);
      }
      return res(undefined);
    } catch (error) {
      return res(undefined);
    }
  });
};
