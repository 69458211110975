import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-printertest',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './printertest.component.html',
    styleUrl: './printertest.component.scss'
})
export class PrintertestComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {}

    userName = localStorage.getItem('UserName');

    printerTestForm = new FormGroup({
        apiKey: new FormControl('', [Validators.required]),
        deviceId: new FormControl('', [Validators.required]),
        jsonPayLoad: new FormControl('', [Validators.required]),
        noOfPrints: new FormControl('', [Validators.required])
    });

    get apiKey(): FormControl {
        return this.printerTestForm.get('apiKey') as FormControl;
    }

    get deviceId(): FormControl {
        return this.printerTestForm.get('deviceId') as FormControl;
    }

    get jsonPayLoad(): FormControl {
        return this.printerTestForm.get('jsonPayLoad') as FormControl;
    }

    get noOfPrints(): FormControl {
        return this.printerTestForm.get('noOfPrints') as FormControl;
    }

    sendData() {
        //console.log(this.printerTestForm.value);
        try {
            let sendPrinterData = {
                ip_key: this.printerTestForm.value.apiKey,
                device_id: this.printerTestForm.value.deviceId,
                text: this.printerTestForm.value.jsonPayLoad,
                print_num: this.printerTestForm.value.noOfPrints
            };

            //console.log(sendPrinterData);

            this.service.postPrinter(sendPrinterData).subscribe((data: any) => {
                //console.log(data);

                if (data['status'] == true) {
                    this.toast.success(data['message']);
                    this.printerTestForm.reset();
                } else {
                    this.toast.error(data['message']);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    getcURL1 = `curl --location --request POST 'http://91.107.200.91:6500/print_test' \
  --header 'Content-Type: application/json' \
  --header 'Cookie: session=eyJlbWFpbCI6Ik1lZXQgTWVodGEifQ.ZEYt0g.lqR-F8rJui-6HnWHrhyIBxCdtRs' \
  --data-raw '{
    "ip_key": "0rtuFwE9ExWRtrLcJZFH4ICGIEExYPaB",
    "device_id": "abcdef",
    "print_num" : "1",
    "text": "{\"merchantld\": \"898d1O0a4aKn\", \"Terminal No\": \"34567894233\", \"Amount\": \"20.0\", \"Discount amount\": \"0.0\", \"Actually paid\": \"0.0\", \"Trade time\": \"2023-09-27 1116\", \"Payment channel\": \"wechat\", \"Transaction Type\": \"SUCCESS\", \"Order No\": \"U1 SWEB202009271114\"}"
  }'`;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    logout() {
        this.isAuth.logout();
    }
}
