import { IsAuthService } from '@/service/is-auth.service';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-terminallog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatPaginatorModule
  ],
  templateUrl: './terminallog.component.html',
  styleUrl: './terminallog.component.scss'
})
export class TerminallogComponent implements OnInit {

  filteredSuccessFail$:any;
  successFailFilterCtrl: FormControl = new FormControl();

  prop: any;
  data: any;
  total: any;


  deviceProp:any;
  deviceData:any;
  devicetotal:any;

  isLoading = true; // Add this line to show the loader at the beginning

  constructor(
    private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private router: Router,
    private isAuth: IsAuthService,
    private http:HttpClient,
    private renderer: Renderer2
  ){
    this.pageEvent = new PageEvent();

    this.filteredSuccessFail$ = this.successFailFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString))
      );
  }

  length :any; // Replace with your total data count from API
  page = 1;
  pageSize = 10; // Replace with your default page size
  pageSizeOptions: number[] = [2,5, 10, 25, 50 ,100]; // Or your page size options
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnInit(): void {
    this.getModelList();
    this.showTerminalLog();
  }

  displayedLogData:any

  filterFromTable(input: any) {
    //console.log(input);
  
    if (!input) {
      // If there's no input, reset to the original dataset
      this.displayedLogData = [...this.data];
    } else {
      const res = this.data.filter(device => 
        Object.values(device).some(prop => 
          prop !== null && prop !== undefined && prop.toString().toLowerCase().includes(input.toLowerCase())
        )
      );
      this.displayedLogData = res;
    }
  }
  
  sortData(sortField: string, sortDirection: string) {
    this.displayedLogData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  capitalizeAndReplace(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

  getModelList(){
    try {
      this.service.getModelListAPI().subscribe((data1: any) => {
        //console.log('Model list', data1);
  
        if (data1['status'] == true) {
          //console.log("here");
  
          this.deviceProp = [];
          for (var propertyDevice in data1['data'][0]) {
            if (data1['data'][0].hasOwnProperty(propertyDevice)) {
              //console.log("PROPERTY",property);
              if (propertyDevice.toLowerCase() != 'total') {
                this.deviceProp.push(propertyDevice);
              }
              //console.log("prop",this.deviceProp);
            }
          }
          this.deviceData = data1['data'];
          this.devicetotal = data1['total_models'];
          this.isLoading = false;
          //console.log("data",this.data);
        } else {
          this.deviceData = null;
          this.toast.error("Data Not Found");
          this.isLoading = false;
        }
      });
    } catch (error) {
      console.log(error);
      
    }
  }


  showTerminalLog(){
    try {
      let sendData = {
        "page": this.page,
        "per_page": this.pageSize
      }
    
      //console.log("send data ng on init",sendData);
      
  
        this.service.postShowTerminalLog(sendData).subscribe((data:any)=>{
        //console.log("Request Log API Response",data);
        if (data['status'] == true) {
          //console.log("here");
  
          this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.prop.push(property);
              }
              //console.log(this.prop);
            }
          }
  
          this.rearrangeArray();
  
          this.data = data['data'];
          this.displayedLogData = [...this.data];
          this.length = data['total_entries'];
          this.total = data['total_entries'];
        } else {
          this.data = null;
          this.toast.error('No Data Found');
        }
      })
    } catch (error) {
      console.log(error);
      
    }
  }


  userName = localStorage.getItem('UserName');

  pageIndex: number = 0;

  handlePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    //console.log(event.pageSize); // This will give you the current items per page
    this.loadData(event.pageIndex, event.pageSize);
  }

  loadData(pageIndex: number, pageSize: number) {
    // Call your API here with pageIndex and pageSize as parameters
    if(pageIndex == 0){
      pageIndex = 1;
    }else{
      pageIndex++;
    }

    let sendData = {
      "device_id": this.searchFormData.value.deviceId,
      "global_user_key": this.searchFormData.value.globalUserKey,
      "device_model": this.selectedModelName,
      "event": this.selectedEventType,
      "wifi_SSID": this.searchFormData.value.wiFiSSID,
      "local_ip_address": this.searchFormData.value.localIp,
      "global_ip_address": this.searchFormData.value.globalIp,
      "from_date" : this.applyDateCheckedFromDate,
      "to_date" : this.applyDateCheckedToDate,
      "page" :pageIndex,
      "per_page" : pageSize
    }

    // console.log("device id",typeof(sendData['device_id']),
    //           "global user key",typeof(sendData['global_user_key']),
    //           "device model",typeof(sendData['device_model']),
    //           "event",typeof(sendData['event']),
    //           "page",typeof(sendData['page']),
    //           "per page",typeof(sendData['per_page']),
    //           "wifi ssid",typeof(sendData['wifi_SSID']),
    //           "local ip aqdd",typeof(sendData['local_ip_address']),
    //           "global ip addd",typeof(sendData['global_ip_address']),
    //           "from date",typeof(sendData['from_date'])),
    //           "to date",typeof(sendData['to_date'])

    

    if(sendData['device_id'] != '' || sendData['global_user_key'] != '' || sendData['device_model'] != undefined || sendData['event'] != undefined || sendData['wifi_SSID'] != '' || sendData['local_ip_address'] != '' || sendData['global_ip_address'] != '' || sendData['from_date'] != undefined || sendData['to_date'] != undefined){
      //console.log("in if");
      //console.log("Search send data",sendData);

      this.service.postSearchTerminalLog(sendData).subscribe((data:any)=>{
        //console.log("search Response",data);
        if (data['status'] == true) {
          //console.log("here");
  
          this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.prop.push(property);
              }
              //console.log(this.prop);
            }
          }
  
          //this.rearrangeArray();
  
          this.data = data['data'];
          this.displayedLogData = [...this.data];
          this.length = data['total_entries'];
          this.total = data['total_entries'];
        } else {
          this.data = null;
          this.toast.error('No Data Found');
        }
      })


    }else{
      //console.log("in else");

        this.service.postShowTerminalLog(sendData).subscribe((data:any)=>{
        //console.log(data);
        if (data['status'] == true) {
          //console.log("here");

          this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.prop.push(property);
              }
              //console.log(this.prop);
            }
          }

          this.rearrangeArray();

          this.data = data['data'];
          this.displayedLogData = [...this.data];
          this.total = data['total_entries'];
        } else {
          this.data = null;
          this.toast.error('No Data Found');
        }
      })
      
    }
    

    
  }


  rearrangeArray() {

    const dateIdx = this.prop.indexOf('id');
    if (dateIdx > -1) {
      const userNameElement = this.prop.splice(dateIdx, 1)[0];
      this.prop.unshift(userNameElement);
    }
}




  displaySearchForm = false;

  toggleSearchForm(){
    this.displaySearchForm = !this.displaySearchForm;
  }

  //fromDate = new Date().toISOString().split("T")[0];  // New variable
  //toDate = new Date().toISOString().split("T")[0];  // New variable

  searchFormData = new FormGroup({
    fromDate : new FormControl('',[Validators.required]),
    toDate:  new FormControl('',[Validators.required]),
    deviceId: new FormControl('',[Validators.required]),
    globalUserKey : new FormControl('',[Validators.required]),
    wiFiSSID : new FormControl('',[Validators.required]),
    localIp : new FormControl('',[Validators.required]),
    globalIp : new FormControl('',[Validators.required])
  })

  get deviceId():FormControl {
    return this.searchFormData.get('deviceId') as FormControl;
  }

  get globalUserKey():FormControl {
    return this.searchFormData.get('globalUserKey') as FormControl;
  }

  get wiFiSSID():FormControl {
    return this.searchFormData.get('wiFiSSID') as FormControl;
  }

  get localIp():FormControl {
    return this.searchFormData.get('localIp') as FormControl;
  }

  get globalIp():FormControl {
    return this.searchFormData.get('globalIp') as FormControl;
  }

  showDownloadButtons = false;

  applyDateCheckedFromDate:any ;
  applyDateCheckedToDate:any ;

  onSearch(){
    this.isLoading = true;

    try {

      this.showDownloadButtons = true;
      

      const dateNew = (<HTMLInputElement>document.getElementById("applyDate")).checked;
      //console.log(dateNew);

      if(dateNew === true){
        this.applyDateCheckedFromDate = this.searchFormData.value.fromDate;
        this.applyDateCheckedToDate = this.searchFormData.value.toDate;
      }else{
        this.applyDateCheckedFromDate = '';
        this.applyDateCheckedToDate = ''
      }

      //console.log("check",this.applyDateCheckedFromDate,this.applyDateCheckedToDate);
      
      let sendData = {
        "device_id": this.searchFormData.value.deviceId,
        "global_user_key": this.searchFormData.value.globalUserKey,
        "device_model": this.selectedModelName,
        "event": this.selectedEventType,
        "wifi_SSID": this.searchFormData.value.wiFiSSID,
        "local_ip_address": this.searchFormData.value.localIp,
        "global_ip_address": this.searchFormData.value.globalIp,
        "from_date" : this.applyDateCheckedFromDate,
        "to_date" : this.applyDateCheckedToDate,
        "page" :this.page,
        "per_page" : this.pageSize
      }

      //console.log("Search send data",sendData);

      this.service.postSearchTerminalLog(sendData).subscribe((data:any)=>{
        //console.log("search Response",data);
        if (data['status'] == true) {
          //console.log("here");
  
          this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.prop.push(property);
              }
              //console.log(this.prop);
            }
          }
  
          this.rearrangeArray();
  
          this.data = data['data'];
          this.displayedLogData = [...this.data];
          this.length = data['total_entries'];
          this.total = data['total_entries'];
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.data = null;
          this.toast.error('No Data Found');
        }
      })
      

    } catch (error) {
      console.log(error);
      
    }

  }

  changeColor(event: any) {
    this.renderer.setStyle(event.target, 'background-color', 'green');
    this.renderer.setStyle(event.target, 'border-color', 'green');
  }


  status = 
    {
      "data":[{id:1,status:"success"},{id:2,status:"fail"}]
    }

  selectedSuccessFailType:any;
  showSuccessFailModel:any = this.status['data'];
  changeSuccessFailType(value:any){
    //console.log(value);
    
  }

  selectedModelName:any;

  changeModelName(value:any){
    //console.log(value);
    this.selectedModelName = value;
  }
  eventType = {
    "data":[{id:1,eventType:"POWER ON"},{id:2,eventType:"POWER OFF"},{id:3,eventType:"HEART BEAT"}]
  }
  selectedEventType:any;
  showEventTypeModel:any = this.eventType['data'];

  changeEventType(value:any){
    //console.log(value);
    this.selectedEventType = value;
  }

  filter(filterString: string) {
    return this.showSuccessFailModel ? this.showSuccessFailModel.filter((option:any) =>
      option['model_name'] && option['model_name'].toLowerCase().includes(filterString.toLowerCase())
    ) : [];
  }


  onDownloadPDF() {
    // Implement your PDF download functionality here.
    //console.log('Download PDF button clicked!');

    try {

      let buttonPDF = document.getElementById('downloadPDFButton');
      if (buttonPDF) {
        buttonPDF.style.borderColor = 'green';
        buttonPDF.style.backgroundColor = 'green';
        buttonPDF.style.color = 'white';

      }

      let sendData = {
        "device_id": this.searchFormData.value.deviceId,
        "global_user_key": this.searchFormData.value.globalUserKey,
        "device_model": this.selectedModelName,
        "event": this.selectedEventType,
        "wifi_SSID": this.searchFormData.value.wiFiSSID,
        "local_ip_address":this.searchFormData.value.localIp,
        "global_ip_address": this.searchFormData.value.globalIp,
        "from_date" :  this.applyDateCheckedFromDate,
        "to_date" : this.applyDateCheckedToDate,
        "download_type" : "pdf" 
      }

      //console.log(sendData);
      

      this.service.downloadTerminalLogs(sendData).subscribe((data: Blob) => {
        saveAs(data, 'log.pdf'); // replace 'filename.pdf' with your desired filename
      }, error => {
        console.log(error);
      });


    } catch (error) {
      console.log(error);
      
    }
  }

  onDownloadCSV(){

    try {

      let buttonCSV = document.getElementById('downloadCSVButton');
      if (buttonCSV) {
        buttonCSV.style.borderColor = 'green';
        buttonCSV.style.backgroundColor = 'green';
        buttonCSV.style.color = 'white';

      }


      let sendData = {
        "device_id": this.searchFormData.value.deviceId,
        "global_user_key": this.searchFormData.value.globalUserKey,
        "device_model": this.selectedModelName,
        "event": this.selectedEventType,
        "wifi_SSID": this.searchFormData.value.wiFiSSID,
        "local_ip_address":this.searchFormData.value.localIp,
        "global_ip_address": this.searchFormData.value.globalIp,
        "from_date" :  this.applyDateCheckedFromDate,
        "to_date" : this.applyDateCheckedToDate,
        "download_type" : "csv" 
      }

      //console.log(sendData);
      
      this.service.downloadTerminalLogs(sendData).subscribe((data: Blob) => {
        saveAs(data, 'log.csv'); // replace 'filename.pdf' with your desired filename
      }, error => {
        console.log(error);
      });
      


    } catch (error) {
      console.log(error);
      
    }

  }

  onDownloadXLSX(){

    try {

      let buttonXLSX = document.getElementById('downloadXLSXButton');
      if (buttonXLSX) {
        buttonXLSX.style.borderColor = 'green';
        buttonXLSX.style.backgroundColor = 'green';
        buttonXLSX.style.color = 'white';

      }

      let sendData = {
        "device_id": this.searchFormData.value.deviceId,
        "global_user_key": this.searchFormData.value.globalUserKey,
        "device_model": this.selectedModelName,
        "event": this.selectedEventType,
        "wifi_SSID": this.searchFormData.value.wiFiSSID,
        "local_ip_address":this.searchFormData.value.localIp,
        "global_ip_address": this.searchFormData.value.globalIp,
        "from_date" :  this.applyDateCheckedFromDate,
        "to_date" : this.applyDateCheckedToDate,
        "download_type" : "xlsx" 
      }

      //console.log(sendData);
      
      this.service.downloadTerminalLogs(sendData).subscribe((data: Blob) => {
        saveAs(data, 'log.xlsx'); // replace 'filename.pdf' with your desired filename
      }, error => {
        console.log(error);
      });
      


    } catch (error) {
      console.log(error);
      
    }

  }


  reloadPage() {
    // Navigate to a dummy route first
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      // Then navigate back to the current route
      this.router.navigateByUrl('/terminalLog');
    });
  }

  logout() {
    this.isAuth.logout();
  }

}
