<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Language Pack APIs</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Language Pack APIs</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="lPSendForm" (ngSubmit)="sendLPSubmit()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">API For List all Default and Active Languages</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="api_key" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="api_key" id="api_key" formControlName="api_key"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="device_name"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device
                                Name</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="device_name" id="device_name" formControlName="device_name"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Name" />
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit" class="btn btn-primary">
                                SUBMIT
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->


<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR LIST ALL DEFAULT AND ACTIVE LANGUAGES</h1>
                <button class="btn btn-primary" (click)="copyText()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy">
        {{getcURL}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->

</section>

<!-- response from api -->

<section class="content" *ngIf="apiResponse !== undefined">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>RESPONSE FOR LIST ALL DEFAULT AND ACTIVE LANGUAGES</h1>
                <button class="btn btn-primary" (click)="copyResponse()">Copy</button>
                <pre>
            <code class="text-justify text-base text-teal-50" id="textToCopyResponse">
              {{ apiResponse | json }}
            </code>
          </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>


<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="lPSendForm1" (ngSubmit)="sendLPSubmit1()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">API For List all Active Language Packs</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="api_key1" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="api_key1" id="api_key1" formControlName="api_key1"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="device_name1"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device
                                Name</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="device_name1" id="device_name1" formControlName="device_name1"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Name" />
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON"
                                class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit" class="btn btn-primary">
                                SUBMIT
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->




<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR LIST ALL ACTIVE LANGUAGE PACKS</h1>
                <button class="btn btn-primary" (click)="copyText1()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy1">
        {{getcURL1}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->

</section>

<!-- response ffrom api different -->

<!-- response from api -->

<section class="content" *ngIf="apiResponese1 !== undefined">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>RESPONSE OF LIST ALL ACTIVE LANGUAGE PACKS</h1>
                <button class="btn btn-primary" (click)="copyResponse1()">Copy</button>
                <pre>
            <code class="text-justify text-base text-teal-50" id="textToCopyResponse1">
              {{ apiResponese1 | json }}
            </code>
          </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>


<!-- language pack api used by sound box devices -->

<section class="content">
    <!-- Default box -->
    <form method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">

                    <h1 class="card-title"><b>Language Pack API Used By Sound Box Devices</b></h1>

                </div>
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>


<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR GET LANGUAGE PACK BY LANGUAGE NAME</h1>
                <button class="btn btn-primary" (click)="copyText2()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy2">
        {{getcURL2}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->

</section>

<!-- Response of GET LANGUAGE PACK BY LANGUAGE NAME -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>RESPONSE FOR GET LANGUAGE PACK BY LANGUAGE NAME</h1>
                <button class="btn btn-primary" (click)="copyshowResponseOfCurl2()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="copyshowResponseOfCurl2">
        {{showResponseOfCurl2}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->

</section>


<!-- get curl 3 -->

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR GET LANGUAGE PACK BY LANGUAGE</h1>
                <button class="btn btn-primary" (click)="copyText3()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy3">
        {{getcURL3}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->

</section>

<!-- Response curl3  -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>RESPONSE FOR GET LANGUAGE PACK BY LANGUAGE</h1>
                <button class="btn btn-primary" (click)="copyshowResponseOfcURL3()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="copyshowResponseOfcURL3">
        {{showResponseOfcURL3}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->

</section>


<!-- get curl 4 -->

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR DOWNLOAD LANGUAGE PACK FILE</h1>
                <button class="btn btn-primary" (click)="copyText4()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy4">
        {{getcURL4}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->

</section>