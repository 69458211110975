<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Test Audio (Device)</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Test Audio (Device)</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="sendAudioToDeviceForm" (ngSubmit)="sendAudioToDevice()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Send audio to device using audio code</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="apiKey" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="apiKey" id="apiKey" formControlName="apiKey"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                                <div class="form-text text-danger" *ngIf="apiKey.errors?.['required'] && (apiKey.touched || apiKey.dirty)">
                                    Api Key is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="device_id" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device
                                Id/Product Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="device_id" id="device_id" formControlName="device_id"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Id/Product Key" />
                                <div class="form-text text-danger" *ngIf="device_id.errors?.['required'] && (device_id.touched || device_id.dirty)">
                                    Device Id/Product Key is required
                                </div>
                        </div>
                    </div>

                    

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="audioFileCode" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Audio File Code</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="audioFileCode" id="audioFileCode" formControlName="audioFileCode"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Audio File Code" />
                                <div class="form-text text-danger" *ngIf="audioFileCode.errors?.['required'] && (audioFileCode.touched || audioFileCode.dirty)">
                                    Audio File Code is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="btn btn-primary">
                                Send
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->


<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="sendAudioToDeviceNameForm" (ngSubmit)="sendAudioToDeviceName()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Send audio to device using audio code with device name</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="apiKey" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="apiKeyDeviceName" id="apiKeyDeviceName" formControlName="apiKeyDeviceName"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                                <div class="form-text text-danger" *ngIf="apiKeyDeviceName.errors?.['required'] && (apiKeyDeviceName.touched || apiKeyDeviceName.dirty)">
                                    Api Key is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="device_id" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device
                                Name</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="device_name" id="device_name" formControlName="device_name"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Name" />
                                <div class="form-text text-danger" *ngIf="device_name.errors?.['required'] && (device_name.touched || device_name.dirty)">
                                    Device Name is required
                                </div>
                        </div>
                    </div>

                    

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="audioFileCode" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Audio File Code</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="audioFileCodeDeviceName" id="audioFileCodeDeviceName" formControlName="audioFileCodeDeviceName"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Audio File Code" />
                                <div class="form-text text-danger" *ngIf="audioFileCodeDeviceName.errors?.['required'] && (audioFileCodeDeviceName.touched || audioFileCodeDeviceName.dirty)">
                                    Audio File Code is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="btn btn-primary">
                                Send
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                
                <button class="btn btn-primary" (click)="copyText()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL1}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
    
</section>
<!-- /.content -->


<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                
                <button class="btn btn-primary" (click)="copyText2()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy2">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL2}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
    
</section>