<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Text To Speech Audio Collection</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">
                        Text To Speech Audio Collection Page
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">
                            Text To Speech Audio Collection
                        </h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="flex justify-between items-center mb-3">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;{{ total }}&nbsp;Records
                                </h3>
                            </div>
                            <div class="flex-column text-right">
                                <button
                                    type="button"
                                    id="addGroupBtn"
                                    (click)="openAddAudioTTSForm(content)"
                                    class="btn btn-primary mb-2"
                                >
                                    Add Text to speech audio file
                                </button>
                                <div class="search-container flex items-center ml-2">
                                    <label for="searchDevicesInput" class="form-label mt-2 mr-2">Search:</label>
                                    <input type="text" id="searchDevicesInput" placeholder="Search Devices..." (input)="filterFromTable($event.target.value)" class="form-control">
                                </div>
                            </div>
                        </div>

                        <table
                            id="example2"
                            class="table table-bordered table-hover"
                        >
                            <thead>
                                <tr>
                                    <th *ngFor="let field of prop">
                                        {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let datas of displayedDeviceData; let i = index">
                                <tr>
                                    <ng-container *ngFor="let val of prop">
                                        <td>
                                            {{ datas[val] }}
                                        </td>
                                    </ng-container>

                                    <!-- <td class="px-4 py-2 border text-center">1</td>
                  <td class="px-4 py-2 border text-center">John Doe</td> -->
                                    <td>
                                        <button
                                            type="button"
                                            class="btn btn-primary ml-1"
                                            value="{{ datas['id'] }}"
                                            (click)="
                                                sendAudioFileOpenForm(
                                                    sendAudioForm,
                                                    datas['id']
                                                )
                                            "
                                            id="sendAudio_{{ datas['id'] }}"
                                            ng-click="getValue($event)"
                                            matTooltip="Send to device"
                                        >
                                        <i class="fa fa-mobile"></i>
                                        </button>

                                        <button
                                            type="button"
                                            class="btn btn-primary ml-1"
                                            value="{{ datas['id'] }}"
                                            (click)="
                                                sendAudioFileToGroupOpenForm(
                                                    sendTTSAudioToGroupForm,
                                                    datas['id']
                                                )
                                            "
                                            id="sendAudioToGroup_{{
                                                datas['id']
                                            }}"
                                            ng-click="getValue($event)"
                                            matTooltip="Send to group"
                                        >
                                        <i class="fa fa-users"></i>
                                        </button>

                                        <button
                                            type="button"
                                            class="btn btn-danger ml-1"
                                            value="{{ datas['id'] }}"
                                            (click)="
                                                deleteTTSAudioFile(datas['id'])
                                            "
                                            id="deleteAudio_{{ datas['id'] }}"
                                            ng-click="getValue($event)"
                                            matTooltip="Delete Audio File"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->

<ng-template #sendTTSAudioToGroupForm let-modal>
    <form
        [formGroup]="ttsAudioToGroupForm"
        (ngSubmit)="sendAudioToGroup()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Text To Speech Audio File
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div>
                <label
                    for="deviceId"
                    class="block text-lg leading-6 text-gray-900"
                    >Group Name</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select
                            formControlName="selectedDeviceId2"
                            [value]="selectedGroupName"
                            (selectionChange)="changeGroupName($event.value)"
                        >
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let datas of dataGroupName"
                                value="{{ datas['group_name'] }}"
                                >{{ datas['group_name'] }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                ttsAudioToGroupForm.controls.selectedDeviceId2.hasError(
                                    'required'
                                )
                            "
                            >Please Select Group Name</mat-error
                        >
                    </mat-form-field>
                </div>
            </div>
            &nbsp; &nbsp;
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                Send
            </button>
        </div>
    </form>
</ng-template>

<ng-template #content let-modal>
    <form
        [formGroup]="addTTSAudioForm"
        (ngSubmit)="addTTSAudio()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Text To Speech Audio File
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div>
                <label
                    for="ttsAudioName"
                    class="block text-lg leading-6 text-gray-900"
                    >Name</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                        type="text"
                        name="ttsAudioName"
                        id="ttsAudioName"
                        formControlName="ttsAudioName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Name"
                    />
                </div>
            </div>
            &nbsp;&nbsp;

            <div>
                <label
                    for="ttsAudioConvertName"
                    class="block text-lg leading-6 text-gray-900"
                    >Text To Convert Audio</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                        type="text"
                        name="ttsAudioConvertName"
                        id="ttsAudioConvertName"
                        formControlName="ttsAudioConvertName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Text To Speech"
                    />
                </div>
            </div>
            &nbsp;&nbsp;

            <div>
                <label
                    for="deviceId"
                    class="block text-lg leading-6 text-gray-900"
                    >Select Audio Type</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select
                            [value]="selectedAudioTTSName"
                            (selectionChange)="
                                changeTTSAudioTypeName($event.value)
                            "
                        >
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let datas of dataTTSAudioType"
                                value="{{ datas['audioType'] }}"
                                >{{ datas['audioType'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp; &nbsp;

            <div>
                <label
                    for="deviceId"
                    class="block text-lg leading-6 text-gray-900"
                    >Select Audio Format</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select
                            [value]="selectedAudioTTSType"
                            (selectionChange)="
                                changeTTSAudioFormatTypeName($event.value)
                            "
                        >
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let datas of dataTTSAudioFormatType"
                                value="{{ datas['audioFormatType'] }}"
                                >{{ datas['audioFormatType'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp; &nbsp;

            <div>
                <label
                    for="deviceId"
                    class="block text-lg leading-6 text-gray-900"
                    >Select Audio Language</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select
                            [value]="selectedTTSAudioLanguageType"
                            (selectionChange)="
                                changeTTSAudioLanguageName($event.value)
                            "
                        >
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let datas of dataTTSAudioLanguageType"
                                value="{{ datas['audioLanguage'] }}"
                                >{{ datas['audioLanguage'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp; &nbsp;

            <div>
                <label
                    for="deviceId"
                    class="block text-lg leading-6 text-gray-900"
                    >Select Audio Voice</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select
                            [value]="selectedTTSAudioVoiceType"
                            (selectionChange)="
                                changeTTSAudioVoiceName($event.value)
                            "
                        >
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let datas of dataTTSAudioVoiceType"
                                value="{{ datas['audioVoice'] }}"
                                >{{ datas['audioVoice'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            &nbsp; &nbsp;
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>

<ng-template #sendAudioForm let-modal>
    <form
        [formGroup]="sendAudioToDeviceForm"
        (ngSubmit)="sendAudioToDevice()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Text To Speech Audio File
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div>
                <label
                    for="deviceId"
                    class="block text-lg leading-6 text-gray-900"
                    >Device ID</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select
                            formControlName="selectedDeviceId1"
                            [value]="selectedDeviceId"
                            (selectionChange)="changeDeviceId($event.value)"
                        >
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let datas of dataDeviceIds"
                                value="{{ datas['device_id'] }}"
                                >{{ datas['device_id'] }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                sendAudioToDeviceForm.controls.selectedDeviceId1.hasError(
                                    'required'
                                )
                            "
                            >Please Select Device Id</mat-error
                        >
                    </mat-form-field>
                </div>
            </div>
            &nbsp; &nbsp;
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                Send
            </button>
        </div>
    </form>
</ng-template>

<!-- In your HTML -->
<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>
<!-- Your other HTML goes here -->
