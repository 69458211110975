import { IsAuthService } from '@/service/is-auth.service';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-paymenthistory',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatPaginatorModule
  ],
  templateUrl: './paymenthistory.component.html',
  styleUrl: './paymenthistory.component.scss'
})
export class PaymenthistoryComponent implements OnInit {

  prop: any;
  data: any;
  total: any;

  deviceProp:any;
  deviceData:any;
  devicetotal:any;

  isLoading = true; // Add this line to show the loader at the beginning

  filteredSuccessFail$:any;

  successFailFilterCtrl: FormControl = new FormControl();

  constructor(
    private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private router: Router,
    private isAuth: IsAuthService,
    private http:HttpClient,
    private renderer: Renderer2
  ){
    this.pageEvent = new PageEvent();
  }

  length :any; // Replace with your total data count from API
  page = 1;
  pageSize = 10; // Replace with your default page size
  pageSizeOptions: number[] = [10, 25, 50 ,100]; // Or your page size options
  pageEvent: PageEvent;

  pageIndex = 0; // Angular Material Paginator is zero-based
  currentPage:any;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnInit(): void {
    this.getAllIds();
    this.getPaymentHistoryAll()
    this.getPaymentHistoryFromDeviceId()
  }


  displayedPaymentData:any;

  filterFromTable(input: any) {
    console.log(input);
  
    if (!input) {
      // If there's no input, reset to the original dataset
      this.displayedPaymentData = [...this.data];
    } else {
      const res = this.data.filter(device => 
        Object.values(device).some(prop => 
          prop !== null && prop !== undefined && prop.toString().toLowerCase().includes(input.toLowerCase())
        )
      );
      this.displayedPaymentData = res;
    }
  }
  
  sortData(sortField: string, sortDirection: string) {
    this.displayedPaymentData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  displaySearchForm = false;

  toggleSearchForm(){
    this.displaySearchForm = !this.displaySearchForm;
  }

  capitalizeAndReplace(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

  searchFormData = new FormGroup({
    ipAddress :  new FormControl('',[Validators.required]),
    fromDate : new FormControl('',[Validators.required]),
    toDate:  new FormControl('',[Validators.required]),
    deviceId: new FormControl('',[Validators.required]),
  })

  get ipAddress():FormControl {
    return this.searchFormData.get('ipAddress') as FormControl;
  }

  get deviceId():FormControl {
    return this.searchFormData.get('deviceId') as FormControl;
  }

  changeColor(event: any) {
    this.renderer.setStyle(event.target, 'background-color', 'green');
    this.renderer.setStyle(event.target, 'border-color', 'green');
  }

  fromDate = new Date().toISOString().split("T")[0];  // New variable
  toDate = new Date().toISOString().split("T")[0];  // New variable

  applyDateCheckedFromDate:any ;
  applyDateCheckedToDate:any ;

  showDownloadButtons = false;

  onSearch() {
    
    // console.log('From date: ', this.fromDate);
    // console.log('To date: ', this.toDate);
    // console.log("Input", this.searchFormData.value);
   
   
   
    try {

      this.showDownloadButtons = true;
      this.isLoading = true;

      const dateNew = (<HTMLInputElement>document.getElementById("applyDate")).checked;
      //console.log(dateNew);

      if(dateNew === true){
        this.applyDateCheckedFromDate = this.searchFormData.value.fromDate;
        this.applyDateCheckedToDate = this.searchFormData.value.toDate;
      }else{
        this.applyDateCheckedFromDate = '';
        this.applyDateCheckedToDate = ''
      }

      //console.log("check",this.applyDateCheckedFromDate,this.applyDateCheckedToDate);
      
      let sendData = {
        "device_id": this.selectedDeviceType,
        "merchant_id":this.selectedMerchantType,
        "pos_id":this.selectedPosType,
        "from_date" : this.applyDateCheckedFromDate,
        "to_date" : this.applyDateCheckedToDate,
        "page": this.page,
        "per_page": this.pageSize
    }

      //console.log("Search send data",sendData);

      this.service.postSearchPaymentHistory(sendData).subscribe((data:any)=>{
        //console.log("search Response",data);
        if (data['status'] == true) {
          //console.log("here");
  
          this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.prop.push(property);
              }
              //console.log(this.prop);
            }
          }
  
          this.rearrangeArray();
  
          this.data = data['data'];
          this.displayedPaymentData = [...this.data];
          this.length = data['total_entries'];
          this.total = data['total_entries'];
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.data = null;
          this.toast.error('No Data Found');

        }
      })
      

    } catch (error) {
      console.log(error);
      
    }
    
  }

  // deviceType = 
  //   {
  //     "data":[{id:1,testType:"Text To Speech"},{id:5,testType:"Text To Speech(Group)"},{id:2,testType:"Test API"},{id:3,testType:"Print Test"},{id:4,testType:"Test Kiotel"},{id:6,testType:"Test Api(amount)"},{id:7,testType:"Test Audio(device)"}]
  //   }

  selectedDeviceType:any;
  //showDeviceTypeModel:any = this.deviceType['data'];
  showDeviceTypeModel:any;
  

  changeDeviceType(value:any){
    //console.log(value);
    this.selectedDeviceType = value
  }

  selectedMerchantType:any
  changeMerchantType(value:any){
    this.selectedMerchantType = value
  }

  selectedPosType:any
  changePosType(value:any){
    this.selectedPosType = value
  }

  getPaymentHistoryFromDeviceId(){
    //console.log(this.isAuth.getDeviceId());
    let deviceId = this.isAuth.getDeviceId()

    if(deviceId !== undefined){
      try {
        let sendData = {
          "device_id":deviceId,
          "page": this.page,
          "per_page": this.pageSize
        }
      
        //console.log("send data",sendData);
        
    
          this.service.postFilterPaymentHistoryByDeviceId(sendData).subscribe((data:any)=>{
          //console.log("Request Log API Response",data);
          if (data['status'] == true) {
            //console.log("here");
    
            this.prop = [];
            for (var property in data['data'][0]) {
              if (data['data'][0].hasOwnProperty(property)) {
                //console.log("PROPERTY",property);
                if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                  this.prop.push(property);
                }
                //console.log(this.prop);
              }
            }
    
            this.rearrangeArray();
    
            this.data = data['data'];
            this.displayedPaymentData = [...this.data];
            this.length = data['total_entries'];
            this.total = data['total_entries'];
            this.currentPage = data['current_page'];
            this.isLoading = false;
            deviceId = undefined;
            this.isAuth.setDeviceId(undefined)
          } else {
            deviceId = undefined;
            this.isAuth.setDeviceId(undefined)
            this.data = null;
            this.toast.error('No Data Found');
            this.isLoading = false
          }
        })
      } catch (error) {
        console.log(error);
        
      }
    }else{
      this.getPaymentHistoryAll()
    }
    

  }
  
  getPaymentHistoryAll(){
    let deviceId = this.isAuth.getDeviceId()
    if(deviceId !== undefined){
      this.getPaymentHistoryFromDeviceId()
    }else{


      try {
        let sendData = {
          "page": this.page,
          "per_page": this.pageSize
        }
      
        //console.log("send data",sendData);
        
    
          this.service.postPaymentHistory(sendData).subscribe((data:any)=>{
          //console.log("Request Log API Response",data);
          if (data['status'] == true) {
            //console.log("here");
    
            this.prop = [];
            for (var property in data['data'][0]) {
              if (data['data'][0].hasOwnProperty(property)) {
                //console.log("PROPERTY",property);
                if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                  this.prop.push(property);
                }
                //console.log(this.prop);
              }
            }
    
            this.rearrangeArray();
    
            this.data = data['data'];
            this.displayedPaymentData = [...this.data];
            this.length = data['total_entries'];
            this.total = data['total_entries'];
            this.currentPage = data['current_page']
            this.isLoading = false
          } else {
            this.data = null;
            this.toast.error('No Data Found');
            this.isLoading = false
          }
        })
      } catch (error) {
        console.log(error);
        
      }


    }

    
  }

  showMerchantType:any;
  showPosType:any

  getAllIds(){
    this.service.getIdsInPaymentHistory().subscribe((data:any)=>{
      //console.log(data);
      if(data['status'] === true){
        this.showDeviceTypeModel = data['device_id'];
        this.showMerchantType = data['merchant_id'];
        this.showPosType = data['pos_id'];
      }
    })
  }
  

  userName = localStorage.getItem('UserName');


  loadPageData(page: number,pageSize:number): void {
    this.isLoading = true;

    if(this.isAuth.getDeviceId() === undefined){
      //console.log("heree");
      let sendData = {
        "page": page,
        "per_page": pageSize
      };
  
      //console.log(sendData);
      
  
      this.service.postPaymentHistory(sendData).subscribe({
        next: (data: any) => {
          if (data && data.status === true) {
  
  
            this.prop = [];
            for (var property in data['data'][0]) {
              if (data['data'][0].hasOwnProperty(property)) {
                //console.log("PROPERTY",property);
                if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                  this.prop.push(property);
                }
                //console.log(this.prop);
              }
            }
  
            this.rearrangeArray();
            this.data = data.data;
            this.displayedPaymentData = [...this.data];
            this.length = data.total_entries; // Total number of records
            this.pageIndex = data.current_page - 1; // Adjust because API is one-based and paginator is zero-based
            this.isLoading = false;
          } else {
            this.toast.error('No Data Found');
            this.isLoading = false;
          }
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          this.toast.error('An error occurred');
        }
      });
      
    }else{
      //console.log("eslse");
      const deviceId = this.isAuth.getDeviceId()
    try {
      let sendData = {
        "device_id":deviceId,
        "page": this.page,
        "per_page": this.pageSize
      }
    
      //console.log("send data",sendData);
      
  
        this.service.postFilterPaymentHistoryByDeviceId(sendData).subscribe((data:any)=>{
        //console.log("Request Log API Response",data);
        if (data['status'] == true) {
          //console.log("here");
  
          this.prop = [];
          for (var property in data['data'][0]) {
            if (data['data'][0].hasOwnProperty(property)) {
              //console.log("PROPERTY",property);
              if (property.toLowerCase() != 'total' && property.toLowerCase() != 'user_id') {
                this.prop.push(property);
              }
              //console.log(this.prop);
            }
          }
  
          this.rearrangeArray();
  
          this.data = data['data'];
          this.displayedPaymentData = [...this.data];
          this.length = data['total_entries'];
          this.total = data['total_entries'];
          this.currentPage = data['current_page']
          this.isLoading = false
        } else {
          this.data = null;
          this.toast.error('No Data Found');
          this.isLoading = false
        }
      })
    } catch (error) {
      console.log(error);
      
    }
    }

    
  }

  handlePage(event: PageEvent): void {
    // Adjust for one-based API indexing
    //console.log(event.pageSize);
    
    this.loadPageData(event.pageIndex + 1,event.pageSize);
  }


  rearrangeArray() {

    const dateIdx = this.prop.indexOf('id');
    if (dateIdx > -1) {
      const userNameElement = this.prop.splice(dateIdx, 1)[0];
      this.prop.unshift(userNameElement);
    }
}

onDownloadPDF() {
  // Implement your PDF download functionality here.
  //console.log('Download PDF button clicked!');

  try {

    let sendData = {
      "device_id": this.searchFormData.value.deviceId,
      "download_type": "pdf"
    }

    //console.log(sendData);
    

    // this.service.downloadLogsAPI(sendData).subscribe((data: Blob) => {
    //   saveAs(data, 'log.pdf'); // replace 'filename.pdf' with your desired filename
    // }, error => {
    //   console.log(error);
    // });


  } catch (error) {
    console.log(error);
    
  }
}

onDownloadCSV() {
  // Implement your CSV download functionality here.
  //console.log('Download CSV button clicked!');

  try {

    let sendData = {
      "device_id": this.searchFormData.value.deviceId,
      "download_type": "csv"
    }

    //console.log(sendData);
    
    // this.service.downloadLogsAPI(sendData).subscribe((data: Blob) => {
    //   saveAs(data, 'log.csv'); // replace 'filename.pdf' with your desired filename
    // }, error => {
    //   console.log(error);
    // });
    


  } catch (error) {
    console.log(error);
    
  }

}

onDownloadXLSX() {
  // Implement your XLSX download functionality here.
  //console.log('Download XLSX button clicked!');

  try {

    let sendData = {
      "device_id": this.searchFormData.value.deviceId,
      "ip_address": this.searchFormData.value.ipAddress,
      "download_type": "excel"
    }

    //console.log(sendData);
    
    // this.service.downloadLogsAPI(sendData).subscribe((data: Blob) => {
    //   saveAs(data, 'log.xlsx'); // replace 'filename.pdf' with your desired filename
    // }, error => {
    //   console.log(error);
    // });
    


  } catch (error) {
    console.log(error);
    
  }

}


  reloadPage() {
    // Navigate to a dummy route first
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      // Then navigate back to the current route
      this.router.navigateByUrl('/paymentHistory');
    });
  }

  logout() {
    this.isAuth.logout();
  }

}
