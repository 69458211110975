import { IsAuthService } from '@/service/is-auth.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-uploadaudio',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    MatSelectModule,
    MatTooltipModule
  ],
  templateUrl: './uploadaudio.component.html',
  styleUrl: './uploadaudio.component.scss'
})
export class UploadaudioComponent implements OnInit {

  selectedAudioName:any;
  selectedAudioFile:any;
  closeResult: any;
  modalReference: any;


  prop: any;
  data: any;
  total:any;

  dataDeviceIds:any;
  selectedDeviceId:any;

  showDeviceProp:any;
  showDeviceData:any;
  showDeviceTotal:any;


  dataGroupName:any;
  selectedGroupName:any;
  groupNameProp:any;
  totalGroupName:any;

  isLoading = true; // Add this line to show the loader at the beginning

  constructor(
    private modalService:NgbModal,
    private service:ApiService,
    private toast:ToastrService,
    private isAuth: IsAuthService
  ){}

  ngOnInit(): void {
    this.getAllApi();
  }

  displayedDeviceData:any;

  filterFromTable(input: any) {
    //console.log(input);
  
    if (!input) {
      // If there's no input, reset to the original dataset
      this.displayedDeviceData = [...this.data];
    } else {
      const res = this.data.filter(device => 
        Object.values(device).some(prop => 
          prop !== null && prop !== undefined && prop.toString().toLowerCase().includes(input.toLowerCase())
        )
      );
      this.displayedDeviceData = res;
    }
  }
  
  sortData(sortField: string, sortDirection: string) {
    this.displayedDeviceData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }


  capitalizeAndReplace(str: string): string {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

  getAllApi(){
    try {
      this.service.showAudioFilesCollection().subscribe((data:any)=>{
        //console.log(data);
        try {
          if (data['status'] == true) {
            //console.log("here");
    
            this.prop = [];
              for (var property in data['data'][0]) {
                if (data['data'][0].hasOwnProperty(property)) {
                  //console.log("PROPERTY",property);
                  if (property.toLowerCase() != 'total') {
                    this.prop.push(property);
                  }
                  //console.log(this.prop);
                  
    
                }
              } 
            

            this.rearrangeArray();


            this.data = data['data'];
            this.displayedDeviceData = [...this.data];
            this.total = data['total_audiofiles'];
            this.isLoading = false; // Add this line to hide the loader when data is loaded
            
          } else {
            this.data = null;
            this.displayedDeviceData = [...this.data];
            this.total = data['total_audiofiles'];
            this.toast.info("No Groups Found");
            this.isLoading = false; // Add this line to hide the loader when data is loaded
          }
        } catch (error) {
          console.log(error);
          
        }
        
      })
    } catch (error) {
      console.log(error);
      
    }


    try {
      this.dataDeviceIds = null;
      this.service.getShowDevicesAPI().subscribe((data2:any)=>{
        //console.log("show devices",data2);
  
        if (data2['status'] == true) {
          //console.log("here");
  
          this.showDeviceProp = [];
          for (var propertyAddedDevice in data2['data'][0]) {
            if (data2['data'][0].hasOwnProperty(propertyAddedDevice)) {
              //console.log("PROPERTY",property);
              if (propertyAddedDevice.toLowerCase() != 'total') {
                this.showDeviceProp.push(propertyAddedDevice);
              }
              //console.log("prop",this.deviceProp);
            }
          }

          this.showDeviceData = data2['data'];
          this.dataDeviceIds = data2['data'];
          this.showDeviceTotal = data2['total_devices'];
          //console.log("data",this.data);
        } else {
          this.data = null;
          this.toast.error("No Device Found");
        }
        
      })
    } catch (error) {
      console.log(error);
      
    }


    try {
      this.dataGroupName = null;

      this.service.getShowGroupAPI().subscribe((data:any)=>{
        //console.log("data",data);
  
        if (data['status'] == true) {
          //console.log("here");
  
          this.groupNameProp = [];
            for (var property in data['data'][0]) {
              if (data['data'][0].hasOwnProperty(property)) {
                //console.log("PROPERTY",property);
                if (property.toLowerCase() != 'total') {
                  this.groupNameProp.push(property);
                }
                //console.log(this.prop);
                
  
              }
            } 
          
          this.dataGroupName = data['data'];
          this.totalGroupName = data['total_groups'];
        } else {
          this.data = null;
          this.toast.error("No Groups Found");
        }
  
        
      });



    } catch (error) {
      console.log(error);
      
    }
  }

  rearrangeArray() {

    // Use the JavaScript splice and unshift methods to move 'Date' to the beginning of the array

    const dateIdx6 = this.prop.indexOf('audio_voice');
    if (dateIdx6 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx6, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx5 = this.prop.indexOf('file_format');
    if (dateIdx5 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx5, 1)[0];
      this.prop.unshift(deviceNameElement);
    }


    const dateIdx3 = this.prop.indexOf('file_type');
    if (dateIdx3 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx3, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx2 = this.prop.indexOf('file_name');
    if (dateIdx2 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx2, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx4 = this.prop.indexOf('audio_name');
    if (dateIdx4 > -1) {
      const deviceNameElement = this.prop.splice(dateIdx4, 1)[0];
      this.prop.unshift(deviceNameElement);
    }

    const dateIdx1 = this.prop.indexOf('text');
    if (dateIdx1 > -1) {
      const groupNameElement = this.prop.splice(dateIdx1, 1)[0];
      this.prop.unshift(groupNameElement);
    }

    const dateIdx = this.prop.indexOf('id');
    if (dateIdx > -1) {
      const userNameElement = this.prop.splice(dateIdx, 1)[0];
      this.prop.unshift(userNameElement);
    }
}

  userName = localStorage.getItem('UserName');

  addAudioForm = new FormGroup({
    audioName: new FormControl('', [Validators.required]),
    // audioFile: new FormControl('',[Validators.required]),
  });

  get groupName(): FormControl {
    return this.addAudioForm.get('audioName') as FormControl;
  }

  // get audioFile(): FormControl {
  //   return this.addAudioForm.get('audioFile') as FormControl;
  // }


  selectedFile: File | null = null; // Add this line to store the selected file
  @ViewChild('audioFile', { static: false }) fileInputRef!: ElementRef<HTMLInputElement>;

  fileSelected: boolean = false;

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (files) {
        this.selectedAudioFile = files[0];
        //console.log(this.selectedAudioFile);
        
    }
}

onFileClear(fileInput: any) {
  fileInput.value = '';
  this.selectedFile = null;
} 

uploadFile() {
  if (this.selectedAudioFile && this.addAudioForm.valid) {
    const formData:any = new FormData();
    formData.append('audio_name', this.addAudioForm.value.audioName);
    formData.append('file', this.selectedAudioFile);

    this.service.uploadAudioFileCollection(formData).subscribe((data:any)=>{
      //console.log(data);
      if (data['status'] == true) {
        this.toast.success(data['message']);
        this.addAudioForm.reset();
        this.ngOnInit();
      }else{
        this.toast.error(data['message']);
        this.ngOnInit()
      }
    });
  } else {
    this.toast.error('Please fill in all required fields and select a valid audio file.');
  }
}


  openAddAudioTTSForm(open:any){

    this.modalReference = this.modalService.open(open, { ariaLabelledBy: 'addAudioFile' });
    this.modalReference.result.then((result:any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason:any) => {
      this.closeResult = `Dismissed `;
    });
  }

  deleteAudioFile(deleteId:any){
    //console.log(deleteId);
    try {
      let sendDeleteId = {
        "id": deleteId
      }

      this.service.deleteAudioFilesCollection(sendDeleteId).subscribe((data:any)=>{
        //console.log(data);
        if(data['status'] == true){
          //console.log("heer");
          
          this.toast.success(data['message']);
          this.ngOnInit();
        }else{
          this.toast.error(data['message']);
          this.ngOnInit();
        }
      })

    } catch (error) {
      console.log(error);
      
    }
  }

  modal:any

  submitAndClose(modal:any) {
    // perform your actions here, like sending the form data

    // then close the modal
    this.modalReference.close('Save Clicked')
  }


  changeAudioTypeName(value: any) {
    //console.log("audio TTS type",value);
    this.selectedAudioName = value;
  }

  mainCollectionAudioId:any;

  sendAudioFileOpenForm(sendAudioForm:any,audioId:any){
    //console.log(audioId);
    this.mainCollectionAudioId = audioId;


    this.modalReference = this.modalService.open(sendAudioForm, { ariaLabelledBy: `sendAudio_${audioId}`});
      this.modalReference.result.then((result:any) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason:any) => {
        this.closeResult = `Dismissed `;
      });
  
  
  }

  changeDeviceId(value:any){
    //console.log(value);
    this.selectedDeviceId = value;
  }


  sendAudioToDeviceForm = new FormGroup({
    selectedDeviceId1 : new FormControl('',[Validators.required])
  })

  get selectedDeviceId1(): FormControl {
    return this.sendAudioToDeviceForm.get('selectedDeviceId1') as FormControl;
  }

  sendAudioToDevice() {
    try {
        let sendCollectionAudioToDevice = {
            "device_id": this.selectedDeviceId,
            "audiofile_id": this.mainCollectionAudioId
        };

        this.service.sendToDeviceAudioCollectionAPI(sendCollectionAudioToDevice).subscribe({
            next: (data: any) => {
                if (data.status === true) {
                    this.toast.success(data.message);
                } else {
                    this.toast.error(data.message);
                }
                this.ngOnInit(); // Consider the necessity of reinitializing component
            },
            error: (error: any) => {
              //console.log(error);
              
                // Handle HTTP error status
                if (error && error.status) {
                    // You can use a switch case to handle various error statuses
                    switch (error.status) {
                        case 400:
                            this.toast.error("Bad Request: " + error.error.message);
                            break;
                        case 401:
                            this.toast.error("Unauthorized: " + error.error.message);
                            break;
                        case 403:
                            this.toast.error("Forbidden: " + error.error.message);
                            break;
                        case 404:
                            this.toast.error("Not Found: " + error.error.message);
                            break;
                        case 500:
                            this.toast.error("Internal Server Error: Please try again later.");
                            break;
                        default:
                            this.toast.error(error.error.message);
                    }
                } else {
                    // Handle case where error status is not available
                    this.toast.error("An unexpected error occurred. Please try again.");
                }
            }
        });
    } catch (error) {
        console.error(error);
        this.toast.error("An error occurred in the application. Please try again.");
    }
}


  ttsAudioToGroupForm = new FormGroup({
    selectedDeviceId2 : new FormControl('',[Validators.required])
  });

  get selectedDeviceId2(): FormControl {
    return this.ttsAudioToGroupForm.get('selectedDeviceId2') as FormControl;
  }

  sendAudioToGroup(){
    //console.log(this.sendToGroupAudioId,this.selectedGroupName);
    try {
      let sendDataToGroup = {
        "group":this.selectedGroupName,
        "audiofile_id":this.sendToGroupAudioId
      }

      this.service.sendToGroupAudioCollectionAPI(sendDataToGroup).subscribe((data:any)=>{
        //console.log(data);
        
        let responses = data;

        responses.forEach((response:any) => {
          // Display toast with message based on status
          this.showToast(response.message, response.status);
        });
      })

    } catch (error) {
      console.log(error);
      
    }
  }

  showToast(message:any, status:boolean) {
    if (status == true) {
      // Call success toast function with message
      this.toast.success(message);
    } else {
      // Call error toast function with message
      this.toast.error(message);
    }
}

  changeGroupName(value:any){
    //console.log(value);
    this.selectedGroupName = value;
  }

  sendToGroupAudioId:any;

  sendAudioFileToGroupOpenForm(sendTTSAudioToGroupForm:any,audioId:any){
    //console.log(audioId);
    this.sendToGroupAudioId = audioId;
    //console.log(this.selectedGroupName);
    

    this.modalReference = this.modalService.open(sendTTSAudioToGroupForm, { ariaLabelledBy: `sendAudioToGroup_${audioId}`});
      this.modalReference.result.then((result:any) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason:any) => {
        this.closeResult = `Dismissed `;
      });
  }


  
  logout() {
    this.isAuth.logout();
  }


}
