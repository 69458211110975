import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-ttsgroup',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './ttsgroup.component.html',
    styleUrl: './ttsgroup.component.scss'
})
export class TtsgroupComponent implements OnInit {
    selectedGroupName: any;
    dataGroupName: any;
    data: any;
    prop: any;
    total: any;

    userName = localStorage.getItem('UserName');

    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        this.showGroup();
    }

    showGroup() {
        try {
            this.data = null;
            this.dataGroupName = null;
            this.service.getShowGroupAPI().subscribe((data: any) => {
                //console.log("show group",data['data']);
                // console.log("show group",data['status']);
                // this.data = data['data'];
                // this.dataGroupName = data['data'];
                //console.log("data",this.data);
                if (data['status'] == true) {
                    //console.log("here");

                    this.prop = [];
                    for (var property in data['data'][0]) {
                        if (data['data'][0].hasOwnProperty(property)) {
                            //console.log("PROPERTY",property);
                            if (property.toLowerCase() != 'total') {
                                this.prop.push(property);
                            }
                            //console.log("prop",this.prop);
                        }
                    }
                    this.dataGroupName = data['data'];
                    this.total = data['total_groups'];
                    //console.log("data",this.data);
                } else {
                    this.data = null;
                    this.toast.error(data['ErrorMsg']);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    createAndSendToGroupForm = new FormGroup({
        ip_key: new FormControl('', [Validators.required]),
        tts: new FormControl('', [Validators.required])
    });

    get ip_key(): FormControl {
        return this.createAndSendToGroupForm.get('ip_key') as FormControl;
    }

    get tts(): FormControl {
        return this.createAndSendToGroupForm.get('tts') as FormControl;
    }

    createAndSendToGroup() {
        try {
            let sendData = {
                ip_key: this.createAndSendToGroupForm.value.ip_key,
                text: this.createAndSendToGroupForm.value.tts,
                group: this.selectedGroupName
            };

            //console.log(sendData);

            this.service
                .postSendGroupDevice(sendData)
                .subscribe((data: any) => {
                    //console.log(data);

                    let responses = data;

                    responses.forEach((response: any) => {
                        // Display toast with message based on status
                        this.showToast(response.message, response.status);
                    });
                });
        } catch (error) {
            console.log(error);
        }
    }

    showToast(message: any, status: boolean) {
        if (status == true) {
            // Call success toast function with message
            this.toast.success(message);
        } else {
            // Call error toast function with message
            this.toast.error(message);
        }
    }

    getcURL1 = `curl --location "http://91.107.200.91:6500/create_wav_group" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"group\\\":\\\"Bonrix\\\",\\\"ip_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"text\\\":\\\"amount 10 rs. successful\\\"}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    changeGroupName(value: any) {
        //console.log("hello",value);
        this.selectedGroupName = value;
    }

    logout() {
        this.isAuth.logout();
    }
}
