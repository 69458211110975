<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Staff Account</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Staff Account</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Staff Account List</h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="flex justify-between items-center mb-3">
                            <div>
                                <h3 class="text-2xl">
                                    Total:&nbsp;{{ total }}&nbsp;Records
                                </h3>
                            </div>
                            <div class="flex-column text-right">
                                <button
                                    type="button"
                                    id="addStaffBtn"
                                    (click)="openAddStaffForm(content)"
                                    class="btn btn-primary mb-2"
                                >
                                    Add Staff
                                </button>
                                <div
                                    class="search-container flex items-center ml-2"
                                >
                                    <label
                                        for="searchDevicesInput"
                                        class="form-label mt-2 mr-2"
                                        >Search:</label
                                    >
                                    <input
                                        type="text"
                                        id="searchDevicesInput"
                                        placeholder="Search Devices..."
                                        (input)="
                                            filterFromTable($event.target.value)
                                        "
                                        class="form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <table
                            id="example2"
                            class="table table-bordered table-hover"
                        >
                            <thead>
                                <tr>
                                    <th *ngFor="let field of prop">
                                        {{ capitalizeAndReplace(field) }}
                                        <i
                                            class="fas fa-sort-up"
                                            (click)="sortData(field, 'asc')"
                                        ></i>
                                        <i
                                            class="fas fa-sort-down"
                                            (click)="sortData(field, 'desc')"
                                        ></i>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="
                                    let datas of displayedStaffData;
                                    let i = index
                                "
                            >
                                <tr>
                                    <ng-container *ngFor="let val of prop">
                                        <!-- <td>
                                            {{ datas[val] }}
                                        </td> -->

                                        <td *ngIf="val.toLowerCase() !== 'staff_status' " id="id_{{ i }}" attr.data-value="{{ datas[val] }}">
                                            {{ datas[val] }}
                                        </td>
                                        <td *ngIf="
                                                datas[val] === 1 &&
                                                val.toLowerCase() ===
                                                    'staff_status'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateStaffStatusActive(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" checked />
                                            </label>
                                        </td>

                                        <td *ngIf="
                                                datas[val] !== 1 &&
                                                val.toLowerCase() ===
                                                    'staff_status'
                                            ">
                                            <label for="active{{ i }}">
                                                <input type="checkbox" id="active{{ i }}" (change)="
                                                        updateStaffStatusActive(
                                                            i
                                                        )
                                                    " value="{{ datas['id'] }}" unchecked />
                                            </label>
                                        </td>


                                    </ng-container>

                                    <!-- <td class="px-4 py-2 border text-center">1</td>
                  <td class="px-4 py-2 border text-center">John Doe</td> -->
                                    <td>
                                        <button
                                            type="button"
                                            value="{{ datas['id'] }}"
                                            id="editUpdateStaff_{{
                                                datas['id']
                                            }}"
                                            class="btn btn-primary ml-1"
                                            (click)="
                                                openEditUpdateAPIForm(
                                                    editStaffFormdATA,
                                                    datas['id'],
                                                    datas['staff_user_name'],
                                                    datas['full_name'],
                                                    datas['contact_no'],
                                                    datas['email_id'],
                                                    datas['role']
                                                )
                                            "
                                            ng-click="getValue($event)"
                                            matTooltip="Edit API"
                                        >
                                            <i class="fas fa-edit"></i>
                                        </button>

                                        <button
                                            type="button"
                                            value="{{ datas['id'] }}"
                                            class="btn btn-danger ml-1"
                                            (click)="deleteStaff(datas['id'])"
                                            id="deleteStaff_{{ datas['id'] }}"
                                            ng-click="getValue($event)"
                                            matTooltip="Delete API"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </button>

                                        <button
                                            type="button"
                                            value="{{ datas['id'] }}"
                                            class="btn btn-primary ml-1"
                                            (click)="changePassword(changeStaffPassword,datas['id'])"
                                            id="changeStaffPassword_{{ datas['id'] }}"
                                            ng-click="getValue($event)"
                                            matTooltip="Change Password"
                                        >
                                            <i class="fa fa-key"></i>
                                        </button>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->

<ng-template #changeStaffPassword let-modal>
    <form
        [formGroup]="changePasswordForm"
        (ngSubmit)="updateStaffPassword()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            

            <div class="mt-2">
                <label
                    for="changeStaffPassword"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Change Password</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="password"
                        name="changeStaffPassword"
                        id="changeStaffPassword"
                        formControlName="changeStaffPassword"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Password"
                    />
                </div>
            </div>

           

            

            

        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                UPDATE PASSWORD
            </button>
        </div>
    </form>
</ng-template>


<ng-template #content let-modal>
    <form
        [formGroup]="addStaffForm"
        (ngSubmit)="addStaffFormSubmit()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Create Staff Account</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div class="mt-2">
                <label
                    for="staffName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >User Name</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="staffName"
                        id="staffName"
                        formControlName="staffName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter User Name"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="staffName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Password</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="password"
                        name="password"
                        id="password"
                        formControlName="password"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Password"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="staffFullName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Staff Full Name</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="staffFullName"
                        id="staffFullName"
                        formControlName="staffFullName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Full Staff Name"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="staffContactNo"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Mobile Number</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="staffContactNo"
                        id="staffContactNo"
                        formControlName="staffContactNo"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Mobile Number"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="staffEmail"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >E-Mail Address</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="email"
                        name="staffEmail"
                        id="staffEmail"
                        formControlName="staffEmail"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter E-Mail Address"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="staffRole"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Select Role</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select formControlName="staffRole">
                            <mat-option value="Manager">Manager</mat-option>
                            <mat-option value="Customer Care"
                                >Customer Care</mat-option
                            >
                            <mat-option value="Supervisior"
                                >Supervisior</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                ADD STAFF
            </button>
        </div>
    </form>
</ng-template>

<ng-template #editStaffFormdATA let-modal>
    <form
        [formGroup]="editStaffForm"
        (ngSubmit)="editStaffSubmit()"
        method="post"
    >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit Staff Account</h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            ></button>
        </div>
        <div class="modal-body">
            <div class="mt-2">
                <label
                    for="editStaffName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >User Name</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="editStaffName"
                        id="editStaffName"
                        formControlName="editStaffName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter User Name"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="editStaffFullName"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Staff Full Name</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="editStaffFullName"
                        id="editStaffFullName"
                        formControlName="editStaffFullName"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Full Staff Name"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="editStaffContactNo"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Mobile Number</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="text"
                        name="editStaffContactNo"
                        id="editStaffContactNo"
                        formControlName="editStaffContactNo"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Mobile Number"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="editStaffEmail"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >E-Mail Address</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input
                        type="email"
                        name="editStaffEmail"
                        id="editStaffEmail"
                        formControlName="editStaffEmail"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter E-Mail Address"
                    />
                </div>
            </div>

            <div class="mt-2">
                <label
                    for="staffRole"
                    class="block text-2xl font-medium leading-6 text-gray-900"
                    >Select Role</label
                >
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select an option</mat-label>
                        <mat-select formControlName="editStaffRole">
                            <mat-option value="Manager">Manager</mat-option>
                            <mat-option value="Customer Care"
                                >Customer Care</mat-option
                            >
                            <mat-option value="Supervisior"
                                >Supervisior</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="submit"
                class="btn btn-primary"
                (click)="modal.close('Save click')"
            >
                UPDATE
            </button>
        </div>
    </form>

    <!-- In your HTML -->
    <!-- <div *ngIf="isLoadingEdit" class="custom-loader"></div> -->

    <div class="loader-container" *ngIf="isLoadingEdit">
        <div class="lds-hourglass"></div>
    </div>
    <!-- Your other HTML goes here -->
</ng-template>

<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>
