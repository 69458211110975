import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-testamount',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule
    ],
    templateUrl: './testamount.component.html',
    styleUrl: './testamount.component.scss'
})
export class TestamountComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {}

    userName = localStorage.getItem('UserName');

    sendAmountWithMerchantIdForm = new FormGroup({
        mip_key: new FormControl('', [Validators.required]),
        mmerchantId: new FormControl('', [Validators.required]),
        mPOSId: new FormControl('', [Validators.required]),
        mamount: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[0-9]+(\.[0-9]{1,4})?$/)
        ])
    });

    get mPOSId(): FormControl {
        return this.sendAmountWithMerchantIdForm.get('mPOSId') as FormControl;
    }

    get mip_key(): FormControl {
        return this.sendAmountWithMerchantIdForm.get('mip_key') as FormControl;
    }

    get mmerchantId(): FormControl {
        return this.sendAmountWithMerchantIdForm.get(
            'mmerchantId'
        ) as FormControl;
    }

    get mamount(): FormControl {
        return this.sendAmountWithMerchantIdForm.get('mamount') as FormControl;
    }

    sendAmountMerchantId() {
        let sendData = {
            api_key: this.sendAmountWithMerchantIdForm.value.mip_key,
            merchant_id: this.sendAmountWithMerchantIdForm.value.mmerchantId,
            pos_id: '',
            amount: 453
        };

        this.service
            .postTestAmountWithMerchantId(sendData)
            .subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    this.ngOnInit();
                    this.toast.success(data['message']);
                    this.sendAmountForm.reset();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
    }

    sendAmountForm = new FormGroup({
        ip_key: new FormControl('', [Validators.required]),
        device_id: new FormControl('', [Validators.required]),
        amount: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[0-9]+(\.[0-9]{1,4})?$/)
        ])
    });

    get ip_key(): FormControl {
        return this.sendAmountForm.get('ip_key') as FormControl;
    }

    get device_id(): FormControl {
        return this.sendAmountForm.get('device_id') as FormControl;
    }

    get amount(): FormControl {
        return this.sendAmountForm.get('amount') as FormControl;
    }

    sendAmount() {
        //console.log(Number(this.sendAmountForm.value.amount));

        let sendData = {
            api_key: this.sendAmountForm.value.ip_key,
            device_name: this.sendAmountForm.value.device_id,
            amount: Number(this.sendAmountForm.value.amount)
        };

        //console.log(sendData);

        try {
            this.service.postTestAmount(sendData).subscribe((data: any) => {
                //console.log(data);
                if (data['status'] == true) {
                    //console.log("heer");
                    this.ngOnInit();
                    this.toast.success(data['message']);
                    this.sendAmountForm.reset();
                } else {
                    this.toast.error(data['message']);
                    this.ngOnInit();
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    getcURL1 = `curl --location "http://91.107.200.91:6500/publish_message_amount" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"device_name\\\":\\\"ctGVOPt26yxU263FFA1BiDZ9Ed1yMzCT\\\",\\\"api_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"amount\\\":1234}"
  `;

    copyText(): void {
        const textToCopy: any =
            document.getElementById('textToCopy')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    // getcURL2 = `curl --location "https://api-fintech-terminal-management.bonrix.in/publish_message_amount" ^
    //                   --header "Content-Type: application/json" ^
    //                   --data "{\\\"device_id\\\":\\\"ctGVOPt26yxU263FFA1BiDZ9Ed1yMzCT\\\",\\\"ip_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"amount\\\":1234}"
    // `;

    getcURL2 = `curl --location "http://91.107.200.91:6500/publishamount_merchantpos" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"merchant_id\\\":\\\"sujsujqqq\\\",\\\"pos_id\\\":\\\"\\\",\\\"api_key\\\":\\\"0rtuFwE9ExWRtrLcJZFH4ICGIEExYPaB\\\",\\\"amount\\\":220}"
                    `;

    copyText2(): void {
        const textToCopy: any =
            document.getElementById('textToCopy2')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }

    logout() {
        this.isAuth.logout();
    }

    sendAmountDeviceIdForm = new FormGroup({
        apiKeyDeviceId: new FormControl('', [Validators.required]),
        deviceId: new FormControl('', [Validators.required]),
        amountDeviceId: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[0-9]+(\.[0-9]{1,4})?$/)
        ])
    });

    get apiKeyDeviceId(): FormControl {
        return this.sendAmountDeviceIdForm.get('apiKeyDeviceId') as FormControl;
    }

    get deviceId(): FormControl {
        return this.sendAmountDeviceIdForm.get('deviceId') as FormControl;
    }

    get amountDeviceId(): FormControl {
        return this.sendAmountDeviceIdForm.get('amountDeviceId') as FormControl;
    }

    sendAmountDeviceId() {
        //console.log(Number(this.sendAmountDeviceIdForm.value.amount));

        let sendData = {
            api_key: this.sendAmountDeviceIdForm.value.apiKeyDeviceId,
            device_id: this.sendAmountDeviceIdForm.value.deviceId,
            amount: Number(this.sendAmountDeviceIdForm.value.amountDeviceId)
        };

        //console.log(sendData);

        try {
            this.service
                .postPubllishAmountId(sendData)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] == true) {
                        //console.log("heer");
                        this.ngOnInit();
                        this.toast.success(data['message']);
                        this.sendAmountDeviceIdForm.reset();
                    } else {
                        this.toast.error(data['message']);
                        this.ngOnInit();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    getcURL3 = `curl --location "http://91.107.200.91:6500/publish_amount_id" ^
                    --header "Content-Type: application/json" ^
                    --data "{\\\"device_id\\\":\\\"fdsfcrer43\\\",\\\"api_key\\\":\\\"DcSAhQrRQnnAHnG70hTTpmmwm1w6SX36\\\",\\\"amount\\\":1234}"
  `;

    copyText3(): void {
        const textToCopy: any =
            document.getElementById('textToCopy3')?.innerText;
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    //console.log('Text copied to clipboard!');
                    // You can optionally show a success message or perform other actions.
                    this.toast.info('cURL Copied Successfully');
                })
                .catch((error) => {
                    this.toast.error('Failed to copy Code:', error);
                    //console.error('Failed to copy text:', error);
                    // Handle any errors that may occur during the copy process.
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute';
            textArea.style.left = '-999999px';

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                this.toast.info('cURL Copied Successfully');
            } catch (error: any) {
                this.toast.error('Failed to copy Code:', error);
            } finally {
                textArea.remove();
            }
        }
    }
}
