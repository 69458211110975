<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Test Amount</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Test Amount</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="sendAmountForm" (ngSubmit)="sendAmount()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Test Amount Or Other Response</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="ip_key" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="ip_key" id="ip_key" formControlName="ip_key"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                                <div class="form-text text-danger" *ngIf="ip_key.errors?.['required'] && (ip_key.touched || ip_key.dirty)">
                                    Api Key is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="device_id" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device Name</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="device_id" id="device_id" formControlName="device_id"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Name" />
                                <div class="form-text text-danger" *ngIf="device_id.errors?.['required'] && (device_id.touched || device_id.dirty)">
                                    Device Name is required
                                </div>
                        </div>
                    </div>

                    

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="amount" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Amount</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="amount" id="amount" formControlName="amount"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Amount" />
                                <div class="form-text text-danger" *ngIf="amount.errors?.['required'] && (amount.touched || amount.dirty)">
                                    Amount is required
                                </div>
                                <div class="form-text text-danger" *ngIf="amount.errors?.['pattern'] && (amount.touched || amount.dirty)">
                                    Only numbers are allowed and up to four numbers are allowed after the dot.
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="btn btn-primary">
                                Send
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->



<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="sendAmountDeviceIdForm" (ngSubmit)="sendAmountDeviceId()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Test Amount Or Other Response With Device Id</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="ip_key" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="apiKeyDeviceId" id="apiKeyDeviceId" formControlName="apiKeyDeviceId"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                                <div class="form-text text-danger" *ngIf="apiKeyDeviceId.errors?.['required'] && (apiKeyDeviceId.touched || apiKeyDeviceId.dirty)">
                                    Api Key is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="device_id" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Device Id/Product Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="deviceId" id="deviceId" formControlName="deviceId"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Device Id/Product Key" />
                                <div class="form-text text-danger" *ngIf="deviceId.errors?.['required'] && (deviceId.touched || deviceId.dirty)">
                                    Device Id/Product Key is required
                                </div>
                        </div>
                    </div>

                    

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="amount" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Amount</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="amountDeviceId" id="amountDeviceId" formControlName="amountDeviceId"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Amount" />
                                <div class="form-text text-danger" *ngIf="amountDeviceId.errors?.['required'] && (amountDeviceId.touched || amountDeviceId.dirty)">
                                    Amount is required
                                </div>
                                <div class="form-text text-danger" *ngIf="amountDeviceId.errors?.['pattern'] && (amountDeviceId.touched || amountDeviceId.dirty)">
                                    Only numbers are allowed and up to four numbers are allowed after the dot.
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="btn btn-primary">
                                Send
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->



<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <form [formGroup]="sendAmountWithMerchantIdForm" (ngSubmit)="sendAmountMerchantId()" method="post">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Test Amount To Device With Merchant Id or POS Id</h3>
                </div>
                <div class="card-body">
                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="mip_key" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Api
                                Key</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="mip_key" id="mip_key" formControlName="mip_key"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter API Key" />
                                <div class="form-text text-danger" *ngIf="mip_key.errors?.['required'] && (mip_key.touched || mip_key.dirty)">
                                    Api Key is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="mmerchantId" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Merchant Id</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="mmerchantId" id="mmerchantId" formControlName="mmerchantId"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Merchant Id" />
                                <div class="form-text text-danger" *ngIf="mmerchantId.errors?.['required'] && (mmerchantId.touched || mmerchantId.dirty)">
                                    Merchant Id is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="mPOSId" class="ml-10 block text-lg font-medium leading-6 text-gray-900">POS Id</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="mPOSId" id="mPOSId" formControlName="mPOSId"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter POS Id" />
                                <div class="form-text text-danger" *ngIf="mPOSId.errors?.['required'] && (mPOSId.touched || mPOSId.dirty)">
                                    POS Id is required
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="mamount" class="ml-10 block text-lg font-medium leading-6 text-gray-900">Amount</label>
                        </div>
                        <div class="w-2/3">
                            <input type="text" name="mamount" id="mamount" formControlName="mamount"
                                class="block w-96 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Amount" />
                                <div class="form-text text-danger" *ngIf="mamount.errors?.['required'] && (mamount.touched || mamount.dirty)">
                                    Amount is required
                                </div>
                                <div class="form-text text-danger" *ngIf="mamount.errors?.['pattern'] && (mamount.touched || mamount.dirty)">
                                    Only numbers are allowed and up to four numbers are allowed after the dot.
                                </div>
                        </div>
                    </div>

                    <div class="flex mb-10">
                        <div class="w-1/3 mr-4">
                            <label for="selectJSON" class="ml-10 block text-lg font-medium leading-6 text-gray-900"></label>
                        </div>
                        <div class="w-2/3">
                            <button type="submit"
                                class="btn btn-primary">
                                Send
                            </button>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <!-- <div class="card-footer">Footer</div> -->
                <!-- /.card-footer-->
            </div>
        </div>
        <!-- /.card -->
    </form>
</section>
<!-- /.content -->


<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR SEND AMOUNT TO MQTT</h1>
                <button class="btn btn-primary" (click)="copyText()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL1}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
    
</section>
<!-- /.content -->


<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR SEND AMOUNT TO MQTT</h1>
                <button class="btn btn-primary" (click)="copyText3()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy3">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL3}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
    
</section>
<!-- /.content -->



<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="bg-black card">
            <div class="card-body">
                <h1>cURL COMMAND FOR SEND AMOUNT TO MQTT WITH MERCHANT OR POS ID's</h1>
                <button class="btn btn-primary" (click)="copyText2()">Copy</button>
                <pre>
      <code class="text-justify text-base text-teal-50" id="textToCopy2">
        <!-- curl --location 'http://91.107.200.91:6500/send_data/QVCjLTNRK6u2whAR4rxxVG3GP6tOCWn2/tNW9LE7WXsbNVbAYrX04DnYM48zD913w' \
        --header 'Content-Type: application/json' \
        --data {{'{"screen":"WelcomeScreen","data":{"title":"Welcome to Easovation Solutions","voice_data":"http://116.202.102.65:9000/downloadttsfilenewuser?filename=20230613T100832138Z750829.wav","img":""}}'}} -->
        {{getcURL2}}
      </code>
    </pre>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
    
</section>
<!-- /.content -->